import { CalculatorOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal, message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { get, post } from '../../axios/axios';
import { DOC_TYPES } from './const';
import styles from './index.module.scss';
import { CodeIcon, PicIcon, TableIcon } from './Summary/icons';
class SummaryStore {
    // 论文题目
    majorName = '';
    // 论文类型
    docType = DOC_TYPES[0].key;
    // 当前分步表单步数
    currentStep = 0;
    // 是否使用Ultra
    useUltra = false;
    // step1的学科分类
    categories = [];
    // step1选中的图、表、代码、公式
    autoLightTypes = [];
    // step1的免责声明是否勾选
    isCheckConfirm = false;
    // 是否正在生成大纲
    isLoadingSummary = false;
    // step2的大纲数据
    paragraphs = [];
    // step3右侧的附加服务列表
    serviceList = [];
    // step3的论文信息
    paperInfo = null;
    // 是否显示Ultra弹窗
    showUltraDialog = false;
    // 是否正在生成step3的预览
    isLoadingPreview = false;
    // step3勾选附加附加服务重新请求的loading
    isExtraloading = false;
    // ste3p的继续预览loading
    nextPreviewLoading = false;
    // 是否已经预览结束
    hasPreviewEnd = false;
    // 是否已经确认图、表、代码、公式
    hasConfirmTable = false;
    // 再次展示选择的图、表、代码、公式弹窗visible
    showCheckTableDialog = false;
    // step3.4的目录数据是否加载完毕
    catalogueDataLoaded = false;
    // step3.4的目录初始数据
    catalogueInceptionData = [];
    // step3.4的目录数据
    catalogueData = [];

    // Step4正在加载数据
    isLoadingStep4Data = false;
    // step4的摘要数据是否加载完毕
    abstractDataLoaded = false;
    // step4的摘要初始数据
    abstractDataInceptionData = {
        abstractCn: '',
        abstractEn: '',
    };
    // step4的摘要数据
    abstractData = {
        abstractCn: '',
        abstractEn: '',
    };
    // step4的内容数据是否加载完毕
    contentDataLoaded = false;
    // step4初始内容数据
    contentInceptionData = [];
    // step4内容数据
    contentData = [];
    _tempAutoText = '';

    // 文献
    customReference = {
        checked: false,
        // customReferenceDocument: '',
        customReferenceDocument: [],
    };
    confirmCustomReferenceDocumentModal = false;
    isConfirmCustomReferenceDocument = false;
    isReref = true;
    constructor() {
        makeAutoObservable(this);
        this.initApiVersion();
        this.getServiceList();
        this.getCatogories();
    }

    reset() {
        this.majorName = '';
        this.isLoadingSummary = false;
        this.paragraphs = null;
        this.serviceList = [];
        this.isCheckConfirm = false;
        this.currentStep = 0;
        this.paperInfo = null;
        this.isLoadingPreview = false;
        this.isExtraloading = false;
        this.nextPreviewLoading = false;
        this.showUltraDialog = false;
        this.hasPreviewEnd = false;
        this.autoLightTypes = [];
        this._tempAutoText = '';
        this.showCheckTableDialog = false;
        this.hasConfirmTable = false;
        this.catalogueData = [];
        // this.customReference = { checked: false, customReferenceDocument: '' };
        this.customReference = { checked: true, customReferenceDocument: [] };
        this.isConfirmCustomReferenceDocument = false;
        this.confirmCustomReferenceDocumentModal = false;
        this.isReref = true;
        this.docType = DOC_TYPES[0].key;
    }

    setDocType(v) {
        this.docType = v;
    }
    setisReref(v) {
        this.isReref = v;
    }
    addCustomReferenceDocument = (v) => {
        this.customReference.customReferenceDocument = [
            ...this.customReference.customReferenceDocument,
            ...v,
        ];
    };
    deletesCustomReferenceDocument = (id) => {
        console.log('id', id);
        this.customReference.customReferenceDocument =
            this.customReference.customReferenceDocument.filter((item) => item.id !== id);
    };
    onAutoLightChange = (list) => {
        this.autoLightTypes = list;
    };
    setCurrentStep(step) {
        this.currentStep = step;
    }
    setIsCheckConfirm(check) {
        this.isCheckConfirm = check;
    }
    getAutoText() {
        const TYPES_DESC = {
            CODE: '代码',
            TABLE: '表',
            DIAGRAM: '图',
            FORMULA: '公式',
        };
        return (
            (this.autoLightTypes && this.autoLightTypes?.map((i) => TYPES_DESC[i])?.join(',')) || ''
        );
    }
    // 获取编辑器大纲和目录
    async getCatalogueData() {
        try {
            const res = await get('/ai/ai/paper/editor/get/outline', {
                orderNo: this.paperInfo.orderNo,
            });

            this.catalogueData = convertToTreeStructure(res.data);
            this.catalogueInceptionData = res.data;
            this.catalogueDataLoaded = true;
        } catch (e) {
            this.catalogueDataLoaded = false;
        }
    }
    // 获取编辑器摘要
    async getAbstractData() {
        try {
            const res = await get('/ai/ai/paper/editor/get/abstract', {
                orderNo: this.paperInfo.orderNo,
            });

            this.abstractDataInceptionData = res.data;
            this.abstractDataLoaded = true;
        } catch (e) {
            this.abstractDataLoaded = false;
        }
    }
    // 获取编辑器内容
    async getContentData() {
        try {
            const res = await get('/ai/ai/paper/editor/get/content', {
                orderNo: this.paperInfo.orderNo,
            });

            this.contentInceptionData = res.data;
            this.contentDataLoaded = true;
        } catch (e) {
            this.contentDataLoaded = false;
        }
    }

    async goStep4() {
        this.isLoadingStep4Data = true;
        await Promise.all([this.getAbstractData(), this.getContentData()]).finally(() => {
            this.isLoadingStep4Data = false;
        });
        this.currentStep = 3;
    }
    initApiVersion() {
        this.useUltra = false;
    }
    toggleUseUltra() {
        if (this.isLoadingSummary) {
            message.warning('大纲生成中，请结束后再试');
            return;
        }
        this.reset();
        this.useUltra = !this.useUltra;
    }
    toggleUltraDialog() {
        this.showUltraDialog = !this.showUltraDialog;
    }
    hideUltraDialog() {
        this.showUltraDialog = false;
    }
    changeMajorName(v) {
        this.majorName = v.trim();
    }
    addTitle(opts) {
        if (this.isLoadingPreview) {
            message.info('论文预览生成中，请结束后再试');
            return;
        }

        const {
            level,
            level1idx,
            level2idx,
            level3idx,
            inputMain,
            inputSub,
            isAfter,
            insertType, // 'same' | 'sub'
            selectedSubChapter, // 选中的子章节索引
        } = opts;

        // 插入同级章节
        if (insertType === 'same') {
            const idxDiff = isAfter ? 1 : 0;

            if (level === 1) {
                this.paragraphs.splice(level1idx + idxDiff, 0, {
                    chapter: inputMain,
                    sections: [],
                });
            } else if (level === 2) {
                this.paragraphs[level1idx].sections.splice(level2idx + idxDiff, 0, {
                    chapter: inputMain,
                    chapterAbstract: inputSub,
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    subsections: [],
                });
            } else if (level === 3) {
                this.paragraphs[level1idx].sections[level2idx].subsections.splice(
                    level3idx + idxDiff,
                    0,
                    {
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                    },
                );
            }
            return;
        }

        // 插入子级章节
        if (insertType === 'sub') {
            // 一级章节插入节
            if (level === 1) {
                // 确保sections是一个数组
                if (!this.paragraphs[level1idx].sections) {
                    this.paragraphs[level1idx].sections = [];
                }
                const sections = this.paragraphs[level1idx].sections;
                // 如果没有子节，直接添加到末尾
                if (!sections.length) {
                    sections.push({
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                        subsections: [],
                    });
                } else {
                    // 有子节时，根据选择的位置插入
                    const insertIdx = selectedSubChapter + (isAfter ? 1 : 0);
                    sections.splice(insertIdx, 0, {
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                        subsections: [],
                    });
                }
            }
            // 二级节插入小节
            else if (level === 2) {
                // 确保subsections是一个数组
                if (!this.paragraphs[level1idx].sections[level2idx].subsections) {
                    this.paragraphs[level1idx].sections[level2idx].subsections = [];
                }
                const subsections = this.paragraphs[level1idx].sections[level2idx].subsections;
                // 如果没有小节，直接添加到末尾
                if (!subsections.length) {
                    subsections.push({
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                    });
                } else {
                    // 有小节时，根据选择的位置插入
                    const insertIdx = selectedSubChapter + (isAfter ? 1 : 0);
                    subsections.splice(insertIdx, 0, {
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                    });
                }
            }
        }
    }

    editTitle(opts) {
        if (this.isLoadingPreview) {
            message.info('论文预览生成中，请结束后再试');
            return;
        }

        const { level, level1idx, level2idx, level3idx, inputMain, inputSub } = opts;
        if (level === 1) {
            this.paragraphs[level1idx] = {
                ...this.paragraphs[level1idx],
                chapter: inputMain,
            };
        } else if (level === 2) {
            this.paragraphs[level1idx].sections[level2idx] = {
                ...this.paragraphs[level1idx].sections[level2idx],
                chapter: inputMain,
                chapterAbstract: inputSub,
            };
        } else if (level === 3) {
            this.paragraphs[level1idx].sections[level2idx].subsections[level3idx] = {
                ...this.paragraphs[level1idx].sections[level2idx].subsections[level3idx],
                chapter: inputMain,
                chapterAbstract: inputSub,
            };
        }
    }

    deleteTitle(opts) {
        if (this.isLoadingPreview) {
            message.info('论文预览生成中，请结束后再试');
            return;
        }

        const { level, level1idx, level2idx, level3idx, txt } = opts;
        Modal.confirm({
            title: '确认删除吗',
            content: `确认删除章节<${txt}>吗？章节不足可能会导致论文总体字数不足`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (level === 1) {
                    this.paragraphs.splice(level1idx, 1);
                } else if (level === 2) {
                    this.paragraphs[level1idx].sections.splice(level2idx, 1);
                } else if (level === 3) {
                    this.paragraphs[level1idx].sections[level2idx].subsections.splice(level3idx, 1);
                }
            },
            onCancel: () => {},
        });
    }

    changeTitleExtra(opts) {
        if (this.isLoadingPreview) {
            message.info('论文预览生成中，请结束后再试');
            return;
        }

        const { v, level, level1idx, level2idx, level3idx } = opts;
        const { checked, type } = v;
        if (level === 1) {
        } else if (level === 2) {
            const origin = this.paragraphs[level1idx].sections[level2idx].selected_extra;
            this.paragraphs[level1idx].sections[level2idx] = {
                ...this.paragraphs[level1idx].sections[level2idx],
                selected_extra: modifySelectExtra({ origin, checked, type }),
            };
        } else if (level === 3) {
            const origin =
                this.paragraphs[level1idx].sections[level2idx].subsections[level3idx]
                    .selected_extra;
            this.paragraphs[level1idx].sections[level2idx].subsections[level3idx] = {
                ...this.paragraphs[level1idx].sections[level2idx].subsections[level3idx],
                selected_extra: modifySelectExtra({ origin, checked, type }),
            };
        }
    }

    /** 获取服务列表 */
    async getServiceList() {
        try {
            const result = await get('/ai/ai/paper/add/services/list2');
            this.serviceList = result?.data.map((it) => ({ ...it, checked: false })) || [];
        } catch (error) {
            console.error(error);
        }
    }

    onModalConfirmGetSummary(notiApi) {
        this.showCheckTableDialog = false;
        this.getSummary(notiApi);
    }

    // 在论文类型为开题报告，文献综述时，需要传入论文类型
    get getFunType() {
        return this.docType === 'PROPOSAL_REPORT'
            ? 4
            : this.docType === 'LITERATURE_REVIEW'
              ? 5
              : 1;
    }

    // 统计引用文献的标题和字数之和，不需要去除空格
    getReferenceTitleAndWordCount() {
        if (!this.customReference.customReferenceDocument.length) return 0;
        return this.customReference.customReferenceDocument.reduce((pre, cur) => {
            return pre + (cur?.title?.length || 0) + (cur?.citationInfo?.length || 0);
        }, 0);
    }

    /** 生成大纲 */
    async getSummary(notiApi) {
        // 如果引用文献的标题和字数之和大于15000，则不生成大纲
        if (this.getReferenceTitleAndWordCount() > 15000) {
            message.warning('参考文献字数请不要超过15000');
            return;
        }

        if (!this.majorName) {
            message.warning('输入论文题目后一键帮您生成大纲');
            return;
        }
        if (!this.isCheckConfirm) {
            message.warning('请先勾选免责声明');
            return;
        }
        // 如果为文献综述和开题报告，不显示确认表格
        if (this.docType === 'LITERATURE_REVIEW' || this.docType === 'PROPOSAL_REPORT') {
            this.hasConfirmTable = true;
        }
        if (this.autoLightTypes.length === 0 && !this.hasConfirmTable) {
            this.showCheckTableDialog = true;
            this.hasConfirmTable = true;
            return;
        }
        // 没有引用文献
        // 没有确认无文献提示消息
        // 没有勾选推荐文献
        if (
            this.customReference.customReferenceDocument.length === 0 &&
            !this.isConfirmCustomReferenceDocument &&
            !this.isReref
        ) {
            this.confirmCustomReferenceDocumentModal = true;
            return;
        }
        const key = `getSummary`;
        this._tempAutoText = this.getAutoText();
        notiApi.info({
            key,
            message: '大纲开始生成',
            description: GetSummaryNotiDom(this.getAutoText()),
            className: styles.getSummaryNoti,
            duration: 0,
            icon: <InfoCircleOutlined />,
        });
        try {
            this.paragraphs = null;
            this.isLoadingSummary = true;

            const result = await post('/ai/ai/paper/gen/outline', {
                subject: '11',
                majorName: this.majorName,
                threeOutline: 'y', // y or n
                version: this.useUltra ? 'ultra' : 'd',
                autoLightTypes:
                    this.autoLightTypes && this.autoLightTypes.join
                        ? this.autoLightTypes.join(',')
                        : '',
                isReref: this.isReref ? 'y' : 'n',
                funType: this.getFunType,
            });
            this.paragraphs = result.data;
            this.currentStep = 1;
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                this.isLoadingSummary = false;
                setTimeout(() => {
                    document
                        .getElementById('summaryHeader')
                        ?.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }, 700);
            notiApi.destroy(key);
        }
    }

    //获取预览
    async getPreview() {
        try {
            this.isLoadingPreview = true;
            const customRef = this.customReference.customReferenceDocument.reduce(
                (pre, cur, index) => {
                    let serialNo = index + 1;
                    if (cur?.isCustom) {
                        pre.customAbstracts.push({
                            serialNo,
                            ref: cur.citationInfo,
                            abstractText: cur.anAbstract,
                            language: 1, // 1中，2英文
                        });
                    } else {
                        pre.abstracts.push({
                            serialNo,
                            ref: cur.citationInfo,
                            abstractText: cur.anAbstract,
                            language: 1,
                        });
                    }
                    return pre;
                },
                {
                    abstracts: [],
                    customAbstracts: [],
                },
            );
            // const result = await post('/ai/ai/paper/gen', {
            const result = await post('/ai/ai/paper/create/paper', {
                subject: '11',
                majorName: this.majorName,
                threeOutline: 'y', // y or n
                version: this.useUltra ? 'u' : 'd',
                paperOutline: this.paragraphs,
                isReref: this.isReref ? 'y' : 'n',
                ...customRef,
                funType: this.getFunType,
            });

            this.paperInfo = result.data;

            await this.getServiceList();
            await this.getPriceAfterAddServices();
            console.log('preview------->', result.data);
            this.paragraphs = result.data.outlines;
            this.currentStep = 2;
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.isLoadingPreview = false;
        }
    }

    async getFinalPriview() {
        try {
            this.nextPreviewLoading = true;
            const customRef = this.customReference.customReferenceDocument.reduce(
                (pre, cur, index) => {
                    let serialNo = index + 1;
                    if (cur?.isCustom) {
                        pre.customAbstracts.push({
                            serialNo,
                            ref: cur.citationInfo,
                            abstractText: cur.anAbstract,
                            language: 1, // 1中，2英文
                        });
                    } else {
                        pre.abstracts.push({
                            serialNo,
                            ref: cur.citationInfo,
                            abstractText: cur.anAbstract,
                            language: 1,
                        });
                    }
                    return pre;
                },
                {
                    abstracts: [],
                    customAbstracts: [],
                },
            );
            const result = await post('/ai/ai/paper/preview/article', {
                orderNo: this.paperInfo.orderNo,
                subject: '11',
                majorName: this.majorName,
                threeOutline: 'y', // y or n
                version: this.useUltra ? 'ultra' : 'd',
                paperOutline: this.paragraphs,
                ...customRef,
            });
            const preList = this.paperInfo.list || [];

            const list = [...preList, ...result.data.list];

            this.paperInfo.list = list;
            this.paperInfo.orderNo = result.data.orderNo;
            this.hasPreviewEnd = true;
            await Promise.all([this.getPriceAfterAddServices()]);
        } catch (error) {
            console.error(error);
        } finally {
            this.nextPreviewLoading = false;
        }
    }

    updateOutline = async () => {
        try {
            const result = await post('/ai/ai/paper/update/outlines', {
                version: this.useUltra ? 'ultra' : 'd',
                paperOutline: this.paragraphs,
                orderNo: this.paperInfo.orderNo,
            });

            await Promise.all([this.getPriceAfterAddServices()]);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    onServiceCheck(checked, idx) {
        this.serviceList[idx].checked = checked;
        this.getPriceAfterAddServices();
    }

    async getPriceAfterAddServices() {
        try {
            this.isExtraloading = true;
            const { data } = await post('/ai/ai/paper/add/services', {
                orderNo: this.paperInfo.orderNo,
                ids: this.serviceList
                    .filter((s) => s.checked)
                    .map((s) => s.id)
                    .join(','),
            });

            runInAction(() => {
                this.paperInfo.orderNo = data.orderNo;

                this.paperInfo.price = data.price;
                this.paperInfo.prices = data;
                this.isExtraloading = false;
            });
        } catch (error) {
            console.error(error);
            runInAction(() => {
                this.isExtraloading = false;
            });
        }
    }

    async getCatogories() {
        try {
            const res = await get('/ai/paper/subject/tree');
            this.categories = res.data;
        } catch (error) {
            console.error(error);
        }
    }

    onReferenceCheck(v) {
        this.customReference.checked = v;
    }

    onReferenceChange(e) {
        this.customReference.customReferenceDocument = e.target.value;
    }

    getSelectTypeCount() {
        const data = this.paragraphs || [];
        const counts = {
            CODE: 0,
            TABLE: 0,
            DIAGRAM: 0,
            FORMULA: 0,
        };

        // 定义有效的类型集合
        const validTypes = new Set(Object.keys(counts));

        // 优化后的计数函数
        const countOccurrences = (str, type) => {
            if (!str || typeof str !== 'string') return 0;
            // 将多个空格替换为单个空格，移除首尾空格，并按逗号分割
            return str
                .split(',')
                .map((item) => item.trim())
                .filter((item) => item === type).length;
        };

        // 优化后的处理函数
        const processSelectedExtra = (selectedExtra) => {
            if (!selectedExtra) return;

            // 预处理字符串，移除多余空格
            const cleanStr = selectedExtra.trim().replace(/\s+/g, ' ');

            // 统计每种类型的出现次数
            validTypes.forEach((type) => {
                const count = countOccurrences(cleanStr, type);
                if (count > 0) {
                    counts[type] += count;
                }
            });
        };

        // 遍历数据结构保持不变
        data.forEach((chapter) => {
            processSelectedExtra(chapter.selected_extra);

            if (Array.isArray(chapter.sections)) {
                chapter.sections.forEach((section) => {
                    processSelectedExtra(section.selected_extra);

                    if (Array.isArray(section.subsections)) {
                        section.subsections.forEach((sub) => {
                            processSelectedExtra(sub.selected_extra);
                        });
                    }
                });
            }
        });

        return counts;
    }
}

const summaryStore = new SummaryStore();
export { summaryStore };

function modifySelectExtra(opt) {
    const { origin, checked, type } = opt;
    const originList = origin?.split(',') || [];
    const isCheckedBefore = originList.includes(type);
    if (!isCheckedBefore && checked) {
        originList.push(type);
        return originList.join(',');
    }
    if (isCheckedBefore && !checked) {
        const idx = originList.indexOf(type);
        originList.splice(idx, 1);
        return originList.join(',');
    }
    return origin;
}

const GetSummaryNotiDom = (txt) => (
    <>
        <p>
            耗时约60s，请不要刷新页面，喝杯咖啡☕️稍作等待。大纲的小节支持<b>增加</b>、<b>删除</b>、
            <b>修改</b>
        </p>
        <p className={'flex items-center'}>
            点亮小节 <span className={'-translate-y-0.5'}>{TableIcon}</span>: 插入<b>数据(表)</b>
        </p>
        <p className={'flex items-center'}>
            点亮小节 <span className={'-translate-y-0.5'}>{PicIcon}</span>: 插入<b>图</b>
        </p>
        <p className={'flex items-center'}>
            点亮小节
            <span className={'-translate-y-0.5'}>
                <CalculatorOutlined style={{ color: '#000' }} />
            </span>
            : 插入<b>公式</b>
        </p>
        <p className={'flex items-center'}>
            点亮小节
            <span className={'-translate-y-0.5'}>{CodeIcon}</span>: 插入<b>代码</b>
        </p>
        {txt && (
            <p style={{ width: '330px' }}>
                <b>小灵会自动根据您的需要点亮相应的{txt}</b>
                <br />
                您可根据需要进行<b>添加或删减</b>
            </p>
        )}
    </>
);

// 提取大纲成为目录
export function convertToTreeStructure(paperStructure) {
    // 用于生成唯一 key
    let counter = 0;

    // 创建根节点
    const treeData = {
        title: '论文目录',
        key: `node-${counter++}`,
        children: [],
    };

    // 遍历每个章节
    paperStructure.forEach((chapter) => {
        // 创建章节节点
        const chapterNode = {
            title: chapter.chapter,
            key: `node-${counter++}`,
            children: [],
            // 可选：保留其他数据作为节点的额外信息
            abstract: chapter.chapterAbstract || null,
            extraType: chapter.extraType || null,
            extraTypeCn: chapter.extraTypeCn || null,
        };

        // 如果有子节且不为 null
        if (chapter.sections && Array.isArray(chapter.sections)) {
            chapter.sections.forEach((section) => {
                // 创建子节节点
                const sectionNode = {
                    title: section.chapter,
                    key: `node-${counter++}`,
                    children: [],
                    abstract: section.chapterAbstract || null,
                    extraType: section.extraType || null,
                    extraTypeCn: section.extraTypeCn || null,
                };

                // 如果有小节且不为 null
                if (section.subsections && Array.isArray(section.subsections)) {
                    section.subsections.forEach((subsection) => {
                        sectionNode.children.push({
                            title: subsection.chapter,
                            key: `node-${counter++}`,
                            children: [],
                            abstract: subsection.chapterAbstract || null,
                            extraType: subsection.extraType || null,
                            extraTypeCn: subsection.extraTypeCn || null,
                        });
                    });
                }

                chapterNode.children.push(sectionNode);
            });
        }

        treeData.children.push(chapterNode);
    });

    return [treeData]; // Tree 组件需要数组格式
}
