import { Image } from '@tiptap/extension-image';

export const CustomImage = Image.extend({
    name: 'customImage',

    addAttributes() {
        // 获取原有属性
        const baseAttributes = this.parent?.() || {};

        return {
            ...baseAttributes, // 保留原有属性
            id: {
                // 添加 id 属性
                default: null,
                parseHTML: (element) => element.getAttribute('id'),
                renderHTML: (attributes) => ({
                    id: attributes.id,
                }),
            },
        };
    },
});
