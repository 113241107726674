import React, {useState} from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import CustomDrawer from './CustomDrawer'
import TextContainer from "./TextContainer";
import InputContainer from "./InputContainer";

// 插入
const InsertModal = NiceModal.create(({ content, from, to, chapterId, setEditorFocus }) => {
    const modal = useModal();
    const handleOk = () => {
        console.log('submit');
        modal.resolve(text);
        modal.hide();
    };
    const handleReset = () => {
        console.log('reset');
    };
    const [value, setValue] = useState('')
    const onChange = (e) => {
        setValue(e.target.value);
    }
    const text =
        '本研究旨在通过系统化的分析与设计流程，构建一套高效、智能的宿舍管理系统，以应对当前高校宿舍管理中存在的效率低下、信息孤岛及用户体验不佳等问题。研究思路起始于深入调研当前宿舍管理的现状与痛点，通过问卷调查、访谈等方式收集来自学生、宿舍管理员及后勤人员的第一手需求数据。这些数据详细揭示了传统管理模式下的具体问题，包括但不限于：';

    return (
        <CustomDrawer
            title="章节插文"
            visible={modal.visible}
            onClose={() => {
                modal.reject();
                modal.hide();
            }}
            okText="插入文章"
            resetText="重新生成"
            onOk={handleOk}
            onReset={handleReset}
        >
            <InputContainer title="提示词" onChange={onChange} value={value} />
            <div className="mt-4">
                <TextContainer type="active" title="插文结果" content={text} />
            </div>
        </CustomDrawer>
    );
})

export default InsertModal
