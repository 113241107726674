import includeContent from '../../assets/include-content.png';
import { FEATURES, NOTICES } from '../Documents/const';
import gentleTip from '../../assets/gentle-tip.png';

// 描述卡片
const DescCards = () => {
    return (
        <div
            className={'w-full mt-8 mb-12 flex flex-col md:flex-row justify-between gap-4 text-sm'}
        >
            {/*左侧描述list*/}
            <div className={'flex-1 p-4 bg-white bg-opacity-50 rounded-xl'}>
                <div className={'flex justify-start'}>
                    <img className={'w-[100px] h-[26px] mb-4'} src={includeContent} alt="" />
                </div>
                <div className={'w-full flex flex-col md:flex-row justify-between'}>
                    <div className={'flex flex-col gap-4 w-48'}>
                        {FEATURES.left.map((it) => {
                            return (
                                <div key={it} className={' flex justify-start items-center gap-2'}>
                                    <div className={'w-1.5 h-1.5 bg-primary  rounded-full'} />
                                    <span className={'text-[#414C6F] text-xs'}>{it}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className={'flex flex-col gap-4 mt-4 md:mt-0'}>
                        {FEATURES.right.map((it) => {
                            return (
                                <div key={it} className={'flex items-center gap-2'}>
                                    <div className={'w-1.5 h-1.5 bg-primary  rounded-full'} />
                                    <span className={'text-[#414C6F] text-xs'}>{it}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {/*右侧描述list*/}
            <div className={'flex-1 p-4 bg-white bg-opacity-50 rounded-xl'}>
                <div className={'flex justify-start'}>
                    <img className={'w-[100px] h-[26px] mb-4'} src={gentleTip} alt="" />
                </div>
                <div className={'flex flex-col items-start gap-4'}>
                    {NOTICES.map((it) => {
                        return (
                            <div key={it} className={'flex items-center gap-2'}>
                                <div className={' w-1.5 h-1.5 bg-primary  rounded-full'} />
                                <span className={'text-[#414C6F]  text-xs'}>{it}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default DescCards;
