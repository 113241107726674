import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Steps, Tooltip, message, notification } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import titleUltra from '../../assets/title-ultra.png';
import title from '../../assets/title.png';
import DescCards from '../DescCards/index';
import { DOC_TYPES, STEPS } from './const';
import { Footer } from './Footer';
import styles from './index.module.scss';
import Step1 from './Step1/index';
import Step2 from './Step2/index';
import { summaryStore } from './store';
import { Progress } from './Summary/Progress';
import { UltraDialog } from './UltraDialog';

import iconCodeActive from '../../assets/icon-code-active.svg';
import iconDiagramActive from '../../assets/icon-diagram-active.svg';
import iconFormulaActive from '../../assets/icon-formula-active.svg';
import iconTableActive from '../../assets/icon-table-active.svg';

import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import activeNormal from '../../assets/active-normal.png';
import activeUltra from '../../assets/active-ultra.png';
import example from '../../assets/example.jpg';
import { useDevice } from '../../hooks/useDevice';
import { PPT_STEPS, REDUCE_STEPS } from './const';
import { Step1 as ReduceStep1 } from '../Reduce/Step1';
import PPTStep1 from './PPTStep1/index';
import Step3 from './Step3/index';
import { CodeIcon, GongshiIcon, PicIcon, TableIcon } from './Summary/icons';

export const Documents = observer(() => {
    const isPPT = summaryStore.docType === 'DEFENSE_PPT';
    const isReduce = summaryStore.docType === 'THESIS_MODIFICATION';

    const [notiApi, notiContextHolder] = notification.useNotification();

    const [modal, contextHolder] = Modal.useModal();

    const items = isPPT
        ? PPT_STEPS.map((item) => ({ key: item.key, title: item.title, disabled: true }))
        : isReduce
          ? REDUCE_STEPS.map((item) => ({
                key: item.key,
                title: item.title,
                disabled: true,
            }))
          : STEPS.map((item) => ({
                key: item.key,
                title: item.title,
                disabled: true,
            }));

    const [top, setTop] = useState(0);

    const nav = useNavigate();

    const onScroll = useMemo(
        () =>
            debounce(() => {
                const el = document.getElementById('summaryEl');
                if (!el) return;
                const maxH = el ? el.offsetHeight - 180 : 1000;
                const distance = document.scrollingElement.scrollTop - el.offsetTop + 196;
                setTop(Math.min(Math.max(0, distance), maxH));
            }, 500),
        [],
    );

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    const { isMobile } = useDevice();

    return (
        <>
            {notiContextHolder}
            {contextHolder}
            {/*分步1*/}
            {summaryStore.currentStep === 0 && (
                <div className={styles.documents}>
                    <div
                        className={classNames(styles.bg, {
                            [styles.bgUltra]: summaryStore.useUltra,
                        })}
                    />
                    <div className={`${styles.content} lg-container`}>
                        <div className={styles.title}>
                            {/* <img
                                src={summaryStore.useUltra ? titleUltra : title}
                                className={'h-6 lg:h-9'}
                            /> */}
                            <div
                                className={
                                    summaryStore.useUltra
                                        ? styles.ultraBannerText
                                        : styles.bannerText
                                }
                            >
                                - 欢迎来到灵感风暴，动动手指让你灵感满满 -
                            </div>
                        </div>
                        <div className={styles.subTitle}>
                            简单几步即可生成内容，灵感风暴帮助完成学术写作与提升研究质量
                        </div>
                        {/* 生成文献类型内容分类 */}
                        <div className={'w-full px-2.5 md:px-0'}>
                            <div className={styles.docTypeContainer}>
                                {DOC_TYPES.map((doc, index) => {
                                    const isActive = summaryStore.docType === doc.key;
                                    return (
                                        <div
                                            className={`${styles.docTypeItem} ${
                                                isActive ? styles.active : ''
                                            }`}
                                            key={doc.key}
                                            onClick={() => {
                                                // 如果是论文降重功能，需要在ultra模式下
                                                if (
                                                    doc.key === 'THESIS_MODIFICATION' &&
                                                    !summaryStore.useUltra
                                                ) {
                                                    modal.confirm({
                                                        title: '提示',
                                                        content:
                                                            '论文降重功能仅在ultra模式下可用, 是否切换到ultra模式?  ',
                                                        onOk: () => {
                                                            message.success('已切换到ultra模式');
                                                            summaryStore.toggleUseUltra();
                                                            summaryStore.setDocType(doc.key);
                                                        },
                                                    });
                                                    return;
                                                }
                                                summaryStore.autoLightTypes = [];
                                                summaryStore.setDocType(doc.key);
                                            }}
                                        >
                                            <img src={doc.icon} alt={doc.label} />
                                            <span>{doc.label}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/*切换ultra和normal*/}
                        <div className={styles.opts}>
                            <img
                                onClick={() => {
                                    summaryStore.toggleUseUltra();
                                }}
                                className={'h-10 w-[209px] cursor-pointer'}
                                src={summaryStore.useUltra ? activeUltra : activeNormal}
                                alt=""
                            />
                            <div
                                className={'flex cursor-pointer items-center text-textGray'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    summaryStore.toggleUltraDialog();
                                }}
                            >
                                <span className={'mr-1'}>版本对比介绍</span>
                                <Tooltip title="点击查看版本对比介绍">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        </div>
                        {/*分步表单*/}
                        <div className={`${styles.stepFormContainer}`}>
                            <div
                                className={
                                    'w-full origin-left scale-75 md:mb-8 md:scale-100 md:px-20'
                                }
                            >
                                <Steps
                                    current={summaryStore.currentStep}
                                    onChange={(e) => {
                                        summaryStore.setCurrentStep(e);
                                    }}
                                    labelPlacement={isMobile ? 'vertical' : 'horizontal'}
                                    responsive={false}
                                    items={items}
                                />
                            </div>
                            {!summaryStore.isLoadingSummary ? (
                                isPPT ? (
                                    <PPTStep1 />
                                ) : isReduce ? (
                                    <ReduceStep1 />
                                ) : (
                                    <Step1
                                        generateOutline={() => {
                                            summaryStore.getSummary(notiApi);
                                        }}
                                    />
                                )
                            ) : null}
                            {summaryStore.isLoadingSummary ? (
                                <>
                                    <Step1Progress />
                                    <div
                                        className={
                                            'mt-6 rounded-xl border border-[#EEEEF1] px-3 py-4 md:px-28'
                                        }
                                    >
                                        <div className={'mb-6 text-sm'}>
                                            大纲小节支持{' '}
                                            <span className={'text-primary'}>增加、</span>
                                            <span className={'text-primary'}>删除、</span>
                                            <span className={'text-primary'}>修改</span>
                                            ；点击图标可插入相应内容
                                        </div>
                                        <div className={'flex justify-between px-0 md:px-7'}>
                                            <div
                                                className={
                                                    'flex flex-col items-center justify-between'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'flex h-8 w-8 items-center justify-center rounded-full bg-[#A570FF] bg-opacity-10'
                                                    }
                                                >
                                                    <img
                                                        className={'h-4 w-4'}
                                                        src={iconTableActive}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className={'mt-2'}>插入数据表</div>
                                            </div>
                                            <div
                                                className={
                                                    'flex flex-col items-center justify-between'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'flex h-8 w-8 items-center justify-center rounded-full bg-[#A570FF] bg-opacity-10'
                                                    }
                                                >
                                                    <img
                                                        className={'h-4 w-4'}
                                                        src={iconDiagramActive}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className={'mt-2'}>插入数据图</div>
                                            </div>
                                            <div
                                                className={
                                                    'flex flex-col items-center justify-between'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'flex h-8 w-8 items-center justify-center rounded-full bg-[#A570FF] bg-opacity-10'
                                                    }
                                                >
                                                    <img
                                                        className={'h-4 w-4'}
                                                        src={iconFormulaActive}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className={'mt-2'}>插入公式</div>
                                            </div>
                                            <div
                                                className={
                                                    'flex flex-col items-center justify-between'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'flex h-8 w-8 items-center justify-center rounded-full bg-[#A570FF] bg-opacity-10'
                                                    }
                                                >
                                                    <img
                                                        className={'h-4 w-4'}
                                                        src={iconCodeActive}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className={'mt-2'}>插入代码</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <DescCards />
                    </div>
                    <div className={'z-2 relative'}>
                        <Footer />
                    </div>
                    <UltraDialog />
                    <Modal
                        open={summaryStore.confirmCustomReferenceDocumentModal}
                        title="提示"
                        onOk={() => {
                            summaryStore.isConfirmCustomReferenceDocument = true;
                            summaryStore.confirmCustomReferenceDocumentModal = false;
                        }}
                        onCancel={() => {
                            summaryStore.confirmCustomReferenceDocumentModal = false;
                        }}
                        cancelButtonProps={{
                            style: {
                                display: 'none',
                            },
                        }}
                    >
                        <div>
                            <div>为了生成文章内容的准确，建议您填写参考文献！</div>
                        </div>
                    </Modal>
                    <Modal
                        title="丰富论文内容"
                        open={summaryStore.showCheckTableDialog}
                        okText="继续生成大纲"
                        footer={
                            <Button
                                onClick={() => {
                                    summaryStore.onModalConfirmGetSummary(notiApi);
                                }}
                                type="primary"
                            >
                                确认
                            </Button>
                        }
                        closable={false}
                    >
                        <div>
                            <div>
                                如您的论文需要包含
                                <hl>表，图，公式，代码</hl>
                                ，您可在在下方勾选相应选项，小灵会为您自动生成
                                <Tooltip
                                    color="#fff"
                                    title={
                                        <span
                                            style={{
                                                color: '#18191c',
                                                width: '250px',
                                            }}
                                        >
                                            您可根据论文需求需要���选相应的
                                            <hl>表，图，公式，代码</hl>
                                            <br />
                                            在生成大纲后仍可继续进行<hl>添加或删减</hl>
                                            <br />
                                            点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：
                                            <img
                                                src={example}
                                                style={{
                                                    color: '#18191c',
                                                    width: '240px',
                                                }}
                                            />
                                        </span>
                                    }
                                >
                                    <QuestionCircleOutlined
                                        color="#999999"
                                        className={styles.summaryIcon}
                                        style={{ marginLeft: '4px' }}
                                    />
                                </Tooltip>
                            </div>
                            <div
                                className={styles.summaryChecks}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox.Group
                                    value={summaryStore.autoLightTypes}
                                    onChange={(e) => summaryStore.onAutoLightChange(e)}
                                    disabled={summaryStore.isLoadingSummary}
                                >
                                    <Checkbox value="TABLE">表</Checkbox>

                                    <Checkbox value="DIAGRAM">图</Checkbox>

                                    <Checkbox value="FORMULA">公式</Checkbox>

                                    <Checkbox value="CODE">代码</Checkbox>
                                </Checkbox.Group>
                            </div>
                            <a
                                href="https://lingganfengbao.com/example"
                                target="_blank"
                                rel="noreferrer"
                            >
                                查看范文样例
                            </a>

                            {/* <div style={{ color: "#18191c" }} className={styles.summaryTips}>
            您可根据论文需求需要勾选相应的<hl>表，图，公式，代码</hl>，
            在生成大纲后仍可继续进行<hl>添加或删减</hl>
            ，点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：
            <img src={example} style={{ color: "#18191c", width: "240px" }} />
          </div> */}
                        </div>
                    </Modal>
                </div>
            )}
            {/*分步2*/}
            {summaryStore.currentStep === 1 ? (
                <div className={styles.documents}>
                    <div
                        className={classNames(styles.bg, {
                            [styles.bgUltra]: summaryStore.useUltra,
                        })}
                    ></div>
                    <div
                        className={`lg-container relative mx-auto my-16 flex flex-col items-center rounded-xl bg-white p-2 pb-4 md:p-10`}
                    >
                        {isMobile ||
                        summaryStore.isLoadingPreview ||
                        summaryStore.isLoadingSummary ? null : (
                            <div
                                className={`${styles.summaryTips} w-64`}
                                style={{ top: top + 'px' }}
                            >
                                <div className={`${styles.bold} mb-3 text-primary`}>TIPS：</div>
                                <div className={'mb-2'}>👈点亮右侧按钮，可插入相应内容</div>
                                <div className={styles.line}>
                                    {TableIcon}

                                    <div className={'ml-1.5'}>
                                        插入<span>数据表</span>
                                    </div>
                                </div>
                                <div className={styles.line}>
                                    {PicIcon}
                                    <div className={'ml-1.5'}>
                                        插入<span>流程图</span>
                                    </div>
                                </div>
                                <div className={styles.line}>
                                    {GongshiIcon}
                                    <div className={'ml-1.5'}>
                                        插入<span>公式</span>
                                    </div>
                                </div>

                                <div className={styles.line}>
                                    {CodeIcon}
                                    <div className={'ml-1.5'}>
                                        插入<span>代码段</span>
                                    </div>
                                </div>
                                <div className={'my-3 border-b border-[#F7F1FF]'}></div>
                                <div className={styles.line}>
                                    <div>
                                        可根据需要进行 <span>新增、编辑、删除</span> 等操作
                                    </div>
                                </div>
                                {summaryStore._tempAutoText && (
                                    <div style={{ lineHeight: '20px' }}>
                                        <span>
                                            小灵会自动根据您的需要点亮相应的
                                            {summaryStore._tempAutoText}
                                        </span>
                                        <br />
                                        您可根据需要进行<span>添加或删减</span>
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className={'w-full origin-left scale-75 md:mb-8 md:scale-100 md:px-20'}
                        >
                            <Steps
                                current={summaryStore.currentStep}
                                labelPlacement={isMobile ? 'vertical' : 'horizontal'}
                                responsive={false}
                                onChange={(e) => {
                                    summaryStore.setCurrentStep(e);
                                }}
                                items={items}
                            />
                        </div>
                        {summaryStore.isLoadingSummary && summaryStore.currentStep === 1 && (
                            <Step1Progress />
                        )}
                        {summaryStore.isLoadingPreview && summaryStore.currentStep === 1 && (
                            <Progress
                                loading={summaryStore.isLoadingPreview}
                                end={!summaryStore.isLoadingPreview}
                                desc="小灵正在帮您生成预览，预计需要等待60s左右，请耐心等待~"
                                list={['启动中...', '检索文献', '分析大纲', '生成预览']}
                                distance={600}
                            />
                        )}
                        {!summaryStore.isLoadingPreview &&
                            summaryStore.currentStep === 1 &&
                            summaryStore.paragraphs &&
                            summaryStore.paragraphs.length > 0 && <Step2 />}
                    </div>
                </div>
            ) : null}
            {/*分步3*/}
            {summaryStore.currentStep === 2 && <Step3 />}
        </>
    );
});

const Step1Progress = observer(() => {
    return (
        <Progress
            loading={summaryStore.isLoadingSummary}
            end={summaryStore?.paragraphs && summaryStore?.paragraphs?.length}
            desc="小灵正在帮您生成大纲，预计需要等待60s左右，请耐心等待~"
            list={['启动中...', '分析题目', '检索文献', '生成大纲']}
        />
    );
});
