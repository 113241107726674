import Superscript from '@tiptap/extension-superscript';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import CharacterCount from '@tiptap/extension-character-count';
import { CustomHeading } from './CustomHeading';
import { CustomImage } from './CustomImage';
import { CustomParagraph } from './CustomParagraph';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';

export const extensions = [
    Superscript,
    StarterKit.configure({
        heading: {
            levels: [1, 2, 3, 4],
        },
    }),
    Image.configure({
        HTMLAttributes: {
            inline: false,
            contenteditable: false,
            // class: 'academic-image',
            id: null,
        },
        resizable: false,
        draggable: false,
        allowBase64: true,
    }),
    Table.configure({
        HTMLAttributes: {
            class: 'academic-table',
            // 'data-table-id': '',
            // contenteditable: false,
        },
        resizable: false,
        draggable: false,
    }),
    TableRow.configure({
        HTMLAttributes: {
            class: 'academic-table-row',
        },
    }),
    TableHeader.configure({
        HTMLAttributes: {
            class: 'academic-table-header',
        },
    }),
    TableCell.configure({
        HTMLAttributes: {
            class: 'academic-table-cell',
        },
    }),
    // CustomTable,
    CustomHeading,
    CustomParagraph,
    CustomImage,
    CharacterCount.configure({
        count: (text) => {
            console.log('text', text);
            // 移除空格和换行符后的所有字符都计数
            return text.replace(/\s+/g, '').length;
        },
    }),
];
