import { Modal, QRCode, Button, Alert, message, Input, Spin, Radio } from 'antd';
import { useState, useEffect, useRef } from 'react';
import error from '../../assets/error.png';
import success from '../../assets/success.png';
import { post, get } from '../../axios/axios';
import { globalData } from '../../Service/utils';

// orderNo price
export const BuyModal = (props) => {
    const [payResult, setPayResult] = useState(false);
    const payInfoRef = useRef({});
    const [showPayResult, setShowPayResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payType, setPayType] = useState(1); // 1:微信 2:支付宝
    const [alipayUrl, setAlipayUrl] = useState('');

    useEffect(() => {
        submitPay();
    }, []);

    useEffect(() => {
        globalData.buyModalDestoryed = false;
        return () => {
            globalData.timerId && window.clearTimeout(globalData.timerId);
            globalData.buyModalDestoryed = true;
        };
    }, []);

    const submitPay = async () => {
        try {
            if (loading) {
                return;
            }
            const isMobile = false;
            const isWexin = false;
            if (isWexin && isMobile) {
                const res = await get(`/member/wechat/a/url?orderNo=${props.orderNo}`);
                window.location.href = res;
                return;
            }

            setLoading(true);
            const client = isMobile ? 'H5' : 'PC';
            const res = await post('/member/payment/pay', {
                orderNo: props.orderNo,
                client: client,
                payType: payType,
            });

            if (client === 'H5') {
                window.location.href = res.data.mwebUrl;
            } else {
                if (payType === 1 && res.data.codeUrl) {
                    payInfoRef.current = {
                        ...(res.data || {}),
                    };
                } else if (payType === 2 && res.data.form) {
                    setAlipayUrl(res.data.form);
                }
                checkPay();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const checkPay = async () => {
        globalData.timerId && window.clearTimeout(globalData.timerId);

        try {
            const res = await get('/member/payment/pay/result/' + props.orderNo);
            console.log('1', res);
            if (res && res.data && res.data.code === 0) {
                setShowPayResult(true);
                setPayResult(res.data.code === 0);

                setTimeout(() => {
                    if (res.data.code === 0) {
                        globalData.timerId && window.clearTimeout(globalData.timerId);
                        props.handleOk && props.handleOk(1);
                    }
                }, 1000);
            } else {
                const id = window.setTimeout(() => {
                    checkPay();
                }, 1000);
                globalData.timerId = id;
            }
        } catch (err) {
            console.error(err);
        }
    };

    const toRePay = async () => {
        setShowPayResult(false);
        const res = await post('/member/payment/pay', {
            orderNo: props.orderNo,
            client: 'PC',
            payType: payType,
        });

        if (payType === 1 && res.data.codeUrl) {
            payInfoRef.current = res.data || {};
        } else if (payType === 2 && res.data.form) {
            setAlipayUrl(res.data.form);
        }
    };

    function onCancel() {
        globalData.timerId && window.clearTimeout(globalData.timerId);
        payInfoRef.current = {};
        setAlipayUrl('');
    }

    return showPayResult ? (
        <div className="pay-result">
            <div className="result" style={payResult ? { padding: '16px 0 20px 0' } : {}}>
                <p className="tips">{payResult ? '恭喜您，支付成功' : '很遗憾，支付失败'}</p>
                <img
                    src={payResult ? success : error}
                    className="image"
                    style={payResult ? { margin: '16px 0 20px 0' } : {}}
                />
                {!payResult ? (
                    <Button className="action_btn" onClick={toRePay}>
                        <span>重新支付</span>
                    </Button>
                ) : (
                    <Button onClick={onCancel}>
                        <span>完成</span>
                    </Button>
                )}
            </div>
        </div>
    ) : (
        <div className="w-pay">
            <Alert
                message="付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮"
                type="warning"
                showIcon
                className="w-pay-alert"
            />
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <Radio.Group
                    onChange={(e) => {
                        setPayType(e.target.value);
                        submitPay();
                    }}
                    value={payType}
                >
                    <Radio.Button value={1}>微信支付</Radio.Button>
                    <Radio.Button value={2}>支付宝</Radio.Button>
                </Radio.Group>
            </div>
            <div className="title">{payType === 1 ? '请使用微信扫一扫' : '请使用支付宝扫一扫'}</div>
            <p className="order">订单号：{props.orderNo}</p>
            <div className="qr-code">
                {payType === 1 ? (
                    payInfoRef.current.codeUrl ? (
                        <QRCode value={payInfoRef.current.codeUrl} />
                    ) : null
                ) : alipayUrl ? (
                    <iframe
                        style={{
                            width: '170px',
                            height: '170px',
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                        src={alipayUrl}
                        title="支付宝支付"
                    ></iframe>
                ) : null}
            </div>
            <p className="cost-tips">
                支付金额 <span className="unit">¥</span>
                <span className="value">{props.price || ''}</span>
            </p>
            <div className="button-group">
                <Button type="primary" onClick={checkPay}>
                    <span>付款成功</span>
                </Button>
            </div>
            <p className="mt-2 px-10 text-xs text-[#999]">
                虚拟产品，支付前请仔细确认，结果由AI根据您提供的信息自动生成，仅供参考，支付成功后立即生效，不支持退款。
            </p>
            <p className="tips">如遇支付问题，请联系客服微信</p>
        </div>
    );
};
