import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { get } from '../../axios/axios';
import { loginInstance } from '../../Service/login';
import { SSE } from 'sse.js';
import { message } from 'antd';
import { sleep } from '../../helper';


export function useTemplateData(isDashboard = false) {
    const [tabs, setTabs] = useState([]);
    const [cards, setCards] = useState([]);
    useEffect(() => {
        (async () => {
            const res = await get('/member/template/category/list');
            setTabs([
                {
                    icon: null,
                    id: 'all',
                    name: '全部',
                    orderNum: 0,
                },
                ...(res.data || []),
            ]);
        })();
        (async () => {
            const res = await get('/member/template/list');
            // if (isDashboard) {
            //   res.data.unshift(genConstructEntry(8));
            // }
            setCards(res.data);
        })();
    }, []);
    return {
        tabs,
        cards,
    };
}

/** 执行 eventSteam 的请求发起 */
function eventSource(params) {
    const { url, fullTextChangeCallback, apiEndCallback, payload, singleTokenCallback } = params;
    const auth = loginInstance.getHeaders();
    const authHeaders = auth ? { Authorization: auth } : {};
    const h = payload ? { 'Content-Type': 'application/json' } : {};
    const source = new SSE(url, {
        headers: {
            ...authHeaders,
            ...h,
        },
        payload,
    });
    const text = [];
    let delay = 0;
    source.onopen = () => {
        console.log('连接成功');
    };
    source.onmessage = async function (event) {
        const data = JSON.parse(event.data);
        if (!data || !data.chatToken) {
            return;
        }
        let { chatToken: token, index } = data;
        if (token === '[DONE]') {
            await sleep(10 * delay);
            apiEndCallback();
            source.close();
            return;
        }
        if (index === undefined) {
            return;
        }
        token = token.replace(/%/g, '%25');
        token = decodeURIComponent(token);
        if (text[index]) {
            text[index] += token;
        } else {
            text[index] = token;
        }

        if (text[index] && /台湾$/.test(text[index])) {
            text[index].replace(/台湾$/, '中国(台湾)');
        }
        fullTextChangeCallback(text);
        delay = delay + 1;
        await sleep(10 * delay);
        singleTokenCallback && singleTokenCallback(token, index, /台湾$/.test(text[index]));
    };
    source.onerror = (e) => {
        console.error('连接失败', e);

        apiEndCallback();

        source.close();
        // show error
        try {
            const errorData = JSON.parse(e.data);
            message.error(errorData.msg);
        } catch (error) {}
    };
    source.stream();
    delay = 0;
}

/** 通过eventStream的方式发起流式请求 */
export function useEventSource() {
    const text = useRef([]);
    const [ts, setTS] = useState(0);
    const [status, setStatus] = useState('init');

    const startFetch = useCallback(async (url, payload, singleWordCb, finishCb) => {
        text.current = [];
        setStatus('loading');
        eventSource({
            url,
            fullTextChangeCallback: (t) => {
                text.current = [...t];
                setTS(Date.now());
            },
            apiEndCallback: () => {
                setStatus('done');
                finishCb && finishCb();
            },
            payload,
            singleTokenCallback: singleWordCb,
        });
    }, []);

    return {
        startFetch,
        text: text.current,
        status,
    };
}

export function commonEventSource(url, cb, cb2, payload, singleWordCb) {
    const auth = loginInstance.getHeaders();
    const authHeaders = auth ? { Authorization: auth } : {};
    const h = payload ? { 'Content-Type': 'application/json' } : {};
    const source = new SSE(url, {
        headers: {
            ...authHeaders,
            ...h,
        },
        payload,
    });
    const text = [];
    source.onopen = () => {
        console.log('连接成功');
    };
    source.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (!data || !data.chatToken) {
            return;
        }
        let { chatToken: token, index } = data;
        if (token === '[DONE]') {
            cb2 && cb2();
            source.close();
            return;
        }
        if (index === undefined) {
            return;
        }
        token = token.replace(/%/g, '%25');
        token = decodeURIComponent(token);
        if (text[index]) {
            text[index] += token;
        } else {
            text[index] = token;
        }

        singleWordCb && singleWordCb(token, index);
        cb && cb(text);
    };
    source.onerror = (e) => {
        console.error('连接失败', e);

        source.close();
        // show error
        try {
            const errorData = JSON.parse(e.data);
            message.error(errorData.msg);
            cb2({ status: 'error', msg: errorData.msg });
        } catch (error) {}
    };
    source.stream();

    return source;
}

export function searchResultEventSource(url, cb, onFinish, payload, singleWordCb) {
    const auth = loginInstance.getHeaders();
    const authHeaders = auth ? { Authorization: auth } : {};
    const h = payload ? { 'Content-Type': 'application/json' } : {};
    const source = new SSE(url, {
        headers: {
            ...authHeaders,
            ...h,
        },
        payload,
    });
    const text = [];
    source.onopen = () => {
        // console.log("连接成功");
    };
    source.onmessage = function (event) {
        let data;
        try {
            data = JSON.parse(event.data);
        } catch (error) {
            console.log('解析失败', error);
        }
        if (!data || !data.chatToken) {
            return;
        }
        let { chatToken: token, index } = data;
        if (token === '[DONE]') {
            onFinish && onFinish();
            source.close();
            return;
        }
        if (token === '```') {
            return;
        }
        token = token.replace(/%/g, '%25');
        token = decodeURIComponent(token);
        if (text[index]) {
            text[index] += token;
        } else {
            text[index] = token;
        }

        singleWordCb && singleWordCb(token, index);
        cb && cb(text);
    };
    source.onerror = (e) => {
        console.error('连接失败', e);

        source.close();
        onFinish && onFinish();
        // show error
        try {
            const errorData = JSON.parse(e.data);
            message.error(errorData.msg);
            onFinish({ status: 'error', msg: errorData.msg });
        } catch (error) {}
    };
    source.stream();

    return source;
}

export function usePreviewSource() {
    const text = useRef([]);
    const [ts, setTS] = useState(0);
    const [status, setStatus] = useState('init');

    const startFetch = useCallback(async (url, payload, singleWordCb, finishCb) => {
        text.current = [];
        setStatus('loading');
        eventSource(
            url,
            (t) => {
                text.current = [...t];
                setTS(Date.now());
            },
            () => {
                setStatus('done');
                finishCb && finishCb();
            },
            payload,
            singleWordCb,
        );
    }, []);

    return {
        startFetch,
        text: text.current,
        status,
    };
}
