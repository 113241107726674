import { message } from "antd";
import { get } from "../axios/axios";

export function currencyHandle(value, currency, decimals) {
  if (!isFinite(value) || (!value && value !== 0)) {
    return "-";
  }
  value = parseFloat(value);
  //
  const stringified = String(value);
  const idx = stringified.indexOf(".");
  let _int = "";
  let _float = "";
  let _floatStr = "";
  //
  if (idx !== -1) {
    _int = stringified.substring(0, idx);
    _floatStr = stringified.substring(idx);
  } else {
    _int = stringified;
    _floatStr = ".";
  }
  if (decimals) {
    const _floatLen = decimals + 1;
    // while (_floatStr.length < _floatLen) {
    //   _floatStr += '0'
    // }
    if (_floatStr.length < _floatLen) {
      _floatStr = _floatStr.padEnd(_floatLen, "0");
    }
    _float = _floatStr.substring(0, _floatLen);
  }
  //
  const i = _int.length % 3;
  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "," : "") : "";
  const sign = value < 0 ? "-" : "";
  return (
    (currency ? " " + currency : "") +
    sign +
    head +
    _int.slice(i).replace(/(\d{3})(?=\d)/g, "$1,") +
    _float
  );
}

export const queryPaper = async (orderNo) => {
  const { data, msg } = await get(`/ai/paper/status/${orderNo}`);
  if (data) {
    return true;
  }

  if (data === null) {
    message.error(msg || "查询订单失败，请联系客服");
  }

  if (data === false) {
    message.info("正在加速生成中，预计需要30分钟");
  }

  return false;
};

export async function handleDownLoad(url, title = '') {
  // const auth = loginInstance.getHeaders();
  // const authHeaders = auth ? { Authorization: auth } : {};
  // console.log('xmy', authHeaders);
  await fetch(url, {
      method: 'GET',
      headers: new Headers({
          //自己加的头信息全都要转成string
          // ...authHeaders,
      }),
  })
      .then((res) => {
          console.log('xxx1', res);
          return res.blob();
      })
      .then((data) => {
          console.log('xxx res', data);
          const blobUrl = window.URL.createObjectURL(data);
          download(blobUrl, title);
      });
  // get(url, {}, { responseType: "blob" })
  //   .then((res) => {
  //     const link = document.createElement("a");
  //     let blob = new Blob([res.data]);
  //     //获取heads中的filename文件名
  //     // var aa = res.headers["content-disposition"];
  //     // let temp = res.headers["content-disposition"]
  //     //   .split(";")[1]
  //     //   .split("filename=")[1];
  //     // var fileName = decodeURIComponent(temp);
  //     // console.log(fileName);
  //     link.style.display = "none";
  //     link.href = URL.createObjectURL(blob);
  //     // link.setAttribute("download", fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
}
export function download(blobUrl, title) {
  const a = document.createElement('a');
  a.href = blobUrl;
  a.target = '_blank';
  a.setAttribute('target', '_blank');
  a.setAttribute('download', title + '.pptx');
  a.click();
  message.success('下载成功，请在浏览器下载文件中查看');
}

export const globalData = { timerId: 0 };