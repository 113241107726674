import React from 'react';

const TextContainer = ({ title, type, content, children, dangerouslySetInnerHTML }) => {
    return (
        <div
            className={`w-full rounded-md p-3`}
            style={{
                background: type === 'active' ? '#ffffff' : '#F8FBFF',
                border: `1px solid ${type === 'active' ? '#9557FF' : '#EEEEF1'}`,
            }}
        >
            <div className="title text-base font-medium" style={{ color: '#192038' }}>
                {title}
            </div>
            {content && (
                <div
                    className="content mt-4 max-h-[110px] overflow-y-auto text-sm"
                    style={{ lineHeight: '22px' }}
                >
                    {content}
                </div>
            )}
            {/* dangerouslySetInnerHTML时展示 */}
            {dangerouslySetInnerHTML && (
                <div
                    className="content mt-4 max-h-[110px] overflow-y-auto text-sm"
                    style={{ lineHeight: '22px' }}
                    dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
                />
            )}
            {children}
        </div>
    );
};

export default TextContainer;
