import { InfoCircleOutlined } from '@ant-design/icons';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Badge, Button, Empty, Input, List, Modal, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import Img1 from '../../../assets/wenxian-1.jpg';
import Img2 from '../../../assets/wenxian-2.jpg';
import { useDevice } from '../../../hooks/useDevice';
import LiteratureItem from '../LiteratureSearchModal/LiteratureItem';
import SelectedLiteratureItem from '../LiteratureSearchModal/SelectedLiteratureItem';
import { parseReferences } from './utils';

const { TextArea } = Input;

const CustomPasteModal = NiceModal.create(() => {
    const modal = useModal();
    const [content, setContent] = useState('');
    const [parsedReferences, setParsedReferences] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showSelected, setShowSelected] = useState(false);
    const [hasPasted, setHasPasted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (modal.visible) {
            setSelectedItems([]);
            setShowSelected(false);
            setHasPasted(false);
        }
    }, [modal.visible]);

    useEffect(() => {
        if (content && !hasPasted) {
            setLoading(true);
            setTimeout(() => {
                const parsed = parseReferences(content);
                console.log('parsed', parsed);
                setParsedReferences(parsed);
                setSelectedItems(parsed);
                setHasPasted(true);
                setLoading(false);
            }, 300);
        }
    }, [content]);

    const handlePaste = () => {
        const parsed = parseReferences(content);
        console.log('parsed', parsed);
        setParsedReferences(parsed);
        setHasPasted(true);
        // 将识别出来的文献全部选中
        setSelectedItems(parsed);
    };

    const handleReset = () => {
        setContent('');
        setParsedReferences([]);
        setSelectedItems([]);
        setShowSelected(false);
        setHasPasted(false);
    };

    const handleSelect = (item) => {
        if (!selectedItems.find((selected) => selected.id === item.id)) {
            setSelectedItems([...selectedItems, item]);
        }
        if (selectedItems.find((selected) => selected.id === item.id)) {
            setSelectedItems(selectedItems.filter((selected) => selected.id !== item.id));
        }
    };

    const handleRemove = (item) => {
        setSelectedItems(selectedItems.filter((selected) => selected.id !== item.id));
    };

    const handleOk = () => {
        modal.resolve(selectedItems);
        modal.hide();
    };

    const showBackgroundImage = !showSelected && !selectedItems.length;

    const { isMobile } = useDevice();
    return (
        <Modal
            title="文献库"
            open={modal.visible}
            onOk={handleOk}
            onCancel={modal.hide}
            width={800}
            maskClosable
            footer={[
                showSelected
                    ? [
                          <Button key={'useDoc'} type={'default'} onClick={handleOk}>
                              使用文献
                          </Button>,
                          <Button
                              key="continueAdd"
                              type="primary"
                              onClick={() => setShowSelected(!showSelected)}
                          >
                              继续添加
                          </Button>,
                      ]
                    : [
                          <Button key="cancel" onClick={modal.hide}>
                              取消
                          </Button>,
                          <Button key="ok" type="primary" onClick={handleOk}>
                              使用
                          </Button>,
                      ],
            ]}
        >
            {!showSelected && (
                <>
                    <div className={'mb-4 flex items-center'}>
                        <span className={'text-[#8C93B1]'}></span>
                        <span className={'text-[#192038]'}>自定义输入文献时，请使用</span>
                        <span className={'mr-2'}>查新引文格式</span>
                        <Popover
                            placement="rightTop"
                            trigger={isMobile ? 'click' : 'hover'}
                            content={
                                <div className={'w-[500px] max-w-[90vw]'}>
                                    <img className={'mx-auto my-1 w-full'} src={Img1} />
                                    <img className={'mx-auto my-1 w-full'} src={Img2} />
                                </div>
                            }
                            title="文献获取指引"
                        >
                            <InfoCircleOutlined
                                style={{
                                    color: 'var(--colorPrimary)',
                                }}
                            />
                        </Popover>
                    </div>
                    <div className={'mb-5'}>
                        <TextArea
                            rows={4}
                            value={content}
                            showCount
                            maxLength={10000}
                            onChange={(e) => setContent(e.target.value)}
                            placeholder="1、请在此处粘贴文献的“查新（引文格式）”，否则无法识别
2、限10000字以内，超额文字会被自动裁减；
3、文献排序影响A投喂效果，重要的文献靠前排列"
                        />
                    </div>

                    <div className={'flex justify-end'}>
                        <Button type="primary" onClick={handlePaste}>
                            粘贴并识别
                        </Button>
                    </div>

                    <List
                        style={{
                            height: '600px',
                            overflow: 'auto',
                            scrollbarWidth: 'thin',
                        }}
                        loading={loading}
                        dataSource={parsedReferences}
                        renderItem={(item) => (
                            <LiteratureItem
                                item={item}
                                onSelect={handleSelect}
                                selected={selectedItems.some((selected) => selected.id === item.id)}
                            />
                        )}
                        locale={{
                            emptyText: null,
                        }}
                    />
                    <div
                        className={
                            'absolute bottom-4 left-6 flex h-10 w-20 cursor-pointer items-center justify-center rounded-md bg-[#F8FBFF]'
                        }
                        onClick={() => setShowSelected(!showSelected)}
                    >
                        <Badge count={selectedItems?.length} showZero offset={[16, -2]}>
                            <div className={'text-[#8C93B1]'}>查看文献</div>
                        </Badge>
                    </div>
                </>
            )}
            {showSelected && (
                <List
                    style={{
                        height: '600px',
                        overflow: 'auto',
                        scrollbarWidth: 'thin',
                    }}
                    header={<div>已选文献({selectedItems.length})篇</div>}
                    dataSource={selectedItems}
                    renderItem={(item) => (
                        <SelectedLiteratureItem item={item} onClose={() => handleRemove(item)} />
                    )}
                    locale={{
                        emptyText: <Empty description="暂未选择文献" />,
                    }}
                />
            )}
        </Modal>
    );
});

export default CustomPasteModal;
