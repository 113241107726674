import styles from './index.module.scss';
import { useDevice } from '../../hooks/useDevice';
import { InfoCircleFilled } from '@ant-design/icons';

// 公司介绍
const CompanyIntro = () => {
    const { isMobile } = useDevice();

    const innerContent = (
        <div className={'w-full overflow-y-scroll'}>
            <div className={styles.leftPopoverTitle}>公司简介</div>
            <div className={styles.leftPopoverContent}>
                合肥墨问题科技有限公司专注于人工智能技术的研发和应用，我们的团队由来自国内外知名高校和科研机构的优秀人才组成，拥有丰富的人工智能算法和应用开发经验，且有着国内外一流公司的工作经验。
                我们为各行业客户提供全方位的人工智能解决方案，包括智能客服、智能语音、智能图像、自然语言处理，智能写作等。墨问题科技的愿景是成为人工智能时代的推动者，将最先进的技术转化为最有价值的产品和服务，助力人类更加高效、智能、便捷地生活和工作。
            </div>
            <div className={styles.leftPopoverContent}>
                我们为各行业客户提供全方位的人工智能解决方案，包括智能客服、智能语音、智能图像、自然语言处理等。除此之外，公司也在积极开展区块链领域的业务，致力于将区块链技术与实际应用相结合，为客户提供更加安全、去中心化、高效的解决方案，其中包括供应链金融，IP版权等应用场景。墨琢科技的愿景是成为人工智能时代的推动者，将最先进的技术转化为最有价值的产品和服务，助力人类更加高效、智能、便捷地生活和工作。
            </div>
            <div className={styles.leftPopoverContent}>
                灵感风暴，作为一款专为写作领域量身打造的智能辅助工具，致力于为广大创作者提供高效、便捷、可靠的创作支持。平台集成了全文写作、智能降重等核心功能，旨在帮助用户突破创作瓶颈，激发创作灵感，提升创作效率。
            </div>
            <div className={styles.leftPopoverTitle}>联系方式</div>
            <div className={styles.leftPopoverContent}>
                联系地址：安徽省合肥市蜀山区西园街道黄山路468号通和佳苑（易居时代公寓）1幢、2幢1＃905室
            </div>
            <div className={styles.leftPopoverContent}>联系电话：18391174426</div>

            <div className={styles.leftPopoverTitle}>联系客服</div>
            <div
                style={{
                    ...(isMobile
                        ? {
                              display: 'flex',
                              justifyContent: 'center',
                          }
                        : {}),
                }}
            >
                <img
                    width="140"
                    height="140"
                    src="https://multure.oss-cn-hangzhou.aliyuncs.com/kf.png"
                />
            </div>
        </div>
    );

    return (
        <div className={'mx-auto px-3 pt-20 lg:w-[62.5vw] lg:px-0'}>
            <div className={'mb-2'}>
                <InfoCircleFilled
                    style={{
                        color: '#9557FF',
                        fontSize: '18px',
                        marginRight: '6px',
                    }}
                />
                <span className={'text-2xl font-semibold'}>公司介绍</span>
            </div>
            {innerContent}
        </div>
    );
};

export default CompanyIntro;
