import { Collapse, Modal, Input, Radio, Alert, message, Select, Tag } from 'antd';
import cx from 'classnames';
import styles from './index.module.scss';
import { unlockStore } from './unlockStore';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Button } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';

const UnlockAllChapter = observer(() => {
    const SummaryHeader = useCallback(({ it, idx }) => {
        return (
            <div className={styles.summaryHeader}>
                <div>
                    {it.isUnlock ? (
                        <div className="rounded-sm bg-[#F8FBFF] px-1.5 py-[2px] text-sm text-primary">
                            已解锁
                        </div>
                    ) : (
                        <div className="rounded-sm bg-[#F8FBFF] px-1.5 py-[2px] text-sm text-[#8C93B1]">
                            未解锁
                        </div>
                    )}
                    <div>{it.chapter}</div>
                </div>
            </div>
        );
    }, []);

    const SectionHeader = useCallback(({ s, idx, sidx, isLastLevel }) => {
        return (
            <div className={styles.cSectionHeader}>
                <div className={styles.cSectionTitle}>
                    <div className={styles.title}>
                        <div>{s.chapter}</div>
                    </div>
                    <div className={styles.desc}>{s.chapterAbstract}</div>
                </div>
            </div>
        );
    }, []);

    return (
        <div className={cx([styles.summaryWrapper, 'drawer'])} id="summaryEl">
            <div className={styles.summaryBox}>
                <div className={styles.docTitle}>题目：{unlockStore.title}</div>
                <Collapse ghost defaultActiveKey={unlockStore.chapters.map((_, idx) => idx)}>
                    {unlockStore.chapters.map((it, idx) => {
                        return (
                            <Collapse.Panel key={idx} header={<SummaryHeader it={it} idx={idx} />}>
                                {it.sections?.map((s, sidx) => {
                                    const haveThreeOutline =
                                        s.subsections && s.subsections.length > 0;
                                    return haveThreeOutline ? (
                                        <Collapse
                                            ghost
                                            key={sidx}
                                            className={styles.collaspseSection}
                                            defaultActiveKey={[sidx]}
                                        >
                                            <Collapse.Panel
                                                key={sidx}
                                                header={
                                                    <SectionHeader s={s} idx={idx} sidx={sidx} />
                                                }
                                            >
                                                {s.subsections.map((sub, subIdx) => (
                                                    <div className={styles.subSection} key={subIdx}>
                                                        <div>
                                                            <div className={styles.subSectionTitle}>
                                                                {/* {idx + 1}.{sidx + 1}.{subIdx + 1} */}
                                                                {sub.chapter}
                                                            </div>
                                                            <div className={styles.subSectionAbs}>
                                                                {sub.chapterAbstract}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Collapse.Panel>
                                        </Collapse>
                                    ) : (
                                        <div
                                            className={classNames(
                                                styles.collaspseSection,
                                                styles.collaspseSectionSingle,
                                            )}
                                            key={sidx}
                                        >
                                            <SectionHeader
                                                isLastLevel={true}
                                                s={s}
                                                idx={idx}
                                                sidx={sidx}
                                            />
                                        </div>
                                    );
                                })}
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
                <div className="flex justify-center">
                    <Button shape="round" type="primary" size="large" icon={<UnlockOutlined />}>
                        解锁全文
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default UnlockAllChapter;
