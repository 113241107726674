import { updatePieItemWithColor } from "./helper";
import { max, min } from "lodash";

export const AttentionEchartsOption1 = (
  prefix,
  years,
  csCount,
  fsCount,
  csRatio,
  fsRatio
) => {
  const maxY = Math.max(max(csCount), max(fsCount));
  const minRY = Math.min(min(csRatio), min(fsRatio));
  const maxRY = Math.max(max(csRatio), max(fsRatio));
  return {
    title: {
      text: "学术关注度",
      top: 13,
      left: 12,
      textStyle: {
        fontSize: 14,
      },
    },
    graphic: [
      {
        type: "text", // 插入文本元素
        top: 15, // 距离容器顶部的距离
        right: 12, // 距离容器右侧的距离
        style: {
          text: `${years[0]}年至${years[years.length - 1]}年`, // 插入的时间
          font: "14px",
          textAlign: "right", // 文本右对齐
          fill: "#666666",
        },
      },
    ],
    grid: {
      top: 110,
      left: 60,
      right: 60,
      bottom: 52,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      top: 60,
      left: 40,
      right: 40,
      type: "scroll",
      data: [
        prefix + "中文相关文献量",
        prefix + "外文相关文献量",
        prefix + "中文环比增长率",
        prefix + "外文环比增长率",
      ],
      pageButtonGap: 10,
    },
    xAxis: {
      type: "category",
      boundaryGap: true,
      data: years,
    },
    yAxis: [
      {
        type: "value",
        min: 0,
        max: maxY || 100,
        position: "left",
      },
      {
        type: "value",
        min: minRY,
        max: maxRY,
        position: "right",
        scale: true,
        splitLine: {
          lineStyle: {
            color: "#F8F9FB", // 表示交替的颜色
            width: 1,
          },
        },
      },
    ],
    series: [
      {
        name: prefix + "中文相关文献量",
        type: "bar",
        data: csCount || [
          30, 50, 70, 90, 100, 80, 60, 40, 50, 60, 70, 85, 70, 55, 40, 25, 30,
          40, 50, 60, 70, 80, 90,
        ],
        barWidth: "30%", // 设置柱子的宽度
        barGap: "10%",
        itemStyle: {
          color: "#F7941D", // 橙色
        },
      },
      {
        name: prefix + "外文相关文献量",
        type: "bar",
        data: fsCount || [
          20, 40, 60, 80, 90, 70, 50, 30, 40, 50, 60, 65, 50, 35, 20, 25, 30,
          45, 55, 65, 75, 85, 90,
        ],
        barWidth: "30%", // 设置柱子的宽度
        barGap: "10%",
        itemStyle: {
          color: "#F7E11F", // 黄色
        },
      },
      {
        name: prefix + "中文环比增长率",
        type: "line",
        yAxisIndex: 1,
        data: csRatio || [
          0.3, 0.4, 0.35, 0.45, 0.5, 0.45, 0.55, 0.4, 0.45, 0.5, 0.55, 0.6,
          0.55, 0.5, 0.45, 0.4, 0.45, 0.5, 0.55,
        ],
        itemStyle: {
          color: "#F7941D", // 橙色
        },
        symbol: "none",
      },
      {
        name: prefix + "外文环比增长率",
        type: "line",
        yAxisIndex: 1,
        data: fsRatio || [
          0.7, 0.6, 0.65, 0.55, 0.5, 0.55, 0.45, 0.6, 0.55, 0.5, 0.45, 0.4,
          0.45, 0.5, 0.55, 0.6, 0.55, 0.5,
        ],
        itemStyle: {
          color: "#F7E11F", // 黄色
        },
        symbol: "none",
      },
    ],
  };
};

export const SubjectWordsEchartsOption2 = (data) => {
  return {
    title: {
      text: "学科分析",
      top: 13,
      left: 12,
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "item",
    },
    grid: {
      top: 400,
      left: 0,
      right: 0,
      bottom: 0,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: "学科分析",
        type: "pie",
        radius: "65%",
        center: ["50%", "60%"],
        roseType: "radius",
        data: updatePieItemWithColor(data),
        label: {
          formatter: "{b} {d}%",
        },
        emphasis: {
          // disabled: true,
          scale: true,
          scaleSize: 10,
          itemStyle: {
            color: "inherit",
          },
        },
      },
    ],
  };
};

export const RelevantEchartsOption3 = (words, counts) => {
  return {
    title: {
      text: "相关词统计",
      top: 13,
      left: 12,
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      top: 58,
      left: 72,
      right: 12,
      bottom: 16,
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      type: "category",
      data: words,
      axisTick: false,
      axisLine: {
        lineStyle: {
          color: "#E5E5E5",
        },
      },
      axisLabel: {
        color: "#999",
        formatter: function (value) {
          return value.length > 5 ? value.substring(0, 4) + "..." : value;
        },
      },
    },
    series: [
      {
        name: "数量",
        type: "bar",
        data: counts, // 这里数据顺序与 Y 轴类别对应
        itemStyle: {
          color: "#F7941D", // 橙色
          borderRadius: [0, 16, 16, 0],
        },
        label: {
          show: true, // 是否显示标签
          position: "insideRight", // 标签的位置
          formatter: "{c}", // 标签内容格式器，{c}表示数据值
          padding: [0, 6, 0, 0],
          color: "#fff", // 标签字体颜色
          fontSize: 14, // 标签字体大小
        },
      },
    ],
  };
};

export const mockList = [
  {
    情感分析: "正面",
    事件类型: "技术创新",
    时间: "2015年09月15日",
    概括: "设计了一套基于ZigBee技术的学生宿舍用电管理系统，通过电能计量模块和ZigBee芯片CC2430实现通信和数据传输。",
    来源: "[[2]]",
    事件名称: "基于ZigBee技术的学生宿舍用电管理系统设计",
  },
  {
    情感分析: "正面",
    事件类型: "产品开发",
    时间: "2018年08月12日",
    概括: "开发了面向智慧校园行业的智能宿舍管理终端产品，利用传感器技术和Wi Fi无线传输技术进行宿舍管理。",
    来源: "[[4]]",
    事件名称: "智慧校园框架下的智能宿舍管理产品设计与实现",
  },
  {
    情感分析: "正面",
    事件类型: "技术创新",
    时间: "2020年01月25日",
    概括: "设计了一套基于ZigBee技术的智能化学生宿舍查寝管理系统，实现了集中化管理与智能化分析。",
    来源: "[[5]]",
    事件名称: "基于ZigBee技术的智能化学生宿舍查寝管理系统设计",
  },
  {
    情感分析: "正面",
    事件类型: "系统开发",
    时间: "2015年03月20日",
    概括: "采用B/S模式对高职院校学生宿舍管理系统进行设计开发，解决了宿舍数据共享问题。",
    来源: "[[6]]",
    事件名称: "高职学院学生宿舍管理系统设计与实现",
  },
  {
    情感分析: "正面",
    事件类型: "技术创新",
    时间: "2019年06月14日",
    概括: "研究并完成了基于J2EE框架的高校宿舍智能管理系统的设计，提高了宿舍管理效率。",
    来源: "[[9]]",
    事件名称: "高校宿舍智能管理系统设计",
  },
];
