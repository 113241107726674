import React, { useEffect, useCallback ,useState} from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import CustomDrawer from './CustomDrawer';
import TextContainer from './TextContainer';
import { Modal, Spin } from 'antd';
import { post } from '../../../../axios/axios';

// 降Ai率
const AiModal = NiceModal.create(({ orderNo, content, from, to, chapterId }) => {
    const modal = useModal();
    const handleOk = () => {
        modal.resolve(text);
        modal.hide();
    };
    const handleReset = () => {
        fetchData();
    };


    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchData = useCallback(async () => {
        if (!orderNo || !chapterId || !content) return;
        try {
            setLoading(true);
            const res = await post('/ai/ai/paper/editor/reduceAiRate', {
                orderNo,
                chapterId,
                original: content,
            });
            console.log('res', res);
            setText(res.data);
        } catch (error) {
            console.error('Error fetching content reduction:', error);
        } finally {
            setLoading(false);
        }
    }, [orderNo, chapterId, content]); // 这些依赖是必要的
    
    useEffect(() => {
        if (!modal.visible) return;
        fetchData();
    }, [modal.visible, fetchData]); 


    return (
        <CustomDrawer
            title="降AI率"
            visible={modal.visible}
            onClose={() => {
                modal.reject();
                modal.hide();
            }}
            okText="替换原文"
            resetText="重新生成"
            onOk={handleOk}
            onReset={handleReset}
        >
            <TextContainer type="default" title="原文" content={content} />
            <span className='text-xs text-[#8C93B1]'>
            此处只能部分降AIGC，无法参与超25%退费服务。若需全文降 AIGC，需购买降AIGC服务。
            </span>
            {loading ? <div className="flex justify-center items-center h-32"><Spin /></div> : (
                <div className="mt-4">
                    <TextContainer type="active" title="降AI率结果" content={text} />
                </div>
            )}
        </CustomDrawer>
    );
});

export default AiModal;
