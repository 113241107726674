import styles from './index.module.scss';
import { Summary } from './Summary';
import { searchResultStore } from '../SearchResult/store';
import { Progress } from './Summary/Progress';
import shrink from './assets/shrink.svg';
import { Button, Checkbox, Input, Modal, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CheckOutlined, SyncOutlined } from '@ant-design/icons';
import { post } from '../../axios/axios';
import { observer } from 'mobx-react';
import { getQuery } from '../../common/query';
import { createPortal } from 'react-dom';
import { NavRight } from '../../components/NavRight';
import { Preview } from './Preview/result';
import { DOCTYPEENUM } from '../Documents/const';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDevice } from '../../hooks/useDevice';

const SearchPaper = observer(() => {
    const nav = useNavigate();

    const [qs] = useSearchParams();
    const [titleList, setTitleList] = useState([]);
    const [focusIdx, setFocusIdx] = useState(0);
    const [selectIdx, setSelectIdx] = useState(0);
    const [titleModalVisible, setTitleModalVisible] = useState(false);
    const [titleLoading, setTitleLoading] = useState(false);
    const [hasChecked, setHasChecked] = useState(false);

    const showTitleOpt = async () => {
        console.log('xx', searchResultStore);
        setTitleLoading(true);

        setTitleModalVisible(true);
        const res = await post('/ai/ai/s/paper/title/optimization', {
            uid: searchResultStore.query.id,
            title: searchResultStore.query.query,
        });
        setTitleList(res.data || []);
        setTitleLoading(false);
    };
    const onTitleChange = (idx, txt) => {
        setSelectIdx(idx);
        titleList[idx] = txt;
        setTitleList([...titleList]);
        if (!txt) {
            setSelectIdx(-1);
        }
    };
    const hideTitleModal = () => {
        setTitleModalVisible(false);
    };

    const onPaperConfirm = () => {
        searchResultStore.title = titleList[selectIdx];
        Modal.confirm({
            title: '是否需要重新生成大纲',
            onOk: () => {
                searchResultStore.getOutline();
                hideTitleModal();
            },
            onCancel: () => {
                hideTitleModal();
            },
        });
    };

    const goToPreview = () => {
        if (!hasChecked) {
            message.warning('请勾选并同意协议');
            return;
        }
        searchResultStore.genPreviewPaper();
    };

    const goToTemplate = () => {
        // if (!hasChecked) {
        //     message.warning('请勾选并同意协议');
        //     return;
        // }
        console.log('searchResultStore.pptQuery', searchResultStore.pptQuery);

        nav(
            `/search/ppt?query=${searchResultStore.pptQuery.query}&docType=${searchResultStore.pptQuery.docType}&pptTitle=${searchResultStore.pptTitle}&pptSubTitle=${searchResultStore.pptSubTitle}`,
        );
    };
    useEffect(() => {
        if (Number(qs.get('docType')) === DOCTYPEENUM.PPT) {
            searchResultStore.initByPPT({
                uid: qs.get('uid'), // 文件ID
                query: qs.get('query'), // 文案
                prompt: qs.get('prompt'), // 提示词
                docType: qs.get('docType'), // 文档类型
            });

            return;
        }
        const query = getQuery();

        if (!searchResultStore.query.id) {
            searchResultStore.initByOutlineQuery(query);
        } else {
            searchResultStore.query.docType = query.docType;
        }
    }, []);

    const { isMobile } = useDevice();

    if (isMobile) {
        return (
            <div className={'bg-[#F8F9FB] p-3'}>
                <div className={styles.searchPaper}>
                    {document.getElementById('navTitle') &&
                        createPortal(<span>搜索结果</span>, document.getElementById('navTitle'))}
                    {document.getElementById('navRight')
                        ? createPortal(<NavRight />, document.getElementById('navRight'))
                        : null}
                    <div className={styles.boxCard}>
                        <div className={styles.paperTitleWrapper}>
                            <div className={styles.paperTitle}>{searchResultStore.title}</div>
                            {/*{!searchResultStore.showPreview && (*/}
                            {/*    <div*/}
                            {/*        type="primary"*/}
                            {/*        className={styles.paperOptimize}*/}
                            {/*        onClick={showTitleOpt}*/}
                            {/*    >*/}
                            {/*        <img src={shrink} />*/}
                            {/*        <span className={styles.optDesc}>标题优化</span>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                        {searchResultStore.showPreview ? (
                            <Preview />
                        ) : (
                            <>
                                {searchResultStore.outlineLoading ? (
                                    <Progress
                                        loading={searchResultStore.outlineLoading}
                                        end={
                                            searchResultStore.outlines &&
                                            searchResultStore.outlines.length
                                        }
                                        desc="正在帮您生成大纲，预计需要等待60s左右，请耐心等待~"
                                        list={['启动中...', '分析题目', '检索文献', '生成大纲']}
                                    />
                                ) : null}
                                {searchResultStore.outlines && searchResultStore.outlines.length ? (
                                    <>
                                        <div className={'mb-4 text-center text-2xl font-bold'}>
                                            {searchResultStore.pptTitle || '主标题'}
                                        </div>
                                        <div className={'mb-4 text-center text-lg'}>
                                            {searchResultStore.pptSubTitle || '副标题'}
                                        </div>
                                        <Summary />
                                        <div className={styles.summaryFooter}>
                                            <div>
                                                {/* <Button
                                                    onClick={searchResultStore.getOutline}
                                                    className={styles.genBtn}
                                                >
                                                    重新生成
                                                </Button> */}
                                                {+searchResultStore.pptQuery.docType ===
                                                DOCTYPEENUM.PPT ? (
                                                    <Button
                                                        className={styles.genBtn}
                                                        type="primary"
                                                        round
                                                        style={{ marginLeft: '8px' }}
                                                        onClick={goToTemplate}
                                                    >
                                                        选择模板
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className={styles.genBtn}
                                                        type="primary"
                                                        round
                                                        loading={
                                                            searchResultStore.showPreviewLoading
                                                        }
                                                        onClick={goToPreview}
                                                        style={{ marginLeft: '8px' }}
                                                    >
                                                        预览全篇
                                                    </Button>
                                                )}
                                            </div>
                                            <div>
                                                <div
                                                    className={styles.xiaohao}
                                                    dangerouslySetInnerHTML={{
                                                        __html: searchResultStore.yujiXiaohao?.str,
                                                    }}
                                                ></div>
                                            </div>

                                            <div
                                                className={styles.regetOutline}
                                                onClick={searchResultStore.getOutline}
                                            >
                                                <SyncOutlined style={{ marginRight: '4px' }} />
                                                大纲不满意，重新生成
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </>
                        )}
                    </div>
                    {/* {!searchResultStore.showPreview && (
                        <div className={styles.agreements}>
                            <Checkbox
                                checked={hasChecked}
                                onClick={(e) => setHasChecked(e.target.value)}
                            >
                                我已阅读并同意：平台所生成的全文为范文，仅用作参考，不用作毕业论文、发表刊物等和
                                <a
                                    href="https://xiezuomowenti.com/help/f.html"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    《服务条款》
                                </a>
                            </Checkbox>
                        </div>
                    )} */}

                    <Modal
                        title="标题选择"
                        open={titleModalVisible}
                        onCancel={hideTitleModal}
                        onOk={onPaperConfirm}
                    >
                        <Spin spinning={titleLoading}>
                            <div className={styles.titleModals}>
                                {titleList.map((title, idx) => {
                                    const isSel = selectIdx === idx;
                                    return (
                                        <div
                                            key={idx}
                                            className={classNames(styles.item, {
                                                [styles.focus]: focusIdx === idx,
                                                [styles.select]: isSel,
                                            })}
                                        >
                                            <Input.TextArea
                                                bordered={false}
                                                value={title}
                                                onFocus={() => setFocusIdx(idx)}
                                                onBlur={() => setFocusIdx(-1)}
                                                onChange={(e) => onTitleChange(idx, e.target.value)}
                                                maxLength={200}
                                                autoSize={true}
                                            />
                                            {isSel ? (
                                                <CheckOutlined className={styles.checked} />
                                            ) : (
                                                <Button
                                                    disabled={!title}
                                                    onClick={() => setSelectIdx(idx)}
                                                >
                                                    <CheckOutlined />
                                                    选择
                                                </Button>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </Spin>
                    </Modal>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.searchPaper}>
            {document.getElementById('navTitle') &&
                createPortal(<span>搜索结果</span>, document.getElementById('navTitle'))}
            {document.getElementById('navRight')
                ? createPortal(<NavRight />, document.getElementById('navRight'))
                : null}
            <div className={styles.boxCard}>
                <div className={styles.paperTitleWrapper}>
                    <div className={styles.paperTitle}>{searchResultStore.title}</div>
                    {/* {!searchResultStore.showPreview && (
                        <div type="primary" className={styles.paperOptimize} onClick={showTitleOpt}>
                            <img src={shrink} />
                            <span className={styles.optDesc}>标题优化</span>
                        </div>
                    )} */}
                </div>
                {searchResultStore.showPreview ? (
                    <Preview />
                ) : (
                    <>
                        {searchResultStore.outlineLoading ? (
                            <Progress
                                loading={searchResultStore.outlineLoading}
                                end={
                                    searchResultStore.outlines && searchResultStore.outlines.length
                                }
                                desc="正在帮您生成大纲，预计需要等待60s左右，请耐心等待~"
                                list={['启动中...', '分析题目', '检索文献', '生成大纲']}
                            />
                        ) : null}
                        {searchResultStore.outlines && searchResultStore.outlines.length ? (
                            <>
                                <div className={'mb-4 text-center text-2xl font-bold'}>
                                    {searchResultStore.pptTitle || '主标题'}
                                </div>
                                <div className={'mb-4 text-center text-lg'}>
                                    {searchResultStore.pptSubTitle || '副标题'}
                                </div>
                                <Summary />
                                <div className={styles.summaryFooter}>
                                    <div>
                                        {/* <Button
                                            onClick={searchResultStore.getOutline}
                                            className={styles.genBtn}
                                        >
                                            重新生成
                                        </Button> */}
                                        {+searchResultStore.pptQuery.docType === DOCTYPEENUM.PPT ? (
                                            <Button
                                                className={styles.genBtn}
                                                type="primary"
                                                round
                                                style={{ marginLeft: '8px' }}
                                                onClick={goToTemplate}
                                            >
                                                选择模板
                                            </Button>
                                        ) : (
                                            <Button
                                                className={styles.genBtn}
                                                type="primary"
                                                round
                                                loading={searchResultStore.showPreviewLoading}
                                                onClick={goToPreview}
                                                style={{ marginLeft: '8px' }}
                                            >
                                                预览全篇
                                            </Button>
                                        )}
                                    </div>
                                    <div>
                                        <div
                                            className={styles.xiaohao}
                                            dangerouslySetInnerHTML={{
                                                __html: searchResultStore.yujiXiaohao?.str,
                                            }}
                                        ></div>
                                    </div>

                                    <div
                                        className={styles.regetOutline}
                                        onClick={searchResultStore.getOutline}
                                    >
                                        <SyncOutlined style={{ marginRight: '4px' }} />
                                        大纲不满意，重新生成
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </>
                )}
            </div>
            {/* {!searchResultStore.showPreview && (
                <div className={styles.agreements}>
                    <Checkbox checked={hasChecked} onClick={(e) => setHasChecked(e.target.value)}>
                        我已阅读并同意：平台所生成的全文为范文，仅用作参考，不用作毕业论文、发表刊物等和
                        <a
                            href="https://xiezuomowenti.com/help/f.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            《服务条款》
                        </a>
                    </Checkbox>
                </div>
            )} */}

            <Modal
                title="标题选择"
                open={titleModalVisible}
                onCancel={hideTitleModal}
                onOk={onPaperConfirm}
            >
                <Spin spinning={titleLoading}>
                    <div className={styles.titleModals}>
                        {titleList.map((title, idx) => {
                            const isSel = selectIdx === idx;
                            return (
                                <div
                                    key={idx}
                                    className={classNames(styles.item, {
                                        [styles.focus]: focusIdx === idx,
                                        [styles.select]: isSel,
                                    })}
                                >
                                    <Input.TextArea
                                        bordered={false}
                                        value={title}
                                        onFocus={() => setFocusIdx(idx)}
                                        onBlur={() => setFocusIdx(-1)}
                                        onChange={(e) => onTitleChange(idx, e.target.value)}
                                        maxLength={200}
                                        autoSize={true}
                                    />
                                    {isSel ? (
                                        <CheckOutlined className={styles.checked} />
                                    ) : (
                                        <Button disabled={!title} onClick={() => setSelectIdx(idx)}>
                                            <CheckOutlined />
                                            选择
                                        </Button>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </Spin>
            </Modal>
        </div>
    );
});
export default SearchPaper;
