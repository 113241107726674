import { useState, useCallback } from 'react';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const useStreamRequest = ({
    url,
    headers = {},
    onMessage,
    onError,
    onFinish,
    delay = 100,
} = {}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const streamRequest = useCallback(
        async (body) => {
            setLoading(true);
            setError(null);
            let contentBuffer = '';
            let controller;

            try {
                controller = new AbortController();

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        ...headers,
                    },
                    body: JSON.stringify(body),
                    signal: controller.signal,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }

                    const chunk = decoder.decode(value, { stream: true });
                    contentBuffer += chunk;

                    const lines = contentBuffer.split('\n');
                    contentBuffer = lines.pop() || '';

                    for (const line of lines) {
                        if (line.trim() && line.startsWith('data:')) {
                            try {
                                const jsonStr = line.slice(5).trim();
                                const data = JSON.parse(jsonStr);

                                if (!data || !data.chatToken) {
                                    continue;
                                }

                                const { chatToken: token, index } = data;

                                if (token === '[DONE]') {
                                    await sleep(delay * 10);
                                    onFinish?.();
                                    return;
                                }

                                onMessage?.({ chatToken: token, index });
                            } catch (e) {
                                console.error('Error parsing JSON:', e, line);
                            }
                        }
                    }
                }
            } catch (err) {
                setError(err);
                onError?.(err);
            } finally {
                setLoading(false);
            }

            return () => {
                if (controller) {
                    controller.abort();
                }
            };
        },
        [url, headers, onMessage, onError, onFinish, delay],
    );

    return {
        streamRequest,
        loading,
        error,
    };
};

export default useStreamRequest;
