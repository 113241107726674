const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

// 解析参考文献
export function parseReferences(text) {
    const references = [];
    // 移除可能的开头空白字符
    const cleanText = text.trim();

    // 简单地用 [数字] 作为分隔符来分割文本
    const entries = cleanText.split(/(?=\[\d+\])/);

    for (const entry of entries) {
        const trimmedEntry = entry.trim();
        // 跳过空条目或不以 [数字] 开头的条目
        if (!trimmedEntry || !/^\[\d+\]/.test(trimmedEntry)) continue;

        try {
            // 用摘要来分割获取引文信息和摘要部分
            const parts = trimmedEntry.split(/摘要[:：]/);
            if (parts.length < 2) continue;

            // 去掉引文信息前面的 [数字]
            const citationInfo = parts[0].trim().replace(/^\[\d+\]\s*/, '');
            // 获取剩余所有内容作为摘要（以防有多个"摘要"关键词）
            const anAbstract = parts.slice(1).join('摘要：').trim();

            references.push({
                id: generateUUID(),
                citationInfo,
                anAbstract,
                isCustom: true,
            });
        } catch (e) {
            console.error('解析引文时出错:', e.message);
            continue;
        }
    }

    return references;
}
