import { Modal, message, Spin, Button, Space, Row, Col, Card, Image } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import PPTpayModal from '../../../PPT/PPTpayModal';
import { useState, useEffect, useMemo } from 'react';
import { get } from '../../../../axios/axios';
import { UnlockOutlined, DownloadOutlined } from '@ant-design/icons';

import unlockZw from '../../../../assets/unlock-zw.png';
import unlockDiagramActive from '../../../../assets/icon-diagram-active.svg';
import unlockTableActive from '../../../../assets/icon-table-active.svg';
import unlockFormulaActive from '../../../../assets/icon-formula-active.svg';
import unlockCodeActive from '../../../../assets/icon-code-active.svg';

const CHAPTER_STATUS = {
    UNLOCKED: 0,
    LOCKED: 1,
};

const SelectUnlockChapter = NiceModal.create(({ orderNo }) => {
    const modal = useModal();
    const usePptPayModal = useModal(PPTpayModal);
    const [unlockedChapter, setUnlockedChapter] = useState(null);
    const [chapters, setChapters] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchChapterData = async () => {
        try {
            setLoading(true);
            const res = await get(`/ai/ai/paper/query/chapter?orderNo=${orderNo}`);
            setChapters(res.data);

            // 检查是否有已解锁的章节
            const unlockedChapterData = res.data.find(
                (chapter) => chapter.status === CHAPTER_STATUS.UNLOCKED,
            );
            if (unlockedChapterData) {
                setUnlockedChapter(unlockedChapterData.orderNo);
            }
        } catch (error) {
            message.error('获取章节数据失败');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchChapterData();
    }, [orderNo]);

    const handleUnlock = async (record) => {
        if (unlockedChapter && unlockedChapter !== record.orderNo) {
            message.warning('已解锁一个章节，无法解锁更多章节');
            return;
        }

        try {
            await usePptPayModal
                .show({
                    title: '解锁章节',
                    orderNo: record.orderNo,
                    majorName: record.chapter,
                    price: record.price,
                })
                .then(async () => {
                    await fetchChapterData();
                    setUnlockedChapter(record.orderNo);
                    message.success('章节解锁成功');
                    modal.resolve(record);
                });
        } catch (error) {
            console.log('Payment cancelled or failed:', error);
        }
    };

    const handleDownload = async (downloadUrl) => {
        const res = await get(`/ai//ai/paper/chapter/status?orderNo=${orderNo}`);
        if (!res.data) {
            message.warning('小灵正在马不停蹄生成中，请您稍等几分钟!');
            return;
        }
        if (!downloadUrl) {
            message.warning('小灵正在马不停蹄生成中，请您稍等');
            return;
        }
        window.open(downloadUrl, '_blank');
    };

    const CONTENT_ICON_CONFIG = [
        {
            key: '正文',
            icon: unlockZw,
        },
        {
            key: '图',
            icon: unlockDiagramActive,
        },
        {
            key: '表',
            icon: unlockTableActive,
        },
        {
            key: '公式',
            icon: unlockFormulaActive,
        },
        {
            key: '代码',
            icon: unlockCodeActive,
        },
    ];

    const getContentIcons = (content = '') => {
        return CONTENT_ICON_CONFIG.filter(({ key }) => content.includes(key)).map(
            ({ key, icon }) => (
                <div
                    key={key}
                    className="rounded-xs flex items-center rounded-sm bg-[#F4EEFF] px-1 text-sm text-primary"
                >
                    <Image width={12} height={12} src={icon} />
                    <span className="ml-1">{key}</span>
                </div>
            ),
        );
    };

    return (
        <Modal
            title="选择需要解锁的章节"
            open={modal.visible}
            onCancel={() => modal.hide()}
            footer={null}
            width={1000}
            className="chapter-select-modal"
            zIndex={1000}
        >
            <Spin spinning={loading}>
                <Row gutter={[16, 16]}>
                    {chapters.map((chapter) => (
                        <Col span={8} key={chapter.orderNo}>
                            <Card className="chapter-card">
                                <h3 className="mb-2 text-lg font-medium">{chapter.chapter}</h3>
                                <Space className="mb-4">
                                    {getContentIcons(chapter.content).map((icon, index) => (
                                        <span key={index} className="text-gray-500">
                                            {icon}
                                        </span>
                                    ))}
                                </Space>
                                <div className="flex items-center justify-between">
                                    <div className="price">
                                        <span className="text-lg text-red-500">
                                            ¥{chapter.price}
                                        </span>
                                        <span className="ml-2 text-gray-400 line-through">
                                            ¥{(chapter.price * 3).toFixed(2)}
                                        </span>
                                    </div>
                                    {chapter.status === CHAPTER_STATUS.UNLOCKED ? (
                                        <Button
                                            type="primary"
                                            size="small"
                                            icon={<DownloadOutlined />}
                                            onClick={() => handleDownload(chapter.downloadUrl)}
                                        >
                                            下载
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            size="small"
                                            disabled={
                                                unlockedChapter &&
                                                unlockedChapter !== chapter.orderNo
                                            }
                                            icon={<UnlockOutlined />}
                                            onClick={() => handleUnlock(chapter)}
                                        >
                                            解锁
                                        </Button>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Spin>
        </Modal>
    );
});

export default SelectUnlockChapter;
