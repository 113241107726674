import React from 'react';
import { Input } from 'antd';

const InputContainer = ({ title, value, placeholder, onChange }) => {
    return (
        <>
            <div className="title mb-2" style={{ color: '#192038' }}>
                {title}
            </div>
            <Input.TextArea
                className={`w-full h-[114px] p-3 rounded-md`}
                style={{
                    background: '#ffffff',
                    border: `1px solid #E9E9EA`,
                }}
                rows={4}
                placeholder={placeholder || '请尽量详细描述您想要的内容'}
                maxLength={60}
                value={value}
                onChange={onChange}
            />
        </>
    );
};

export default InputContainer;
