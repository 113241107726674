// export const UISearchType = {
//   WenXianYueDu: 1,
// };

// export const getSearchUIType = (type) => {
//   switch (type) {
//     case SearchType.WenXianYueDu:
//       return UISearchType.WenXianYueDu;
//     default:
//       return null;
//   }
// };

function getDotFromAngle(angle) {
  if (Math.abs(angle) === Math.PI / 2) {
    return {
      x: 0,
      y: angle > 0 ? 1 : -1,
    };
  }

  if (angle === (-Math.PI / 2) * 3) {
    return {
      x: 0,
      y: 1,
    };
  }

  let t = Math.tan(angle);
  if (Math.abs(angle) === Math.PI) {
    t = 0;
  }
  const signX = angle < -Math.PI / 2 ? -1 : 1;
  const x = (1 / Math.sqrt(1 + t * t)) * signX;
  const signY = angle < 0 && angle > -Math.PI ? -1 : 1;
  const y = Math.abs(x * t) * signY;
  return { x, y };
}

function findRect(dots) {
  // let min = { x: 1, y: 1 };
  // let max = { x: 0, y: 0 };
  // dots.forEach(({ x, y }) => {
  //   if (x < min.x) {
  //     min.x = x;
  //   }
  //   if (x > max.x) {
  //     max.x = x;
  //   }
  //   if (y < min.y) {
  //     min.y = y;
  //   }
  //   if (y > max.y) {
  //     max.y = y;
  //   }
  // });
  // const res = {
  //   x: min.x,
  //   y: min.y,
  //   x2: max.x,
  //   y2: max.y,
  // };

  const res = {
    x: dots[1].x,
    y: dots[1].y,
    x2: dots[2].x,
    y2: dots[2].y,
  };

  // 转化坐标系
  res.y = -res.y;
  res.y2 = -res.y2;
  // 平移到第一象限
  const dx = Math.max(0, -Math.min(res.x, res.x2));
  const dy = Math.max(0, -Math.min(res.y, res.y2));

  res.x = res.x + dx;
  res.x2 = res.x2 + dx;
  res.y = res.y + dy;
  res.y2 = res.y2 + dy;

  return res;
}

function getPieColor(i) {
  const colors = [["#FFEFDB", "#F7941D"]];
  return colors[0];
}

function getRandomColor(i) {
  // const r = Math.floor(Math.random() * 256); // 生成0到255之间的随机整数
  // const g = Math.floor(Math.random() * 256);
  // const b = Math.floor(Math.random() * 256);
  // return [r, g, b];
  const colors = [
    [247, 148, 28],
    [255, 108, 59],
    [255, 184, 43],
    [255, 127, 237],
    [144, 131, 255],
    [127, 202, 255],
    [128, 255, 206],
    [151, 255, 127],
    [255, 230, 130],
    [255, 173, 135],
  ];
  const idx = i % colors.length;
  return colors[idx];
}

export function updatePieItemWithColor(allData) {
  // total value
  const total = allData.reduce((prev, i) => prev + i.value, 0);
  // with angle
  const withAngle = allData.map((i) => ({
    ...i,
    angle: (i.value / total) * Math.PI * 2,
  }));
  // with color
  let usedAngle = 0;
  const withColor = withAngle.map((i, idx) => {
    const angle1 = Math.PI / 2 - usedAngle;
    usedAngle = usedAngle + i.angle;
    const angle2 = Math.PI / 2 - usedAngle;
    const dot1 = getDotFromAngle(angle1);
    const dot2 = getDotFromAngle(angle2);

    const finalRect = findRect([{ x: 0, y: 0 }, dot1, dot2]);

    const color = getRandomColor(idx);
    // const color = [247, 148, 29];
    // const color = [247, 148, 28];
    const colorStart = [...color, 0.2].join(",");
    const colorEnd = [...color, 1].join(",");

    const [start, end] = getPieColor();

    return {
      ...i,
      itemStyle: {
        color: {
          type: "linear",
          ...finalRect,
          colorStops: [
            {
              offset: 0,
              color: `rgba(${colorStart})`, // 0% 处的颜色
              // color: start,
            },
            {
              offset: 1,
              color: `rgba(${colorEnd})`, // 100% 处的颜色
              // color: end,
            },
          ],
          global: false, // 缺省为 false
        },
      },
    };
  });
  return withColor;
}
