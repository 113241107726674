import styles from './index.module.scss';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <>
            {/*<div className={styles.footer}>*/}
            {/*    <div className={styles.footerTop}>*/}
            {/*        <div className={styles.box}>*/}
            {/*            <div className={styles.imgBox}>*/}
            {/*                <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbgzh.png" />*/}
            {/*            </div>*/}
            {/*            <div>关注公众号，获取更多论文技巧</div>*/}
            {/*        </div>*/}
            {/*        <div className={styles.box}>*/}
            {/*            <div className={styles.imgBox}>*/}
            {/*                <img src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbqq.png" />*/}
            {/*            </div>*/}
            {/*            <div>灵感风暴官方QQ群</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <footer className="bg-white pb-4 pt-8">
                <div className="pl-3 md:mx-auto md:w-[78vw] md:px-4">
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                        <div>
                            <h3 className="mb-4 text-base font-semibold text-[#192038]">
                                关于我们
                            </h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link
                                        to={'/companyIntro'}
                                        target={'_blank'}
                                        className="text-sm text-[#414C6F] hover:text-gray-900"
                                    >
                                        公司介绍
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="mb-4 text-base font-semibold text-[#192038]">
                                产品介绍
                            </h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link
                                        to={'/productIntro'}
                                        target={'_blank'}
                                        className="text-sm text-[#414C6F] hover:text-gray-900"
                                    >
                                        产品介绍
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="mb-4 text-base font-semibold text-[#192038]">
                                联系我们
                            </h3>
                            <ul className="space-y-2">
                                <li className={'text-sm text-[#414C6F]'}>
                                    客服邮箱：
                                    <a href="kf@lingganfengbao.com" className="hover:text-gray-900">
                                        kf@lingganfengbao.com
                                    </a>
                                </li>
                                <li className={'text-sm text-[#414C6F]'}>
                                    投诉反馈：
                                    <a href="ts@lingganfengbao.com" className="hover:text-gray-900">
                                        ts@lingganfengbao.com
                                    </a>
                                </li>
                                <li className={'text-sm text-[#414C6F]'}>
                                    商务合作：
                                    <a href="bd@lingganfengbao.com" className="hover:text-gray-900">
                                        bd@lingganfengbao.com
                                    </a>
                                </li>
                                <li className={'text-sm text-[#414C6F]'}>
                                    联系地址：
                                    <span>
                                        安徽省合肥市蜀山区西园街道黄山路468号通和佳苑（易居时代公寓）1幢、2幢1＃905室
                                    </span>
                                </li>
                                <li className={'text-sm text-[#414C6F]'}>
                                    联系电话：
                                    <span>18391174426</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={'flex justify-center'}>
                    灵感风暴&nbsp;|&nbsp;
                    <a
                        href="https://beian.miit.gov.cn/#/Integrated/index"
                        className={styles.aboutUs}
                        target="_blank"
                        rel="noreferrer"
                    >
                        皖ICP备2023021108号-2 LINGGANFENGBAO.COM
                    </a>
                    &nbsp;|&nbsp;
                    <a
                        href="https://beian.cac.gov.cn"
                        className={styles.aboutUs}
                        target="_blank"
                        rel="noreferrer"
                    >
                        网信算备340104444369501240019号
                    </a>
                </div>
            </footer>
        </>
    );
};
