import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';

import './AcademicEditor.scss';
import { findParentNode } from '@tiptap/react';
import { message } from 'antd';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { parseTable, processContent } from './AcademicEditorUtil';
import EditorScroller from './EditorScrollBar';
import { extensions } from './customConfig/extensions';
import { shouldShowBubbleMenu, shouldShowButtons } from './bubbleMenuUtils';

// 主编辑器组件
const AcademicEditor = forwardRef(
    ({ data, onOpenModal, getWordCount, replaceContentToServer }, ref) => {
        const [qs] = useSearchParams();
        const lastContentRef = useRef('');
        const editingChapterId = useRef('');
        const editingChapterContent = useRef('');
        const editor = useEditor({
            extensions,
            content: '',
            editorProps: {
                attributes: {
                    class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
                },
            },
            // ���加选择更新事件处理
            onSelectionUpdate: ({ editor }) => {
                const { from, to } = editor.state.selection;
                if (from !== to) {
                    // 如果需要更准确地获取包含该位置的段落节点
                    const resolvedPos = editor.state.doc.resolve(from);
                    const paragraphNode = resolvedPos.node(1); // 获取最近的块级节点

                    if (paragraphNode) {
                        const nodeId = paragraphNode.attrs.id;

                        console.log('段落节点属性:', {
                            nodeId,
                            allAttrs: paragraphNode.attrs,
                        });
                        console.log('选区位置:', { from, to });
                    }
                }
            },
            onTransaction: ({ editor, transaction }) => {
                if (!transaction.docChanged) return;

                const { from } = editor.state.selection;
                const resolvedPos = editor.state.doc.resolve(from);
                const paragraphNode = resolvedPos.node(1);
                const chapterId = paragraphNode?.attrs?.id;

                if (!chapterId) return;

                // 收集同一个 transaction 中的所有操作
                const operations = [];

                transaction.steps.forEach((step) => {
                    let operationType;
                    let content;
                    let from;
                    let to;
                    let length;

                    const stepType = step.constructor.name;

                    // 处理不同类型的步骤
                    switch (stepType) {
                        case 'ReplaceStep':
                            if (step.slice && step.slice.content.size > 0) {
                                operationType = 'insert';
                                content = step.slice.content.textBetween(
                                    0,
                                    step.slice.content.size,
                                    '\n', // 使用换行符处理多行内容
                                    ' ', // 空格作为 block 节点之间的分隔符
                                );
                                from = step.from;
                                to = step.to;
                            } else {
                                operationType = 'delete';
                                content = transaction.before.textBetween(
                                    step.from,
                                    step.to,
                                    '\n',
                                    ' ',
                                );
                                from = step.from;
                                to = step.to;
                                length = step.to - step.from;
                            }
                            break;

                        // 可以添加其他类型的步骤处理
                        case 'ReplaceAroundStep':
                            // 处理包裹内容的操作（如添加格式）
                            break;
                        default:
                            break;
                    }

                    if (content) {
                        operations.push({
                            type: operationType, // 操作类型 delete 或 insert
                            from, // 操作开始位置
                            to, // 操作结束位置
                            content, // 操作内容
                            length, // 操作长度
                            stepType, // 操作类型
                        });
                    }
                });

                // 如果有操作，打印日志查看
                if (operations.length > 0) {
                }

                // 更新字数
                const wordCount = editor.storage.characterCount.words();
                updateWordCount(wordCount);
            },
            onFocus: () => {
                console.log('Editor focused');
            },
        });
        const updateChapterContent = useRef(
            debounce((replacementContent) => {
                replaceContentToServer({
                    chapterId: editingChapterId.current,
                    orderNo: qs.get('orderNo'),
                    original: editingChapterContent.current,
                    replacementContent,
                });
            }, 120),
        ).current;
        const scroller = new EditorScroller(editor);

        // 创建一个防抖的 updateWordCount 函数
        const updateWordCount = useRef(
            debounce((count) => {
                getWordCount(count);
            }, 300),
        ).current;
        // 在组件卸载时取消防抖
        useEffect(() => {
            return () => {
                updateWordCount.cancel();
            };
        }, []);

        const [content, setContent] = useState('');
        // 初始编辑器内容
        useEffect(() => {
            if (editor && data) {
                const content = processContent(data);
                setContent(content);

                // 等待内容设置完成后再计算字数
                setTimeout(() => {
                    const wordCount = editor.storage.characterCount.words();
                    getWordCount(wordCount);
                }, 0);
            }
        }, [editor]);
        // 内容变化时更新编辑器内容
        useEffect(() => {
            if (editor && content) {
                editor.commands.setContent(content);

                // 内容更新后计算字数
                const wordCount = editor.storage.characterCount.words();
                getWordCount(wordCount);
            }
        }, [editor, content]);

        // 检查工具选中状态
        const checkToolSelection = (toolId) => {
            const { from, to } = editor.state.selection;
            const hasSelection = from !== to;
            const node = hasSelection ? editor.state.doc.nodeAt(from) : null;

            // 文本工具：需要有文本选中
            const textTools = ['reduce', 'ai', 'expand', 'shrink', 'rewrite'];
            if (textTools.includes(toolId)) {
                if (!hasSelection) {
                    message.warning('请选择需要处理的文本');
                    return false;
                }
                return true;
            }

            // 插入工具：需要选中插入位置
            if (toolId === 'insert') {
                // 检测是否聚焦
                if (!editor.isFocused) {
                    message.warning('请聚焦到需要插入的位置');
                    return false;
                }
                const { from, to } = editor.state.selection;
                const hasSelection = from !== to;

                if (hasSelection) {
                    message.warning('插入时不能选中内容，请将光标放置在需要插入的位置');
                    return false;
                }
                return true;
            }

            // 修图工具：需要选中图片
            if (toolId === 'image') {
                if (!node || node.type.name !== 'image') {
                    message.warning('请选择需要修改的图片');
                    return false;
                }
                return true;
            }

            // 修表工具：需要选中表格
            if (toolId === 'table') {
                // 先检查是否直接选中了表格
                if (node?.type.name === 'table') {
                    return true;
                }

                // 如果不是表格，检查是否在表格内
                const { from } = editor.state.selection;
                const $pos = editor.state.doc.resolve(from);

                // 检查当前节点及其所有祖先节点是否包含表格
                let depth = $pos.depth;
                let isInTable = false;

                while (depth > 0) {
                    const node = $pos.node(depth);
                    if (node.type.name === 'table') {
                        isInTable = true;
                        break;
                    }
                    depth--;
                }

                if (!isInTable) {
                    message.warning('请选择需要修改的表格');
                    return false;
                }
                return true;
            }

            return true;
        };

        // 检查选区是否包含标题
        const checkForHeadings = (from, to) => {
            let hasHeading = false;
            console.log('from', from);
            console.log('to', to);
            editor.state.doc.nodesBetween(from, to, (node) => {
                if (node.type.name === 'heading') {
                    console.log('node', node);
                    hasHeading = true;
                    return false; // 停止遍历
                }
            });
            return hasHeading;
        };

        // 获取选中的文本内容
        const getSelectedContent = (from, to) => {
            return editor.state.doc.textBetween(from, to, ' ');
        };
        // 获取选中的图片
        const getSelectedImage = () => {
            const { from } = editor.state.selection;
            const node = editor.state.doc.nodeAt(from);
            if (node?.type.name === 'image') {
                return {
                    src: node.attrs.src,
                    id: node.attrs.alt.split('-')[1],
                    sectionId: node.attrs.alt.split('-')[0],
                };
            }
            return null;
        };

        // 辅助函数：找到指定 id 的图片位置
        const findImagePosition = (doc, id) => {
            let pos = -1;
            doc.descendants((node, position) => {
                if (node.type.name === 'image' && node.attrs?.alt?.split('-')[1] === id) {
                    pos = position;
                    return false; // 停止遍历
                }
            });
            return pos;
        };

        // 暴露给父组件的方法
        useImperativeHandle(ref, () => ({
            // 设置编辑器是否可编辑
            setEditable(editable) {
                editor.setOptions({ editable });
            },
            // 将编辑器滚动到指定文本位置
            scrollToHeadingText(text) {
                scroller.scrollToText(text);
            },
            // 将编辑器滚动到指定id位置
            scrollToId(id) {
                scroller.scrollToId(id);
            },
            // 检查工具选中状态
            checkToolSelection: (toolId) => {
                return checkToolSelection(toolId);
            },
            handleButtonClick: (type) => {
                handleButtonClick(type);
            },
            // 获取文档字数
            getDocumentWordCount: () => {
                return editor.storage.characterCount.words();
            },
            // 获取选中的内容
            getSelectedContent: () => {
                return getSelectedContent();
            },
            // 获取选中的图片
            getSelectedImage: () => {
                return getSelectedImage();
            },
            // 获取选中的表格
            getSelectedTable: () => {
                // 判断当前选中的是否是表格标签
                const { from } = editor.state.selection;
                const node = editor.state.doc.nodeAt(from);
                if (node?.type.name === 'table') {
                    return node;
                }
                return null;
            },
            // 往光标位置插入内容
            insertContentAt: (from, to, content) => {
                editor.commands.insertContentAt({ from, to }, content);
            },
            // 更新选区部分的文本内容
            updateTextContent: (from, to, content) => {
                editor
                    .chain()
                    .focus() // 确保编辑器获得焦点
                    .insertContentAt({ from, to }, content)
                    .setTextSelection(from + content.length)
                    .run();
            },
            // 更新选中的图片的src
            updateImageSrc: (oldId, newSrc) => {
                // 参数校验
                if (!oldId || !newSrc) {
                    console.error('Invalid parameters for updateImageSrc');
                    return false;
                }

                try {
                    return editor
                        .chain()
                        .focus()
                        .command(({ tr }) => {
                            const pos = findImagePosition(tr.doc, oldId);
                            if (pos === -1) {
                                console.warn(`Image with id ${oldId} not found`);
                                return false;
                            }

                            // 验证新的src
                            if (!newSrc.match(/^(http|https|data:image)/)) {
                                console.error('Invalid image source URL');
                                return false;
                            }

                            tr.setNodeAttribute(pos, 'src', newSrc);

                            // 确保事务被应用
                            if (!tr.docChanged) {
                                console.warn('Transaction did not modify the document');
                                return false;
                            }

                            return true;
                        })
                        .run();
                } catch (error) {
                    console.error('Error updating image source:', error);
                    return false;
                }
            },
            // 更新选中的表格的HTML
            updateTableHtml: (id, from, to, tableContent) => {
                editor.commands.command(({ tr }) => {
                    const node = tr.doc.nodeAt(from);
                    // 确保是表格点且ID匹配
                    if (node?.type.name === 'table' && node.attrs['data-table-id'] === id) {
                        // 检查是否是正确的 AIML_TABLE 格式
                        if (
                            tableContent.startsWith('[AIML_TABLE') &&
                            tableContent.endsWith('[/AIML_TABLE]')
                        ) {
                            try {
                                // 使用现有的 parseTable 函数解析表格内容
                                const match = tableContent.match(
                                    /\[AIML_TABLE id=(.*?)\](.*?)\[\/AIML_TABLE\]/s,
                                );
                                if (match) {
                                    const [_, tableId, tableMarkdown] = match;
                                    const newTableHtml = parseTable(tableMarkdown, tableId);

                                    if (newTableHtml) {
                                        editor.commands.replaceRange({ from, to }, newTableHtml);
                                        return true;
                                    }
                                }
                            } catch (error) {
                                console.error('Error updating table:', error);
                            }
                        }
                    }
                    return false;
                });
            },
        }));

        const handleButtonClick = (type) => {
            const selection = editor.state.selection;
            const { from, to } = selection;

            // 检查选中区域是否包含标题
            const hasHeading = checkForHeadings(from, to);
            if (hasHeading) {
                message.warning('不能选中标题内容！');
                return;
            }

            // 检查工具类型的选区要求
            if (!checkToolSelection(type)) {
                return; // checkToolSelection 内部已经包含了警告提示
            }

            let chapterId = '';
            let id = '';
            // 根据工具类型获取内容
            let content;
            switch (type) {
                case 'image':
                    content = getSelectedImage();
                    chapterId = content.sectionId;
                    id = content.id;
                    break;
                case 'table':
                    const tableParent = findParentNode((node) => node.type.name === 'table')(
                        editor.state.selection,
                    );

                    if (tableParent) {
                        const tableParent = findParentNode((node) => node.type.name === 'table')(
                            editor.state.selection,
                        );

                        if (tableParent) {
                            content = tableParent.node.textContent;
                            console.log('node', tableParent.node);

                            console.log('content', content);

                            // 获取当前文档
                            const doc = editor.state.doc;
                            // 存储找到的所有符合条件的节点
                            const foundNodes = [];

                            doc.nodesBetween(0, tableParent.pos, (node, pos) => {
                                if (
                                    node.type.name === 'heading' ||
                                    node.type.name === 'paragraph'
                                ) {
                                    foundNodes.push({ node, pos });
                                }
                            });

                            // 从后往前遍历找到的节点，直到找到有 id 的节点
                            for (let i = foundNodes.length - 1; i >= 0; i--) {
                                const { node } = foundNodes[i];

                                if (node.attrs.id && !chapterId) {
                                    chapterId = node.attrs.id;
                                }
                            }
                        }
                    }
                    break;
                case 'insert':
                    content = '';
                    break;
                default:
                    if (from !== to) {
                        // 如果需要更准确地获取包含该位置的段落节点
                        const resolvedPos = editor.state.doc.resolve(from);
                        const paragraphNode = resolvedPos.node(1); // 获取最近的块级节点

                        if (paragraphNode) {
                            chapterId = paragraphNode.attrs.id;
                        }
                    }
                    content = getSelectedContent(from, to);
            }

            if (content) {
                onOpenModal({
                    type,
                    content,
                    from,
                    to,
                    chapterId,
                    id,
                });
            }
        };

        const rewriteChapter = () => {
            // 1. 获取精确的选区信息
            const { from, to, empty } = editor.state.selection;
            const $from = editor.state.doc.resolve(from);
            const $to = editor.state.doc.resolve(to);

            // 2. 精确定位当前标题节点
            let headingDepth = -1;
            let headingPos = -1;
            let headingNode = null;

            // 从当前深度向上精确查找标题节点（3级或4级）
            for (let d = $from.depth; d >= 0; d--) {
                const node = $from.node(d);
                if (
                    node.type.name === 'heading' &&
                    (node.attrs.level === 3 || node.attrs.level === 4)
                ) {
                    headingDepth = d;
                    headingNode = node;
                    headingPos = $from.before(d);
                    break;
                }
            }

            // 3. 确保找到了有效的标题节点
            if (!headingNode || headingPos === -1) {
                console.warn('No valid heading found at current position');
                return;
            }

            const doc = editor.state.doc;
            const headingEnd = headingPos + headingNode.nodeSize;

            // 4. 精确收集章节内容
            let contents = [];
            let contentStart = headingEnd;
            let contentEnd = doc.content.size;
            let foundNextHeading = false;

            // 5. 使用更精确的节点遍历逻辑
            doc.nodesBetween(headingEnd, doc.content.size, (node, pos, parent, index) => {
                // 只处理直接子节点
                if (parent === doc) {
                    if (node.type.name === 'heading') {
                        // 如果当前是3级标题，遇到下一个3级或更高级别标题就停止
                        if (headingNode.attrs.level === 3 && node.attrs.level <= 3) {
                            contentEnd = pos;
                            foundNextHeading = true;
                            return false;
                        }
                        // 如果当前是4级标题，遇到任何同级或更高级别标题就停止
                        if (headingNode.attrs.level === 4 && node.attrs.level <= 4) {
                            contentEnd = pos;
                            foundNextHeading = true;
                            return false;
                        }
                    }

                    if (!foundNextHeading && node.type.name === 'paragraph') {
                        // 确保段落内容完整性
                        const paragraphContent = node.textContent.trim();
                        if (paragraphContent) {
                            contents.push({
                                type: node.type.name,
                                from: pos,
                                to: pos + node.nodeSize,
                                content: paragraphContent,
                            });
                        }
                    }
                }
                return !foundNextHeading;
            });

            // 6. 验证内容有效性
            if (contents.length === 0) {
                console.warn('No valid content found in current section');
                return;
            }

            // 7. 添加开发环境调试信息
            if (process.env.NODE_ENV === 'development') {
                console.log({
                    debug: {
                        selectionFrom: from,
                        selectionTo: to,
                        headingPosition: headingPos,
                        headingDepth,
                        headingId: headingNode.attrs.id,
                        headingLevel: headingNode.attrs.level,
                        contentRange: {
                            start: contentStart,
                            end: contentEnd,
                        },
                        contentsCount: contents.length,
                    },
                    headingId: headingNode.attrs.id,
                    from: contentStart,
                    to: contentEnd,
                    contents,
                });
            }

            // 8. 调用模态框，确保所有必要参数都是有效的
            if (headingNode.attrs.id && contentStart < contentEnd) {
                onOpenModal({
                    type: 'rewrite',
                    content: contents,
                    from: contentStart,
                    to: contentEnd,
                    chapterId: headingNode.attrs.id,
                });
            }
        };

        if (!editor) {
            return null;
        }

        return (
            <div className="academic-editor">
                {editor && (
                    <BubbleMenu
                        className="bubble-menu"
                        tippyOptions={{
                            duration: 100,
                            hideOnClick: true,
                            trigger: 'manual',
                        }}
                        editor={editor}
                        shouldShow={shouldShowBubbleMenu}
                    >
                        {/* 修图按钮 */}
                        {shouldShowButtons.image(editor) && (
                            <button
                                className="menu-button"
                                onClick={() => handleButtonClick('image')}
                            >
                                修图
                            </button>
                        )}

                        {/* 修表按钮 */}
                        {shouldShowButtons.table(editor) && (
                            <button
                                className="menu-button"
                                onClick={() => handleButtonClick('table')}
                            >
                                修表
                            </button>
                        )}

                        {/* 重写本章按钮 */}
                        {shouldShowButtons.rewrite(editor) && (
                            <button className="menu-button" onClick={rewriteChapter}>
                                重写本章
                            </button>
                        )}

                        {/* 文本工具按钮组 */}
                        {shouldShowButtons.textTools(
                            editor,
                            editor.state.selection.from,
                            editor.state.selection.to,
                        ) && (
                            <>
                                <button
                                    className="menu-button"
                                    onMouseDown={() => handleButtonClick('reduce')}
                                >
                                    降重
                                </button>
                                <button
                                    className="menu-button"
                                    onClick={() => handleButtonClick('expand')}
                                >
                                    扩写
                                </button>
                                <button
                                    className="menu-button"
                                    onClick={() => handleButtonClick('shrink')}
                                >
                                    缩写
                                </button>
                            </>
                        )}
                    </BubbleMenu>
                )}
                <EditorContent
                    editor={editor}
                    onKeyDown={(e) => {
                        // 如果是在标题内且按下删除键
                        if (
                            (e.key === 'Backspace' || e.key === 'Delete') &&
                            editor.isActive('heading')
                        ) {
                            e.preventDefault(); // 阻止默认行为
                            return false;
                        }
                    }}
                />
            </div>
        );
    },
);

export default memo(AcademicEditor);
