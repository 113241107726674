import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'antd';
import { Nav } from '../../components/Nav/index';
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useScroll } from 'ahooks';
import exchange from '../../assets/exchange.png';

const ExchangeCode = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code') || '';
    const scroll = useScroll(document);
    const isVisible = scroll?.top && scroll.top > 50 ? false : true;

    const handleCopy = () => {
        navigator.clipboard.writeText(code).then(() => {
            message.success('复制成功');
        });
    };

    return (
        <div className="flex min-h-screen flex-col bg-white">
            <Nav hasBottomBorder={true} bgWhite={true} />

            {/* Tab栏 */}
            <div className="mb-16 mt-6 flex justify-center">
                <div className="flex h-[38px] cursor-pointer items-center justify-center rounded-[19px] bg-primary px-4 text-white">
                    答辩PPT兑换码
                </div>
            </div>

            <div
                className={`transition-all duration-300 ${
                    isVisible ? 'h-[164px] opacity-100' : 'h-0 opacity-0'
                }`}
            >
                <img
                    src={exchange}
                    alt="兑换码"
                    className="mx-auto h-[164px] w-[216px] object-contain"
                />
            </div>

            {/* 兑换码展示和复制 */}
            <div className="mb-10 flex flex-col items-center">
                <div className="flex h-[48px] w-[310px] items-center justify-center rounded-[24px] bg-primary text-white">
                    <span className="mr-2">{code}</span>
                </div>
                <div className="mt-2 cursor-pointer hover:text-primary" onClick={handleCopy}>
                    复制兑换码
                </div>
            </div>

            {/* 使用说明 */}
            <div className="mx-auto w-[60vw] rounded-[16px] bg-[#F9FBFF] p-6 text-left">
                <h3 className="mb-4 text-lg font-medium text-[#1D2129]">兑换码使用方法~</h3>
                <div className="text-gray-600">{/* 这里添加兑换码使用说明的具体内容 */}</div>
            </div>
        </div>
    );
};

export default ExchangeCode;
