export const processContent = (data) => {
    if (!data) return '';

    let content = '';

    // 处理中文摘要 - 添加换行符处理
    if (data.abstract_zh) {
        const processedAbstract = data.abstract_zh.replace(/\r\n/g, '\n').replace(/\n/g, '<br />');
        content += `
            <h2>摘要</h2>
            <p id="abstract_zh">${processedAbstract}</p>
        `;
    }

    // 处理英文摘要 - 添加换行符处理
    if (data.abstract_en) {
        const processedAbstract = data.abstract_en.replace(/\r\n/g, '\n').replace(/\n/g, '<br />');
        content += `
        <h2>Abstract</h2>
        <p id="abstract_en">${processedAbstract}</p>
    `;
    }

    // 处理主体内容
    if (data?.main_body?.length) {
        data.main_body.forEach((chapter) => {
            // 章节标题
            if (chapter.chapter) {
                content += `<h2 id="${chapter?.id || ''}">${chapter.chapter}</h2>`;
            }

            // 章节内容
            if (chapter.content) {
                content += processSection(chapter.content, chapter?.id);
            }

            // 处理 sections
            if (chapter.sections?.length) {
                chapter.sections.forEach((section) => {
                    // 小节标题
                    if (section.chapter) {
                        content += `<h3 id="${section?.id || ''}">${section.chapter}</h3>`;
                    }

                    // 小节内容
                    if (section.content) {
                        content += processSection(section.content, section?.id);
                    }

                    // 处理 subSections
                    if (section.subSections?.length) {
                        section.subSections.forEach((subSection) => {
                            // 子小节标题
                            if (subSection.chapter) {
                                content += `<h4 id="${subSection?.id || ''}">${subSection.chapter}</h4>`;
                            }

                            // 子小节内容
                            if (subSection.content) {
                                content += processSection(subSection.content, subSection?.id);
                            }
                        });
                    }
                });
            }
        });
    }

    return content;
};

// 自定义表格解析函数
export const parseTable = (tableContent, tableId) => {
    try {
        // Markdown 格式解析
        if (tableContent.includes('|')) {
            return parseMarkdownTable(tableContent, tableId);
        }
        // JSON 格式解析
        return parseJsonTable(tableContent, tableId);
    } catch (error) {
        console.error('Error parsing table:', error);
        return '';
    }
};

// 解析 Markdown 格式表格
const parseMarkdownTable = (tableContent, tableId) => {
    const rows = tableContent.trim().split('\n');
    const headers = rows[0]
        .split('|')
        .filter((cell) => cell.trim())
        .map((cell) => cell.trim());

    const dataRows = rows
        .filter((row) => row.trim() && !row.includes('---'))
        .slice(1)
        .map((row) =>
            row
                .split('|')
                .filter((cell) => cell.trim())
                .map((cell) => cell.trim()),
        );

    return generateTableHtml(headers, dataRows, tableId);
};

// 解析 JSON 格式表格
const parseJsonTable = (tableContent, tableId) => {
    const tableData = JSON.parse(tableContent);
    if (!tableData.headers || !tableData.rows) return '';

    return generateTableHtml(tableData.headers, tableData.rows, tableId);
};

// 生成表格 HTML
const generateTableHtml = (headers, rows, tableId) => {
    return `
        <table
            class="academic-table"
            data-table-id="${tableId}"
            contenteditable="false"
        >
            <thead>
                <tr>${headers.map((h) => `<th>${h}</th>`).join('')}</tr>
            </thead>
            <tbody>
                ${rows
                    .map(
                        (row) => `
                    <tr>${row.map((cell) => `<td>${cell}</td>`).join('')}</tr>
                `,
                    )
                    .join('')}
            </tbody>
        </table>
    `;
};

// 处理章节内容
export const processSection = (content, sectionId) => {
    if (!content) return '';

    let processedContent = content;

    // 处理上标
    processedContent = processedContent.replace(
        /\[AIML_SUPERSCRIPT\](.*?)\[\/AIML_SUPERSCRIPT\]/gs,
        (_, text) => `<sup>${text}</sup>`,
    );

    processedContent = processedContent.replace(
        /\[AIML_TABLE id=(.*?)\](.*?)\[\/AIML_TABLE\]/gs,
        (_, id, table) => parseTable(table, id),
    );

    // 处理文本 - 添加换行符处理
    processedContent = processedContent.replace(
        /\[AIML_TEXT\](.*?)\[\/AIML_TEXT\]/gs,
        (_, text) => {
            const processedText = text.trim().replace(/\r\n/g, '\n').replace(/\n/g, '<br />');
            return `<p id="${sectionId}">${processedText}</p>`;
        },
    );

    // 处理echarts - 支持带id的格式
    processedContent = processedContent.replace(
        /\[AIML_ECHARTS id=(.*?)\](.*?)\[\/AIML_ECHARTS\]/gs,
        (_, id, base64Image) => `<img
            src="${base64Image}"
            alt="${sectionId}-${id}"
            id="${id}"
        />`,
    );

    // 处理 Mermaid - 支持带id的格式
    processedContent = processedContent.replace(
        /\[AIML_MERMAID id=(.*?)\](.*?)\[\/AIML_MERMAID\]/gs,
        (_, id, base64Image) => `<img
            src="${base64Image}"
            alt="${sectionId}-${id}"
            id="${id}"
        />`,
    );

    return processedContent;
};
