import React, { useState, useCallback } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import CustomDrawer from './CustomDrawer';
import InputContainer from './InputContainer';
import { Button, Image, Select, Space, message, Spin, Cascader } from 'antd';
import TextContainer from './TextContainer';
import { post } from '../../../../axios/axios';

const getBase64FromContent = (content) => {
    const regex = /\[AIML_(ECHARTS|MERMAID) id=.*?\](.*?)\[\/AIML_(?:ECHARTS|MERMAID)\]/;
    const match = content.match(regex);
    return match ? match[2] : '';
};

// 修改图片
const ImgModal = NiceModal.create(({ orderNo, content, chapterId, from, to, id }) => {
    const modal = useModal();
    const [prompt, setPrompt] = useState('');
    const [imgType, setImgType] = useState('');
    const [loading, setLoading] = useState(false);
    const [newSrc, setNewSrc] = useState('');

    // 重置状态的函数
    const resetState = () => {
        setPrompt('');
        setImgType('');
        setLoading(false);
        setNewSrc('');
    };

    const handleOk = async () => {
        if (!newSrc) {
            message.warning('请先生成新图片');
            return;
        }
        modal.resolve(newSrc);
        modal.hide();
        resetState();
    };

    const onPromptChange = (e) => {
        setPrompt(e.target.value);
    };

    const onImgTypeChange = (e) => {
        setImgType(e);
    };

    const fetchData = async () => {
        if (!prompt) {
            message.warning('请输入提示词');
            return;
        }
        if (!imgType) {
            message.warning('请选择AI出图类型');
            return;
        }
        if (!id) {
            message.warning('图片ID不存在');
            return;
        }

        try {
            setLoading(true);
            const res = await post('/ai/ai/paper/editor/updateChapterImage', {
                orderNo,
                chapterId,
                imgType,
                prompt,
                id,
            });

            const base64Content = getBase64FromContent(res.data);
            if (!base64Content) {
                throw new Error('Invalid image data received');
            }

            setNewSrc(base64Content);
            message.success('图片生成成功');
        } catch (error) {
            console.error('Error fetching content reduction:', error);
            message.error('图片生成失败，请重试');
        } finally {
            setLoading(false);
        }
    };

    //     1. 数据分析图表
    // 用于展示数据和趋势的分析：
    // 折线图
    // 曲面图
    // 饼图
    // 柱状图
    // 散点图
    // 箱型图
    // 等高线图
    // 3D图形
    // 三维线性逼近图
    // 三维抛物线坐标系
    // 2. 结构与流程图
    // 展示层级、关系、流程或逻辑：
    // 流程图
    // 树状图
    // 森林图
    // 思维导图
    // 组织结构图
    // 用例图
    // 甘特图
    // 时间线
    // 3. 技术与专业图
    // 用于科学研究或专业领域的技术展示：
    // ER图（实体关系图）
    // 类图
    // 状态图
    // 时序图
    // 拓扑图
    // 化学分子结构图
    // 电子元件图
    // 逻辑电路图
    // 群的凯莱图
    // 生物信息学图
    // 网络图
    // 关系图
    // 4. 数学与几何图
    // 展示数学、几何和拓扑相关内容：
    // 函数图
    // 几何图形
    // 彭罗斯图
    // 数学图形
    // 三维几何图形
    // 维恩图
    // 5. 艺术与时间展示
    // 用于表达艺术创作或时间相关信息：
    // 音乐符谱
    // 时间线
    const chartTypes = [
        {
            value: '数据分析图表',
            label: '数据分析图表',
            children: [
                {
                    value: '折线图',
                    label: '折线图',
                },
                {
                    value: '柱状图',
                    label: '柱状图',
                },
                {
                    value: '饼图',
                    label: '饼图',
                },
                {
                    value: '散点图',
                    label: '散点图',
                },
                {
                    value: '箱型图',
                    label: '箱型图',
                },
                {
                    value: '等高线图',
                    label: '等高线图',
                },
                {
                    value: '曲面图',
                    label: '曲面图',
                },
                {
                    value: '矢量场',
                    label: '矢量场',
                },
                {
                    value: '3D图形',
                    label: '3D图形',
                },
                {
                    value: '三维线性逼近图',
                    label: '三维线性逼近图',
                },
                {
                    value: '三维抛物线坐标系',
                    label: '三维抛物线坐标系',
                },
            ],
        },
        {
            value: '结构与流程图',
            label: '结构与流程图',
            children: [
                {
                    value: '流程图',
                    label: '流程图',
                },
                {
                    value: '树状图',
                    label: '树状图',
                },
                {
                    value: '树图',
                    label: '树图',
                },
                {
                    value: '森林图',
                    label: '森林图',
                },
                {
                    value: '思维导图',
                    label: '思维导图',
                },
                {
                    value: '组织结构图',
                    label: '组织结构图',
                },
                {
                    value: '网络图',
                    label: '网络图',
                },
                {
                    value: '关系图',
                    label: '关系图',
                },
                {
                    value: '甘特图',
                    label: '甘特图',
                },
            ],
        },
        {
            value: '技术与专业图',
            label: '技术与专业图',
            children: [
                {
                    value: 'ER图',
                    label: 'ER图',
                },
                {
                    value: '类图',
                    label: '类图',
                },
                {
                    value: '状态图',
                    label: '状态图',
                },
                {
                    value: '时序图',
                    label: '时序图',
                },
                {
                    value: '用例图',
                    label: '用例图',
                },
                {
                    value: '化学分子结构图',
                    label: '化学分子结构图',
                },
                {
                    value: '电子元件图',
                    label: '电子元件图',
                },
                {
                    value: '逻辑电路图',
                    label: '逻辑电路图',
                },
                {
                    value: '生物信息学图',
                    label: '生物信息学图',
                },
            ],
        },
        {
            value: '数学与几何图',
            label: '数学与几何图',
            children: [
                {
                    value: '函数图',
                    label: '函数图',
                },
                {
                    value: '几何图形',
                    label: '几何图形',
                },
                {
                    value: '三维几何图形',
                    label: '三维几何图形',
                },
                {
                    value: '彭罗斯图',
                    label: '彭罗斯图',
                },
                {
                    value: '拓扑图',
                    label: '拓扑图',
                },
                {
                    value: '群的凯莱图',
                    label: '群的凯莱图',
                },
                {
                    value: '数学图形',
                    label: '数学图形',
                },
                {
                    value: '维恩图',
                    label: '维恩图',
                },
            ],
        },
        {
            value: '艺术与时间展示',
            label: '艺术与时间展示',
            children: [
                {
                    value: '音乐符谱',
                    label: '音乐符谱',
                },
                {
                    value: '时间线',
                    label: '时间线',
                },
            ],
        },
    ];

    return (
        <CustomDrawer
            title="修改图片"
            visible={modal.visible}
            onClose={() => {
                modal.reject();
                modal.hide();
                resetState();
            }}
            okText="生成图片"
            resetText="替换原图"
            onOk={fetchData}
            onReset={handleOk}
            okButtonProps={{
                loading: loading,
                disabled: loading,
            }}
            resetButtonProps={{
                disabled: loading || !newSrc,
            }}
        >
            <InputContainer
                title="提示词"
                onChange={onPromptChange}
                value={prompt}
                disabled={loading}
            />

            <div className="mt-4 flex items-center gap-2">
                <div className="w-[88px]">出图类型：</div>
                <Cascader
                    placeholder="请选择AI出图类型"
                    value={imgType}
                    onChange={onImgTypeChange}
                    style={{ width: '100%' }}
                    disabled={loading}
                    showSearch
                    options={chartTypes}
                />
            </div>
            <div className="mt-4">
                {loading ? (
                    <div className="flex h-[200px] w-full items-center justify-center">
                        <Spin tip="正在生成图片..." />
                    </div>
                ) : newSrc ? (
                    <TextContainer type="active" title="生成结果">
                        <div className="images mt-4 flex flex-wrap items-center gap-4">
                            <Image preview={false} src={newSrc} />
                        </div>
                    </TextContainer>
                ) : null}
            </div>
        </CustomDrawer>
    );
});

export default ImgModal;
