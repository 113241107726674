import styles from './result.module.scss';
import { Modal, QRCode, Button, Alert, message, Input, Spin } from 'antd';
import { useState, useEffect, useRef, useCallback } from 'react';
import { get, post, baseURL } from '../../../axios/axios';
import { observer } from 'mobx-react';
import { useEventSource } from '../../helper/api';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Drawer } from 'antd';
import { Summary } from '../Summary';

import { searchResultStore } from '../../SearchResult/store';
import { DOCTYPEENUM } from '../../Documents/const';

export const Preview = observer(() => {
    const [loading, setLoading] = useState(false);
    const nav = useNavigate();

    const [drawerVisible, setDrawerVisible] = useState(false);
    const showDrawer = useCallback((e) => {
        e.stopPropagation();
        setDrawerVisible(true);
    }, []);

    const closeDrawer = useCallback(() => {
        setDrawerVisible(false);
    }, []);
    // 中文摘要
    const { startFetch, status: streamStatus } = useEventSource();
    const chineseSummary = useRef('');
    const englishSummary = useRef('');
    const previewContent = useRef('');
    const summaryPreviewEnd = useRef(false);
    const finallyPreviewEnd = useRef(false);

    // 英文摘要
    const {
        startFetch: startFetchEnglish,
        // text: englishSummary,
        status: englishStatus,
    } = useEventSource();
    const appendText = (elId, text) => {
        const el = document.getElementById(elId);
        if (el) {
            el.innerText = text;
        }
    };

    // 继续预览
    const { startFetch: startPreview } = useEventSource();

    const continuePreview = () => {
        searchResultStore.nextPreviewLoading = true;
        startPreview(
            `${baseURL}/ai/ai/s/paper/w`,
            JSON.stringify({ uid: searchResultStore.query.id }),
            (...params) => {
                const [word, index] = params;
                previewContent.current += word;
                appendText('pContent', previewContent.current);
            },
            () => {
                searchResultStore.hasPreviewEnd = true;
                searchResultStore.nextPreviewLoading = false;
                finallyPreviewEnd.current = true;
            },
        );
    };

    useEffect(() => {
        startFetch(
            `${baseURL}/ai/ai/s/paper/gen/c/abstract`,
            JSON.stringify({ uid: searchResultStore.query.id }),
            (...params) => {
                const [word, index] = params;
                chineseSummary.current += word;
                appendText('cContent', chineseSummary.current);
            },
            () => {
                setTimeout(() => {
                    startFetchEnglish(
                        `${baseURL}/ai/ai/s/paper/gen/e/abstract`,
                        JSON.stringify({ uid: searchResultStore.query.id }),
                        (...params) => {
                            const [word, index] = params;
                            englishSummary.current += word;
                            appendText('eContent', englishSummary.current);
                        },
                        () => {
                            summaryPreviewEnd.current = true;
                        },
                    );
                }, 2000);
            },
        );
    }, []);

    const submitPay = async () => {
        try {
            if (loading) {
                return;
            }

            setLoading(true);
            await post('/ai/ai/s/paper/use/full', {
                uid: searchResultStore.query.id,
            });
            setLoading(false);
            Modal.success({
                className: styles.successModal,
                content: '论文生成中，请稍后在我的创作-全文写作中查看论文',
                onOk() {
                    nav('/dashboard/myDocuments');
                },
            });
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // function getHtmlContent(text) {
    //   // 匹配 \textsuperscript{[数字]} 的模式
    //   const regex = /\\ccccc\{\[(\d+)\]\}/g;
    //   // 使用 \$1 来引用第一个括号中捕获的内容（即数字），并在替换文本中保留方括号
    //   return text.replace(regex, "<span>[$1]</span>");
    // }

    const [updateLoading, setUpdateLoading] = useState(false);

    const updateOutlineCallback = useCallback(async () => {
        setUpdateLoading(true);
        await searchResultStore.updateOutline();
        closeDrawer();
        message.success('修改成功');
        setUpdateLoading(false);
    }, []);

    return (
        <div className={styles.result}>
            <div className={styles.resultPage}>
                <div>
                    <div className={styles.chapterTitle}>中文摘要</div>
                    <div className={styles.content} id="cContent"></div>
                </div>
                {englishSummary.current && englishSummary.current.length ? (
                    <div>
                        <div className={styles.chapterTitle}>英文摘要</div>
                        <div id="eContent" className={styles.content}></div>
                    </div>
                ) : null}
                {summaryPreviewEnd.current || searchResultStore.nextPreviewLoading ? null : (
                    <Spin tip="生成中" style={{ margin: '0 auto' }}>
                        <div style={{ height: '160px', width: '100%' }}></div>
                    </Spin>
                )}

                <div>
                    {/* <div className={styles.chapterTitle}>{item.chapter}</div>
            <div className={styles.section}>{item.section}</div>
            <div className={styles.subHeading}>{item.subheading}</div> */}
                    {summaryPreviewEnd.current && searchResultStore.outlines[0] && (
                        <>
                            <div className={styles.chapterTitle}>
                                {searchResultStore.outlines[0].chapter}
                            </div>
                            {searchResultStore.outlines[0].sections &&
                                searchResultStore.outlines[0].sections[0] && (
                                    <div className={styles.section}>
                                        {searchResultStore.outlines[0].sections[0].chapter}
                                    </div>
                                )}
                        </>
                    )}
                    <div id="pContent" className={styles.content}></div>
                </div>

                <div className={styles.payButtonBox}>
                    <div className={styles.payMask}></div>

                    <div>
                        {summaryPreviewEnd.current && (
                            <div className="flex" style={{ flexDirection: 'column' }}>
                                <div className="flex justify-center">
                                    {searchResultStore.hasPreviewEnd ? null : (
                                        <Button
                                            loading={searchResultStore.nextPreviewLoading}
                                            size="large"
                                            shape="round"
                                            onClick={() => continuePreview()}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: '10px',
                                                width: '160px',
                                                height: '50px',
                                                color: '#F7941D',
                                                borderColor: '#F7941D',
                                            }}
                                            disabled={!summaryPreviewEnd.current}
                                        >
                                            继续预览
                                        </Button>
                                    )}

                                    <Button
                                        type="primary"
                                        loading={loading}
                                        size="large"
                                        shape="round"
                                        onClick={submitPay}
                                        style={{
                                            height: '50px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '160px',
                                        }}
                                        disabled={!summaryPreviewEnd.current}
                                    >
                                        <div>解锁全篇</div>
                                    </Button>
                                    {searchResultStore.query.docType !== DOCTYPEENUM.PAPER && (
                                        <Button
                                            onClick={showDrawer}
                                            style={{
                                                height: '50px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '160px',
                                                marginLeft: '10px',
                                            }}
                                            shape="round"
                                            size="large"
                                        >
                                            修改图表
                                        </Button>
                                    )}
                                </div>
                                <div
                                    className={styles.xiaohao}
                                    dangerouslySetInnerHTML={{
                                        __html: searchResultStore.yujiXiaohao?.str,
                                    }}
                                ></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Drawer
                title="修改大纲"
                rootClassName="summary-drawer"
                onClose={closeDrawer}
                open={drawerVisible}
                width={800}
                footer={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            lineHeight: 1,
                            height: 'auto',
                        }}
                    >
                        <Button onClick={closeDrawer}>取消</Button>
                        <Button
                            type="primary"
                            style={{ marginLeft: '20px' }}
                            onClick={updateOutlineCallback}
                            loading={updateLoading}
                        >
                            确定
                        </Button>
                    </div>
                }
            >
                <Summary from="drawer" />
            </Drawer>
        </div>
    );
});
