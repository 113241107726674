import { Modal } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import highLevel from './assets/high-level.png';

const ImageOption = ({ isAdvanced, selected, title, description, price, onClick }) => (
    <div
        onClick={onClick}
        className={classNames(
            'p-6 mb-4 rounded-xl cursor-pointer transition-all',
            'border-2 hover:border-primary',
            selected
                ? 'bg-[rgba(247,148,29,0.1)] border-primary'
                : 'bg-[#F8F9FB] border-transparent',
        )}
    >
        <div className="flex items-center justify-between">
            <div>
                <div className={'flex items-center'}>
                    <span className="text-lg font-medium">{title}</span>
                    {isAdvanced && <img src={highLevel} className={'w-10 h-5 ml-2'} alt="" />}
                </div>
                <div className="text-gray-500 mt-1 text-sm">{description}</div>
            </div>
            <div
                className={classNames(
                    'w-6 h-6 rounded-full border-2 flex items-center justify-center',
                    selected ? 'border-primary' : 'border-gray-300',
                )}
            >
                {selected && <div className="w-3 h-3 rounded-full bg-primary" />}
            </div>
        </div>
        {price && <div className="text-primary text-sm mt-2">需消耗 {price}</div>}
    </div>
);

const AIImageModal = ({ visible, onClose, onConfirm }) => {
    const [selected, setSelected] = useState('no');

    const options = [
        {
            key: 'no',
            title: '无需AI配图',
            description: '所有内容均为文本排版，无AI图片',
        },
        {
            key: 'normal',
            title: '普通AI配图',
            description: '少量AI图文排版，内容更美观，排版更灵活',
        },
        {
            key: 'advanced',
            title: '高级AI配图',
            description: '丰富的AI图文排版，图片质量更高，生成速度更快',
            // price: '19.9¥',
            // isAdvanced: true,
        },
    ];

    return (
        <Modal
            title="AI配图"
            open={visible}
            onCancel={onClose}
            onOk={() => {
                onConfirm(selected);
            }}
            width={560}
            centered
            destroyOnClose
            maskClosable={false}
        >
            <div className="py-4">
                {options.map((option) => (
                    <ImageOption
                        key={option.key}
                        selected={selected === option.key}
                        title={option.title}
                        description={option.description}
                        price={option.price}
                        isAdvanced={option.isAdvanced}
                        onClick={() => setSelected(option.key)}
                    />
                ))}
            </div>
        </Modal>
    );
};

export default AIImageModal;
