import styles from './result.module.scss';
import { Button, message, Spin } from 'antd';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { get, post } from '../../axios/axios';
import { newPPTStore } from './newStore';
import { handleDownLoad } from '../../Service/utils';
import { useSearchParams } from 'react-router-dom';
let intervalId;

export function PPTResult({ uid, hs }) {
    const [qs] = useSearchParams();

    const [templateBg, setTemplateBg] = useState(qs.get('coverImg'));
    const [pptTitle, setPptTitle] = useState(qs.get('pptTitle'));
    const [pptSubTitle, setPptSubTitle] = useState(qs.get('pptSubTitle'));
    const [loading, setLoading] = useState(false);
    const [pptResult, setPPTResult] = useState(null);
    const [isGenerating, setIsGenerating] = useState(true); // 改名更直观，true 表示正在生成

    useEffect(() => {
        queryStatusInterval();
        checkPPTStatus(); // 添加检查PPT状态
        return () => {
            window.clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const coverImg = params.get('coverImg');
        if (coverImg) {
            setTemplateBg(coverImg);
        }
        const pptTitle = params.get('pptTitle');
        if (pptTitle) {
            setPptTitle(pptTitle);
        }
        const pptSubTitle = params.get('pptSubTitle');
        if (pptSubTitle) {
            setPptSubTitle(pptSubTitle);
        }
    }, [window.location]);

    // 检查PPT状态
    const checkPPTStatus = async () => {
        try {
            const { data = {} } = await get(`/ai/ai/s/ppt/result`, { uid });
            // 如果有 url，说明已经生成完成
            setIsGenerating(!data.url); // 有 url 则不在生成中
        } catch (error) {
            console.error('检查PPT状态失败:', error);
            setIsGenerating(false); // 出错时停止生成状态
        }
    };

    function onClickDownload() {
        queryPPTResult();
    }

    const queryPPTResult = async () => {
        try {
            setLoading(true);
            const { data = {}, msg } = await get(`/ai/ai/s/ppt/result`, { uid });
            setPPTResult(data);
            if (data.url) {
                message.success('开始下载');
                const filename = data.url.split('filename=')[1];
                await handleDownLoad(data.url, filename)
                    .then(() => {
                        message.success('下载成功');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                return;
            }

            if (!data.url) {
                message.info('正在加速生成中，预计需要3分钟');
            }
        } catch (error) {
            console.error('获取PPT结果失败:', error);
            message.error('下载失败，请稍后重试');
        } finally {
            setLoading(false);
        }
    };

    const queryStatusInterval = async () => {
        if (hs || newPPTStore.hs) {
            intervalId = window.setInterval(async () => {
                try {
                    const { data = {} } = await get(`/ai/ai/s/ppt/result`, {
                        uid,
                    });
                    if (data.url) {
                        window.clearInterval(intervalId);
                        message.success('PPT生成完成， 请点击下载');
                        setIsGenerating(false); // PPT已生成完成
                    }
                } catch (error) {
                    console.error('轮询状态失败:', error);
                    window.clearInterval(intervalId);
                    setIsGenerating(false);
                }
            }, 1000);
        }
    };

    return (
        <div className={styles.result}>
            <div className={styles.resultPage}>
                <div className={styles.pageTitle}>PPT预览</div>

                <div className={styles.pptPreview}>
                    <div className={styles.pptPreviewContent}>
                        {/*<div className={styles.pptTitle}>{pptTitle || '主标题'}</div>*/}
                        {/*<div className={styles.pptSubTitle}>{pptSubTitle || '副标题'}</div>*/}
                        {/*<div className={styles.pptAuthor}>作者：墨问题</div>*/}
                    </div>
                    <img src={templateBg} className={styles.pptImg} />
                </div>

                <div className={styles.pay}>
                    <div className={styles.hideTop}></div>
                    <div className={styles.payContent}>
                        <div className={styles.payResultBtnList}>
                            <Button
                                loading={isGenerating || loading}
                                type="primary"
                                onClick={onClickDownload}
                                className={styles.payBtn}
                            >
                                {isGenerating ? '正在生成中...' : '下载PPT'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
