import styles from "./NavRight.module.scss";
import { Button, Dropdown, Popover } from "antd";
import classNames from "classnames";
import Icon1 from "../assets/aboutIcon.png";
import Icon2 from "../assets/helpIcon.png";
import Icon3 from "../assets/avatarIcon.png";
import { useNavigate } from "react-router-dom";
import { About } from "../components/About";
import { loginInstance } from "../Service/login";
import { RightOutlined } from "@ant-design/icons";
import memberPic from "../assets/user/member.png";
import {
  ChuangzuoIcon,
  DingdanIcon,
  LogoutIcon,
  ShiyongIcon,
  ZhanghaoIcon,
} from "../common/const";
import { action } from "mobx";
import chongzhi from "../assets/chongzhi.png";
import chuangzuo from "../assets/chuangzuo.png";

export const NavRight = () => {
  const nav = useNavigate();
  const { userInfo } = loginInstance;
  const userNames = userInfo.user_info || {};

  const list = [
    {
      title: "我的创作",
      key: "chuangzuo",
      icon: ChuangzuoIcon,
      action: () => {
        nav("/dashboard/myDocuments");
      },
    },
    {
      title: "账号设置",
      key: "account",
      icon: ZhanghaoIcon,
      action: () => {
        nav("/dashboard/userCenter?tab=account");
      },
    },
    {
      title: "订单记录",
      icon: DingdanIcon,
      key: "order",
      action: () => {
        nav("/dashboard/userCenter?tab=order");
      },
    },
    {
      title: "使用记录",
      icon: ShiyongIcon,
      key: "use",
      action: () => {
        nav("/dashboard/userCenter?tab=record");
      },
    },
    {
      title: "退出登录",
      icon: LogoutIcon,
      key: "logout",
      action: () => {
        window.localStorage.clear();
        window.location.reload();
      },
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <div>
          <div
            className={styles.menuInfoRow}
            onClick={() => nav("/dashboard/userCenter")}
          >
            <img className={classNames(styles.menuAvatarIcon)} src={Icon3} />
            <div className={styles.avatarRight}>
              <div className={styles.username}>{userNames.name || "小墨"}</div>
            </div>
            <RightOutlined style={{ color: "#BCC3D2" }} />
          </div>
          <div className={styles.memberDot}>
            当前可用墨点数
            <span style={{ color: "#F7941D", fontSize: "14px" }}>
              {userNames.inkDotAvailable}
            </span>
          </div>
          
          <div className={styles.memberBox} >
            <div className={styles.memberTitle}>
              <img className={styles.memberPic} src={memberPic} />
              <div className={styles.memberBtn}>查看权益</div>
            </div>
            <div className={styles.memberDesc}>
              会员到期时间 {userNames.vipExpireDate?.split(" ")[0]}
            </div>
          </div>
        </div>
      ),
    },
    ...list.map((item, index) => ({
      key: item.key,
      label: (
        <div className={styles.linkRow} onClick={item.action}>
          {item.icon}
          <span className={styles.linkName}>{item.title}</span>
        </div>
      ),
    })),
  ];

  return (
    <div className={styles.NavRight}>
      {false && (
        <Popover placement="bottomRight" content={About} trigger="click">
          <img className={classNames(styles.icon)} src={Icon1} />
        </Popover>
      )}
      {false && (
        <img
          className={classNames(styles.icon)}
          src={Icon2}
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = "https://xiezuomowenti.com/help/c.html";
          }}
        />
      )}

      <div className={styles.navBtn}>
        <img src={chongzhi} />
        <div className={styles.text}>充值中心</div>
      </div>

      <div
        className={styles.navBtn}
        onClick={() => nav("/dashboard/myDocuments")}
      >
        <img src={chuangzuo} />
        <div className={styles.text}>我的创作</div>
      </div>

      {false && (
        <Button
          onClick={() => nav("/dashboard/myDocuments")}
          className={styles.btn}
          style={{ marginRight: "12px" }}
        >
          我的创作
        </Button>
      )}
      {false && (
        <Button  className={styles.btn}>
          充值中心
        </Button>
      )}
      <Dropdown
        overlayClassName="userdropdown"
        menu={{ items }}
        placement="bottomRight"
      >
        <img
          className={classNames(styles.icon, styles.avatarIcon)}
          src={Icon3}
          onClick={() => nav("/dashboard/userCenter")}
        />
      </Dropdown>
      <div className={styles.modian}>
        <div className={styles.count}>{userNames.inkDotAvailable}</div>
        <div className={styles.tip}>剩余墨点</div>
      </div>
    </div>
  );
};
