import React, { useState, useEffect, useMemo } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, Input, Button, List, Row, Col, Empty, Badge, message, Spin, Checkbox } from 'antd';
import docEmpty from '../../../assets/doc-empty.png';
import { useRequest } from 'ahooks';
import { RedoOutlined } from '@ant-design/icons';
import LiteratureItem from './LiteratureItem';
import SelectedLiteratureItem from './SelectedLiteratureItem';
import { post } from '../../../axios/axios';
import { ConfigProvider } from 'antd';

const { Search } = Input;

const searchLiterature = async (keyword) => {
    try {
        const response = await post('/ai/article/search', {
            query: keyword,
        });

        if (response && response.data) {
            return response.data;
        }
        return [];
    } catch (error) {
        console.error('搜索失败:', error);
        message.error('搜索失败，请稍后重试');
        return [];
    }
};

const LiteratureSearchModal = NiceModal.create(({ initialSearchValue }) => {
    const modal = useModal();
    const [searchTerm, setSearchTerm] = useState(initialSearchValue);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showSelected, setShowSelected] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const {
        data: searchResults = [],
        run: runSearch,
        loading,
        cancel,
    } = useRequest((keyword) => searchLiterature(keyword), {
        manual: true,
    });

    const { isAllSelected, isIndeterminate } = useMemo(() => {
        if (searchResults.length === 0) {
            return { isAllSelected: false, isIndeterminate: false };
        }

        const selectedCount = searchResults.reduce(
            (count, item) =>
                selectedItems.some((selected) => selected.id === item.id) ? count + 1 : count,
            0,
        );

        return {
            isAllSelected: selectedCount === searchResults.length,
            isIndeterminate: selectedCount > 0 && selectedCount < searchResults.length,
        };
    }, [searchResults, selectedItems]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const newSelectedItems = [...selectedItems];
            searchResults.forEach((item) => {
                if (!selectedItems.some((selected) => selected.id === item.id)) {
                    newSelectedItems.push(item);
                }
            });
            setSelectedItems(newSelectedItems);
        } else {
            const filteredItems = selectedItems.filter(
                (selected) => !searchResults.some((item) => item.id === selected.id),
            );
            setSelectedItems(filteredItems);
        }
    };

    useEffect(() => {
        if (modal.visible) {
            setSelectedItems([]);
            setShowSelected(false);
            cancel();
            if (initialSearchValue) {
                handleSearch(initialSearchValue);
            }
        }
    }, [modal.visible]);

    const handleSearch = (value) => {
        setSearchTerm(value);
        runSearch(value);
    };

    const handleReset = () => {
        setSearchTerm('');
        setSelectedItems([]);
        setShowSelected(false);
    };

    const handleSelect = (item) => {
        if (!selectedItems.find((selected) => selected.id === item.id)) {
            setSelectedItems([...selectedItems, item]);
        } else {
            setSelectedItems(selectedItems.filter((selected) => selected.id !== item.id));
        }
    };

    const handleRemove = (item) => {
        setSelectedItems(selectedItems.filter((selected) => selected.id !== item.id));
    };

    const handleOk = () => {
        modal.resolve(selectedItems);
        modal.hide();
    };

    const showBackgroundImage = !showSelected && !selectedItems.length && !searchResults.length;

    return (
        <Modal
            title="文献库"
            open={modal.visible}
            onOk={handleOk}
            onCancel={modal.hide}
            width={800}
            maskAnimation={false}
            confirmLoading={loading}
            footer={
                showSelected
                    ? [
                          <Button key={'useDoc'} type={'default'} onClick={modal.hide}>
                              使用文献
                          </Button>,
                          <Button
                              key="continueAdd"
                              type="primary"
                              onClick={() => setShowSelected(!showSelected)}
                          >
                              继续添加
                          </Button>,
                      ]
                    : [
                          <Button key="cancel" onClick={modal.hide}>
                              取消
                          </Button>,
                          <Button key="ok" type="primary" onClick={handleOk} loading={loading}>
                              使用
                          </Button>,
                      ]
            }
        >
            {
                <>
                    {!showSelected && (
                        <>
                            <Row gutter={16} style={{ marginBottom: 16 }}>
                                <Col span={16} md={20}>
                                    <Search
                                        placeholder="输入文献关键词进行搜索查询"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onSearch={handleSearch}
                                        enterButton={'搜索文献'}
                                        loading={loading}
                                    />
                                </Col>
                                <Col span={8} md={4}>
                                    <div
                                        onClick={handleReset}
                                        className={
                                            'flex cursor-pointer justify-center gap-1.5 rounded-md bg-[#F9FBFF] px-3 py-1.5 text-[#414C6F]'
                                        }
                                    >
                                        <RedoOutlined />
                                        <span>重置</span>
                                    </div>
                                </Col>
                            </Row>
                            <Spin spinning={loading}>
                                <List
                                    style={{
                                        height: '600px',
                                        overflow: 'auto',
                                        scrollbarWidth: 'thin',
                                    }}
                                    dataSource={searchResults}
                                    renderItem={(item) => (
                                        <LiteratureItem
                                            item={item}
                                            onSelect={handleSelect}
                                            selected={selectedItems.some(
                                                (selected) => selected.id === item.id,
                                            )}
                                        />
                                    )}
                                    header={
                                        <div className="flex w-full items-center justify-between px-4">
                                            <div>搜索结果</div>
                                            <div>
                                                <ConfigProvider
                                                    theme={{
                                                        token: {
                                                            borderRadiusSM: 50,
                                                        },
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={isAllSelected}
                                                        indeterminate={isIndeterminate}
                                                        onChange={handleSelectAll}
                                                        disabled={searchResults.length === 0}
                                                    >
                                                        全选
                                                    </Checkbox>
                                                </ConfigProvider>
                                            </div>
                                        </div>
                                    }
                                    locale={{
                                        emptyText: showBackgroundImage ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '300px',
                                                }}
                                            >
                                                <img
                                                    src={docEmpty}
                                                    alt="搜索文献"
                                                    style={{
                                                        width: '200px',
                                                        marginBottom: '20px',
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <Empty description="无搜索结果" />
                                        ),
                                    }}
                                />
                            </Spin>
                            <div
                                className={
                                    'absolute bottom-4 left-6 flex h-10 w-20 cursor-pointer items-center justify-center rounded-md bg-[#F8FBFF]'
                                }
                                onClick={() => setShowSelected(!showSelected)}
                            >
                                <Badge count={selectedItems?.length} showZero offset={[16, -2]}>
                                    <div className={'text-[#8C93B1]'}>查看文献</div>
                                </Badge>
                            </div>
                        </>
                    )}
                    {showSelected && (
                        <>
                            <List
                                header={<div>已选文献({selectedItems.length})篇</div>}
                                dataSource={selectedItems}
                                style={{
                                    height: '600px',
                                    overflow: 'auto',
                                    // 展示滚动条
                                    scrollbarWidth: 'thin',
                                }}
                                renderItem={(item) => (
                                    <SelectedLiteratureItem
                                        item={item}
                                        onClose={() => {
                                            handleRemove(item);
                                        }}
                                    />
                                )}
                                locale={{
                                    emptyText: <Empty description="暂未选择文献" />,
                                }}
                            />
                        </>
                    )}
                </>
            }
        </Modal>
    );
});

export default LiteratureSearchModal;
