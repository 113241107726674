import NiceModal from '@ebay/nice-modal-react';
import styles from './PPTpayModal.module.scss';
import { Modal, Button, Alert, QRCode, Radio } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import error from '../../assets/error.png';
import success from '../../assets/success.png';
import { globalData } from '../../Service/utils';
import { get, post } from '../../axios/axios';
import useCountdown from '@bradgarropy/use-countdown';
import { useGetState } from 'ahooks';
import RedeemModal from '../Documents/Step3/components/RedeemModal';

const PPTpayModal = NiceModal.create(({ title = '生成PPT', orderNo, majorName, price }) => {
    const modal = useModal();
    const [timerId, setTimerId] = useState(null);
    const [payResult, setPayResult] = useState(false);
    const [payInfo, setPayInfo, getPayInfo] = useGetState({});
    const [showPayResult, setShowPayResult] = useState(false);
    const [qrCodeStatus, setQrCodeStatus] = useState('loading');
    const [checking, setChecking] = useState(false);
    const isPollingRef = useRef(false); // 使用 ref 来追踪轮询状态
    const [payType, setPayType] = useState(1); // 1:微信 2:支付宝
    const [alipayUrl, setAlipayUrl] = useState('');

    const useRedeemModal = useModal(RedeemModal);

    const countdown = useCountdown({
        minutes: 0,
        seconds: 30 * 60,
        format: 'mm:ss',
        autoStart: false,
        onCompleted: () => console.log('onCompleted'),
    });

    // 停止轮询的统一方法
    const stopPolling = () => {
        isPollingRef.current = false;
        if (timerId) {
            window.clearTimeout(timerId);
            setTimerId(null);
        }
    };

    const onCancel = () => {
        stopPolling();
        setPayInfo({});
        setShowPayResult(false);
        setPayType(1); // 关闭时重置为微信支付
        setAlipayUrl(''); // 清除支付宝URL
        modal.hide();
        globalData.destroy = 1;
    };

    const toRePay = async () => {
        try {
            setShowPayResult(false);
            setQrCodeStatus('loading');
            const res = await post('/member/payment/pay', {
                orderNo: orderNo,
                client: 'PC',
            });
            if (res.data?.codeUrl) {
                setPayInfo(res.data);
                setQrCodeStatus('active');
            } else {
                setQrCodeStatus('expired');
            }
        } catch (error) {
            console.error('支付请求失败:', error);
            setQrCodeStatus('expired');
        }
    };

    const checkPay = async () => {
        if (checking) return;

        try {
            setChecking(true);
            if (!orderNo) {
                console.error('订单号不存在');
                return;
            }

            stopPolling(); // 检查支付时暂停轮询

            const res = await get('/member/payment/pay/result/' + orderNo);
            if (res?.data?.code === 0) {
                setShowPayResult(true);
                setPayResult(true);
                setTimeout(() => {
                    modal.resolve(res.data);
                    onCancel();
                }, 1000);
            } else {
                startPolling(); // 如果未支付成功，重新开始轮询
            }
        } catch (err) {
            console.error('支付检查失败:', err);
            startPolling();
        } finally {
            setChecking(false);
        }
    };

    // 我有兑换码弹窗
    const showCodeModal = async (e) => {
        e.stopPropagation();
        await useRedeemModal
            .show({
                orderNo: orderNo,
            })
            .then((resData) => {
                setShowPayResult(true);
                setPayResult(true);
                setTimeout(() => {
                    modal.resolve(resData);
                    onCancel();
                }, 1000);
            })
            .catch(() => {
                startPolling();
            });
    };

    const startPolling = (latestPayInfo) => {
        // 使用传入的最新数据，而不是通过 getPayInfo 获取
        const payInfoToUse = latestPayInfo || getPayInfo();

        if (!payInfoToUse.codeUrl || globalData.destroy) {
            stopPolling();
            return;
        }

        if (timerId) {
            window.clearTimeout(timerId);
            setTimerId(null);
        }

        isPollingRef.current = true;
        const id = window.setTimeout(() => {
            if (isPollingRef.current && payInfoToUse.codeUrl) {
                checkPayStatus();
            }
        }, 1000);
        setTimerId(id);
    };

    const checkPayStatus = async () => {
        try {
            if (!orderNo || !isPollingRef.current) {
                console.error('订单号不存在或已停止轮询');
                stopPolling();
                return;
            }

            const res = await get('/member/payment/pay/result/' + orderNo);
            if (res?.data?.code === 0) {
                stopPolling();
                setShowPayResult(true);
                setPayResult(true);
                setTimeout(() => {
                    modal.resolve(res.data);
                    onCancel();
                }, 1000);
            } else if (isPollingRef.current) {
                startPolling();
            }
        } catch (err) {
            console.error('支付检查失败:', err);
            stopPolling();
            setShowPayResult(true);
            setPayResult(false);
        }
    };

    const handlePayTypeChange = async (e) => {
        const newPayType = e.target.value;
        setPayType(newPayType);
        setQrCodeStatus('loading');
        try {
            const res = await post('/member/payment/pay', {
                orderNo: orderNo,
                client: 'PC',
                payType: newPayType, // 传递支付方式到后端
            });
            if (res.data?.codeUrl && newPayType === 1) {
                setPayInfo(res.data);
                setQrCodeStatus('active');
            } else if (res.data?.form && newPayType === 2) {
                setAlipayUrl(res.data.form);
            }
        } catch (error) {
            console.error('支付请求失败:', error);
            setQrCodeStatus('expired');
        }
    };

    useEffect(() => {
        let mounted = true;

        const initPayment = async () => {
            if (modal.visible && mounted) {
                try {
                    setPayType(1); // 每次打开时重置为微信支付
                    globalData.destroy = 0;
                    setQrCodeStatus('loading');
                    const res = await post('/member/payment/pay', {
                        orderNo: orderNo,
                        client: 'PC',
                        payType: 1, // 强制使用微信支付
                    });

                    if (!mounted) return;

                    if (res.data.codeUrl) {
                        setPayInfo(res.data);
                        setQrCodeStatus('active');
                        countdown.start();
                        startPolling(res.data);
                    }
                } catch (error) {
                    console.error('初始化支付失败:', error);
                    if (mounted) {
                        setQrCodeStatus('expired');
                    }
                }
            }
        };

        initPayment();

        return () => {
            mounted = false;
            countdown.reset();
            stopPolling();
            setPayType(1); // 组件卸载时也重置为微信支付
            setAlipayUrl('');
        };
    }, [modal.visible]);

    const getVirtualOriginalPrice = (price) => {
        return (price * 1.5).toFixed(2);
    };

    // Modal 的 JSX 部分保持不变
    return (
        <Modal
            title={title}
            open={modal.visible}
            width={500}
            footer={null}
            onCancel={onCancel}
            maskClosable={false}
            zIndex={1888}
        >
            {showPayResult ? (
                <div className={styles.payResult}>
                    <div
                        className={styles.payStatusBox}
                        style={payResult ? { padding: '16px 0 20px 0' } : {}}
                    >
                        <p className={styles.statusTitle}>
                            {payResult ? '恭喜您，支付成功' : '很遗憾，支付失败'}
                        </p>
                        <img
                            src={payResult ? success : error}
                            className={styles.statusImg}
                            style={payResult ? { margin: '16px 0 20px 0' } : {}}
                        />
                        {!payResult ? (
                            <Button className="action_btn" onClick={toRePay}>
                                <span>重新支付</span>
                            </Button>
                        ) : (
                            <Button onClick={onCancel}>
                                <span>完成</span>
                            </Button>
                        )}
                    </div>
                </div>
            ) : (
                <div style={{ margin: '30px 0 0 0' }}>
                    <Alert
                        message="付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮"
                        type="warning"
                        showIcon
                        className="w-pay-alert"
                    />
                    <div className={styles.payContent}>
                        <div className={styles.left}>
                            <div
                                className={styles.payTypeSwitch}
                                style={{ marginBottom: '20px', textAlign: 'center' }}
                            >
                                <Radio.Group onChange={handlePayTypeChange} value={payType}>
                                    <Radio.Button value={1}>微信支付</Radio.Button>
                                    <Radio.Button value={2}>支付宝</Radio.Button>
                                </Radio.Group>
                            </div>
                            <div className={styles.qrcode}>
                                {payType === 1 ? (
                                    payInfo.codeUrl ? (
                                        <QRCode
                                            value={payInfo.codeUrl}
                                            status={qrCodeStatus}
                                            onLoad={() => setQrCodeStatus('active')}
                                            onError={() => setQrCodeStatus('expired')}
                                            size={200}
                                            icon={null}
                                        />
                                    ) : (
                                        <div className={styles.loading}>正在加载二维码...</div>
                                    )
                                ) : null}
                                {payType === 2 ? (
                                    alipayUrl ? (
                                        <iframe
                                            style={{
                                                width: '170px',
                                                height: '170px',
                                                marginLeft: '10px',
                                                marginTop: '10px',
                                            }}
                                            src={alipayUrl}
                                            title="支付宝支付"
                                        ></iframe>
                                    ) : (
                                        <div className={styles.loading}>正在加载二维码...</div>
                                    )
                                ) : null}
                            </div>
                            <div>{payType === 1 ? '微信扫码支付' : '支付宝扫码支付'}</div>
                            <div style={{ color: '#999', fontSize: '12px' }}>
                                如遇支付问题，请联系客服微信
                            </div>
                            <p className="mt-2 w-[168px] text-xs text-[#999]">
                                虚拟产品，支付前请仔细确认，结果由AI根据您提供的信息自动生成，仅供参考，支付成功后立即生效，不支持退款。
                            </p>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.title}>{majorName}</div>
                            {countdown.formatted !== '00:00' ? (
                                <div className={styles.discount}>
                                    <div className={styles.line}>
                                        <div className={styles.lineLeft}></div>
                                        <div className={styles.lineRight}></div>
                                    </div>
                                    <div>
                                        限时特惠 距活动结束：
                                        <span className={styles.countdown}>
                                            {countdown.formatted}
                                        </span>
                                    </div>
                                </div>
                            ) : null}

                            <div className={styles.row}>
                                <label>金额：</label>
                                <div>
                                    <div>
                                        <span className={styles.price}>
                                            ￥{price}
                                            <span style={{ fontSize: '16px' }}>元</span>
                                        </span>
                                        <span className={styles.origin}>
                                            ￥{getVirtualOriginalPrice(price)}元
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.btnRow}>
                                <Button
                                    onClick={checkPay}
                                    className={styles.btn}
                                    type="primary"
                                    shape="round"
                                    loading={checking}
                                    disabled={checking}
                                >
                                    我已支付成功
                                </Button>
                            </div>
                            <div
                                style={{
                                    color: '#999',
                                    fontSize: '12px',
                                    marginTop: '8px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                }}
                                onClick={showCodeModal}
                            >
                                我有兑换码
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
});

export default PPTpayModal;
