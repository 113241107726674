import React, { useEffect, useCallback, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import CustomDrawer from './CustomDrawer';
import TextContainer from './TextContainer';
import { Modal, Spin } from 'antd';
import { post } from '../../../../axios/axios';

// 降重
const RepetitionModal = NiceModal.create(({ orderNo, content, from, to, chapterId }) => {
    const modal = useModal();
    const handleOk = () => {
        modal.resolve(replaceText(text));
        modal.hide();
    };
    const handleReset = () => {
        fetchData();
    };

    const replaceText = useCallback((text) => {
        return text.replace(/\[\[citation:(\d+)\]\]/g, (match, num) => `<sup>[${num}]</sup>`);
    }, []);

    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchData = useCallback(async () => {
        if (!orderNo || !chapterId || !content) return;
        try {
            setLoading(true);
            const res = await post('/ai/ai/paper/editor/contentReduction', {
                orderNo,
                chapterId,
                original: content,
            });
            console.log('res', res);
            setText(res.data);
        } catch (error) {
            console.error('Error fetching content reduction:', error);
        } finally {
            setLoading(false);
        }
    }, [orderNo, chapterId, content]); // 这些依赖是必要的

    useEffect(() => {
        if (!modal.visible) return;
        fetchData();
    }, [modal.visible, fetchData]);

    return (
        <CustomDrawer
            title="一键降重"
            visible={modal.visible}
            onClose={() => {
                modal.reject();
                modal.hide();
            }}
            okText="替换原文"
            resetText="重新生成"
            onOk={handleOk}
            onReset={handleReset}
        >
            <TextContainer type="default" title="原文" content={content} />
            {loading ? (
                <div className="flex h-32 items-center justify-center">
                    <Spin />
                </div>
            ) : (
                <div className="mt-4">
                    <TextContainer
                        type="active"
                        title="降重结果"
                        dangerouslySetInnerHTML={replaceText(text)}
                    />
                </div>
            )}
        </CustomDrawer>
    );
});

export default RepetitionModal;
