import { makeAutoObservable } from 'mobx';

class UnlockStore {
    // 标题
    title = '机械原理';
    // 章节数据
    chapters = [
        {
            chapter: '摘要',
            sections: null,
        },
        {
            chapter: 'ABSTRACT',
            sections: null,
        },
        {
            chapter: '一、引言',
            sections: [
                {
                    chapter: '1.1 研究背景',
                    subsections: [],
                    chapterAbstract:
                        '介绍机械原理在现代工业中的重要性，以及其对科技进步和生产效率的推动作用。',
                    extraType: '',
                    selected_extra: '',
                    extraTypeCn: null,
                },
                {
                    chapter: '1.2 研究意义',
                    subsections: [],
                    chapterAbstract:
                        '阐述研究机械原理对于提高机械设备性能、降低能耗、促进技术创新等方面的重要意义。',
                    extraType: '',
                    selected_extra: '',
                    extraTypeCn: null,
                },
                {
                    chapter: '1.3 研究目标与方法',
                    subsections: [],
                    chapterAbstract:
                        '明确本研究的目标，即深入探究机械原理的基本原理和应用，以及采用的研究方法和手段。',
                    extraType: '',
                    selected_extra: '',
                    extraTypeCn: null,
                },
            ],
        },
        {
            chapter: '二、机械原理基础知识概述',
            sections: [
                {
                    chapter: '2.1 机械系统的基本组成',
                    subsections: [],
                    chapterAbstract: '介绍机械系统的组成部分，包括构件、运动副和驱动力等基本元素。',
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    extraTypeCn: {},
                },
                {
                    chapter: '2.2 机械运动分析基础',
                    subsections: [],
                    chapterAbstract:
                        '详细阐述机械运动学的基本概念，如位移、速度、加速度以及它们的计算方法。',
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    extraTypeCn: {},
                },
                {
                    chapter: '2.3 力与力矩在机械中的应用',
                    subsections: [],
                    chapterAbstract:
                        '探讨力和力矩在机械系统中的作用，包括它们如何影响机械的运动状态和结构稳定性。',
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    extraTypeCn: {},
                },
            ],
        },
        {
            chapter: '三、机械传动的优化与创新',
            sections: [
                {
                    chapter: '3.1 传动效率的提升方法',
                    subsections: [
                        {
                            chapter:
                                '3.1.1 材料选择对传动效率的影响(探讨不同材料在机械传动中的应用及其对效率的影响。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter: '3.1.2 结构设计优化(分析如何通过改进机械结构来提高传动效率。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '3.1.3 润滑技术的应用(讨论润滑剂的选择和应用方式对提升传动效率的作用。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: '',
                            extraTypeCn: {},
                        },
                    ],
                    chapterAbstract: null,
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: 'NORMAL',
                    extraTypeCn: null,
                },
            ],
        },
        {
            chapter: '四、机构学基础与创新设计',
            sections: [
                {
                    chapter: '4.1 机构学的基本概念和原理',
                    subsections: [],
                    chapterAbstract:
                        '介绍机构学的定义，基本组成元素如杆件、铰链等，以及它们之间的连接方式。探讨机构的自由度计算方法，包括Gruebler公式的应用，以及如何通过改变机构参数来调整自由度。',
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    extraTypeCn: {
                        FORMULA: ' Gruebler公式(详细阐述其在自由度计算中的应用)',
                    },
                },
                {
                    chapter: '4.2 创新设计在机构学中的应用',
                    subsections: [],
                    chapterAbstract:
                        '分析创新设计对于提高机械系统性能的重要性，讨论如何将创新理念融入机构设计中。举例说明一些成功的创新设计方案，如可变形机构、自适应机构等。',
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    extraTypeCn: {},
                },
                {
                    chapter: '4.3 机构学的未来发展趋势和挑战',
                    subsections: [],
                    chapterAbstract:
                        '探讨机构学未来的发展方向，包括新材料、新技术的应用，以及智能化、自动化的趋势。同时，讨论当前机构学面临的主要挑战，如复杂系统的建模与分析、多学科交叉等问题。',
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    extraTypeCn: {},
                },
            ],
        },
        {
            chapter: '五、现代机械设计与分析方法的未来发展',
            sections: [
                {
                    chapter: '5.1 智能设计系统',
                    subsections: [
                        {
                            chapter:
                                '5.1.1 人工智能在机械设计中的应用(探讨如何利用AI技术优化设计方案，提高设计效率和质量。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '5.1.2 机器学习与大数据分析(分析大数据和机器学习技术如何辅助设计师进行决策，以及它们对未来设计趋势的影响。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '5.1.3 自适应与自我修复材料(研究新型智能材料的特性及其在机械设计中的潜在应用。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                    ],
                    chapterAbstract: null,
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: 'NORMAL',
                    extraTypeCn: null,
                },
                {
                    chapter: '5.2 可持续设计与绿色制造',
                    subsections: [
                        {
                            chapter:
                                '5.2.1 环境友好型材料选择(讨论如何选择可再生或可循环利用的材料以减少对环境的影响。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '5.2.2 能效分析与优化(描述如何通过改进设计来降低能耗并提高能源使用效率。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '5.2.3 生命周期评估(LCA)(介绍LCA方法在评价产品从生产到废弃全过程中的环境影响的重要性。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                    ],
                    chapterAbstract: null,
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: 'NORMAL',
                    extraTypeCn: null,
                },
                {
                    chapter: '5.3 数字化与虚拟仿真技术',
                    subsections: [
                        {
                            chapter:
                                '5.3.1 三维建模软件的进步(概述最新的3D建模工具如何帮助设计师更准确地预测产品性能。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '5.3.2 虚拟现实(VR)与增强现实(AR)技术(探索VR/AR技术在产品设计验证和用户体验方面的应用潜力。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '5.3.3 数字孪生概念(解释什么是数字孪生，并讨论它如何改变我们对机械设备维护和管理的方式。)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                    ],
                    chapterAbstract: null,
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: 'NORMAL',
                    extraTypeCn: null,
                },
            ],
        },
        {
            chapter: '六、结论与展望',
            sections: [
                {
                    chapter: '6.1 研究总结',
                    subsections: [
                        {
                            chapter:
                                '6.1.1 主要发现(总结论文的主要研究成果，包括理论贡献和实践意义)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter: '6.1.2 研究限制(分析研究过程中遇到的限制因素及其对结果的影响)',
                            chapterAbstract: null,
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: null,
                            extraTypeCn: null,
                        },
                        {
                            chapter:
                                '6.1.3 未来研究方向(基于当前研究的不足，提出未来可能的研究方向和改进措施)',
                            chapterAbstract:
                                '本节将总结整篇论文的核心发现，评估其在机械原理领域的理论和实际应用价值，同时指出研究过程中的局限性和未来的研究方向。',
                            extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                            selected_extra: '',
                            extraTypeCn: {},
                        },
                    ],
                    chapterAbstract: null,
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: 'NORMAL',
                    extraTypeCn: null,
                },
            ],
        },
        {
            chapter: '致谢',
            sections: null,
        },
        {
            chapter: '参考文献',
            sections: null,
        },
    ];
    constructor() {
        makeAutoObservable(this);
    }
    setChapters(chapters) {
        this.chapters = chapters;
    }
}

export const unlockStore = new UnlockStore();
