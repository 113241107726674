import zhCN from 'antd/locale/zh_CN';
import './App.css';
import { Documents } from './Article/Documents';
import { Example } from './Article/Example';

import { ConfigProvider } from 'antd';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { legacyLogicalPropertiesTransformer, StyleProvider } from '@ant-design/cssinjs';
import { QqOutlined, WechatOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { FloatButton, Popover } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { Article } from './Article';
import { Agreement } from './Article/Agreement';
import Editor from './Article/Documents/Editor/index';
import { summaryStore } from './Article/Documents/store';
import { ErrorBoundary } from './Article/ErrorBoundary/index';
import MyCreation from './Article/MyCreation';
import PPT from './Article/PPT';
import SearchPaper from './Article/SearchPaper';
import telIcon from './assets/tel.png';
import './common/common.scss';
import CssVariableSetter from './components/CssVariableSetter';
import CompanyIntro from './pages/companyIntro/index';
import ProductIntro from './pages/productIntro/index';
import { loginInstance } from './Service/login';
import { Reduce } from './Article/Reduce/index';
import ExchangeCode from './pages/ExchangeCode/index.jsx';
import UnlockAllChapter from './Article/Documents/UnlockAllChapter';
const router = createBrowserRouter([
    {
        path: '/',
        element: <Article />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '',
                element: <Documents />,
            },
            {
                path: 'example',
                element: <Example />,
            },
            {
                path: '/companyIntro',
                element: <CompanyIntro />,
                errorElement: <ErrorBoundary />,
            },
            { path: '/reduce', element: <Reduce /> },
            {
                path: '/productIntro',
                element: <ProductIntro />,
                errorElement: <ErrorBoundary />,
            },
            {
                path: '/editor',
                element: <Editor />,
                errorElement: <ErrorBoundary />,
            },
            {
                path: '/myCreation',
                element: <MyCreation />,
                errorElement: <ErrorBoundary />,
            },
            {
                path: 'search',
                children: [
                    { path: 'paper', element: <SearchPaper /> },
                    { path: 'ppt', element: <PPT /> },
                ],
            },
        ],
    },
    { path: '/agreement', element: <Agreement /> },
    { path: '/exchange', element: <ExchangeCode /> },
    { path: '/unlockAllChapter', element: <UnlockAllChapter /> },
]);

const App = observer(() => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);

    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const show = () => {
        if (!loginInstance.hasLogin) {
            loginInstance.open();
            return;
        }
        setOpen(true);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const theme = useMemo(() => {
        // Ultra 版本的主题
        if (summaryStore.useUltra) {
            return {
                cssVar: true,
                token: {
                    colorPrimary: '#f7941d',
                    colorInfoBg: '#fffbe6',
                    colorInfoActive: '#a277ee',
                    colorInfoText: '#a277ee',
                },
            };
        }
        // Normal 版本的主题
        return {
            cssVar: true,
            hashed: false,
            token: {
                colorPrimary: '#a277ee',
                colorInfoBg: '#fffbe6',
                colorInfoActive: '#a277ee',
                colorInfoText: '#a277ee',
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryStore.useUltra]);

    const CustomerServiceButton = () => {
        // 如果是编辑器页面，不展示
        if (window.location.pathname === '/editor') {
            return null;
        }
        return (
            <>
                <FloatButton.Group shape="square" style={{ bottom: 122 }}>
                    <Popover
                        title={
                            <div style={{ textAlign: 'center', fontSize: '16px' }}>
                                灵感风暴官方QQ群
                            </div>
                        }
                        placement="leftBottom"
                        content={
                            <img
                                src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbqq.png"
                                style={{ width: '180px', margin: '0 auto' }}
                            />
                        }
                    >
                        <FloatButton icon={<QqOutlined />} type="default" />
                    </Popover>
                    <Popover
                        title={
                            <div style={{ textAlign: 'center', fontSize: '16px' }}>关注公众号</div>
                        }
                        placement="leftBottom"
                        content={
                            <img
                                src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbgzh.png"
                                style={{ width: '180px', margin: '0 auto' }}
                            />
                        }
                    >
                        <FloatButton icon={<WechatOutlined />} type="default" />
                    </Popover>
                </FloatButton.Group>
                <Popover
                    title={<div style={{ textAlign: 'center', fontSize: '16px' }}>联系客服</div>}
                    placement="leftBottom"
                    content={
                        <img
                            src="https://modeai.oss-cn-hangzhou.aliyuncs.com/lgfbkf.png"
                            style={{ width: '180px', margin: '0 auto' }}
                        />
                    }
                >
                    <FloatButton
                        style={{ bottom: 70 }}
                        icon={<img src={telIcon} alt="电话" />}
                        type="primary"
                        shape="square"
                    />
                </Popover>
            </>
        );
    };

    return loaded ? (
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
            <ConfigProvider locale={zhCN} theme={theme}>
                <CssVariableSetter isGlobal>
                    <NiceModal.Provider>
                        <RouterProvider router={router} />
                        <CustomerServiceButton />
                    </NiceModal.Provider>
                </CssVariableSetter>
            </ConfigProvider>
        </StyleProvider>
    ) : (
        ''
    );
});

export default App;
