import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, message, Spin } from 'antd';
import React, { useState } from 'react';
import { post } from '../../../../axios/axios';
import { parseTable } from './AcademicEditor/AcademicEditorUtil';
import CustomDrawer from './CustomDrawer';
import InputContainer from './InputContainer';
import TextContainer from './TextContainer';
import './tableModal.scss';

const TableModal = NiceModal.create(({ orderNo, content, chapterId, from, to, id }) => {
    const modal = useModal();
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [newSrc, setNewSrc] = useState('');
    const [tableContent, setTableContent] = useState('');

    const handleOk = async () => {
        modal.resolve({
            content: newSrc || tableContent,
            from,
            to,
            id,
        });
        modal.hide();
    };

    const onPromptChange = (e) => {
        setPrompt(e.target.value);
    };

    const fetchData = async () => {
        if (!prompt) {
            message.warning('请输入提示词');
            return;
        }

        try {
            setLoading(true);
            const res = await post('/ai/ai/paper/editor/updateChapterTable', {
                orderNo,
                chapterId,
                prompt,
                tableContent: content,
            });
            if (res.code === 0) {
                setNewSrc(res.data);
                const _tableContent = res.data.replace(
                    /\[AIML_TABLE id=(.*?)\](.*?)\[\/AIML_TABLE\]/gs,
                    (_, id, table) => parseTable(table, id),
                );
                setTableContent(_tableContent);
                console.log('_tableContent', _tableContent);
            } else {
                message.error(res.message || '生成失败');
            }
        } catch (error) {
            console.error('Error fetching content reduction:', error);
            message.error('生成失败，请重试');
        } finally {
            setLoading(false);
        }
    };

    return (
        <CustomDrawer
            title="修改表格"
            visible={modal.visible}
            onClose={() => {
                modal.reject();
                modal.hide();
            }}
            okText="替换原文"
            resetText="重新生成"
            onOk={handleOk}
            onReset={fetchData}
            showFooter={!!newSrc}
        >
            <div className="flex flex-col gap-4">
                <InputContainer title="提示词" onChange={onPromptChange} value={prompt} />

                {!newSrc && (
                    <Button type="primary" onClick={fetchData} loading={loading} className="w-full">
                        生成表格
                    </Button>
                )}

                <div className="mt-4">
                    {loading ? (
                        <div className="flex h-12 w-full items-center justify-center">
                            <Spin />
                        </div>
                    ) : newSrc ? (
                        <TextContainer type="active" title="生成结果">
                            <div
                                className="content table-modal mt-4 rounded border p-4"
                                dangerouslySetInnerHTML={{ __html: tableContent }}
                            />
                        </TextContainer>
                    ) : null}
                </div>
            </div>
        </CustomDrawer>
    );
});

export default TableModal;
