import lunwen00 from '../../assets/样例/论文/论文_00.png';
import lunwen01 from '../../assets/样例/论文/论文_01.png';
import lunwen02 from '../../assets/样例/论文/论文_02.png';
import lunwen03 from '../../assets/样例/论文/论文_03.png';
import lunwen04 from '../../assets/样例/论文/论文_04.png';
import lunwen05 from '../../assets/样例/论文/论文_05.png';
import lunwen06 from '../../assets/样例/论文/论文_06.png';
import lunwen07 from '../../assets/样例/论文/论文_07.png';
import lunwen08 from '../../assets/样例/论文/论文_08.png';
import lunwen09 from '../../assets/样例/论文/论文_09.png';
import lunwen10 from '../../assets/样例/论文/论文_10.png';
import lunwen11 from '../../assets/样例/论文/论文_11.png';
import lunwen12 from '../../assets/样例/论文/论文_12.png';
import lunwen13 from '../../assets/样例/论文/论文_13.png';
import lunwen14 from '../../assets/样例/论文/论文_14.png';
import lunwen15 from '../../assets/样例/论文/论文_15.png';
import lunwen16 from '../../assets/样例/论文/论文_16.png';
import lunwen17 from '../../assets/样例/论文/论文_17.png';
import lunwen18 from '../../assets/样例/论文/论文_18.png';
import lunwen19 from '../../assets/样例/论文/论文_19.png';
import lunwen20 from '../../assets/样例/论文/论文_20.png';
import lunwen21 from '../../assets/样例/论文/论文_21.png';
import lunwen22 from '../../assets/样例/论文/论文_22.png';
import lunwen23 from '../../assets/样例/论文/论文_23.png';
import lunwen24 from '../../assets/样例/论文/论文_24.png';
import lunwen25 from '../../assets/样例/论文/论文_25.png';
import lunwen26 from '../../assets/样例/论文/论文_26.png';
import lunwen27 from '../../assets/样例/论文/论文_27.png';
import lunwen28 from '../../assets/样例/论文/论文_28.png';
import lunwen29 from '../../assets/样例/论文/论文_29.png';
import lunwen30 from '../../assets/样例/论文/论文_30.png';
import lunwen31 from '../../assets/样例/论文/论文_31.png';
import lunwen32 from '../../assets/样例/论文/论文_32.png';
import lunwen33 from '../../assets/样例/论文/论文_33.png';
import lunwen34 from '../../assets/样例/论文/论文_34.png';
import lunwen35 from '../../assets/样例/论文/论文_35.png';
import lunwen36 from '../../assets/样例/论文/论文_36.png';
import lunwen37 from '../../assets/样例/论文/论文_37.png';
import lunwen38 from '../../assets/样例/论文/论文_38.png';
import lunwen39 from '../../assets/样例/论文/论文_39.png';
import lunwen40 from '../../assets/样例/论文/论文_40.png';
import lunwen41 from '../../assets/样例/论文/论文_41.png';
import lunwen42 from '../../assets/样例/论文/论文_42.png';
import lunwen43 from '../../assets/样例/论文/论文_43.png';
import lunwen44 from '../../assets/样例/论文/论文_44.png';
import lunwen45 from '../../assets/样例/论文/论文_45.png';
import lunwen46 from '../../assets/样例/论文/论文_46.png';
import lunwen47 from '../../assets/样例/论文/论文_47.png';
import lunwen48 from '../../assets/样例/论文/论文_48.png';
import lunwen49 from '../../assets/样例/论文/论文_49.png';
import lunwen50 from '../../assets/样例/论文/论文_50.png';
import lunwen51 from '../../assets/样例/论文/论文_51.png';
import lunwen52 from '../../assets/样例/论文/论文_52.png';
import lunwen53 from '../../assets/样例/论文/论文_53.png';
import lunwen54 from '../../assets/样例/论文/论文_54.png';
import lunwen55 from '../../assets/样例/论文/论文_55.png';
import lunwen56 from '../../assets/样例/论文/论文_56.png';
import lunwen57 from '../../assets/样例/论文/论文_57.png';
// import lunwen58 from '../../assets/样例/论文/论文_58.png';
// import lunwen59 from '../../assets/样例/论文/论文_59.png';
// import lunwen60 from '../../assets/样例/论文/论文_60.png';
// import lunwen61 from '../../assets/样例/论文/论文_61.png';
// import lunwen62 from '../../assets/样例/论文/论文_62.png';
// import lunwen63 from '../../assets/样例/论文/论文_63.png';

import kaiti00 from '../../assets/样例/开题报告/开题报告_00.jpg';
import kaiti01 from '../../assets/样例/开题报告/开题报告_01.jpg';
import kaiti02 from '../../assets/样例/开题报告/开题报告_02.jpg';
import kaiti03 from '../../assets/样例/开题报告/开题报告_03.jpg';
import kaiti04 from '../../assets/样例/开题报告/开题报告_04.jpg';
import kaiti05 from '../../assets/样例/开题报告/开题报告_05.jpg';
import kaiti06 from '../../assets/样例/开题报告/开题报告_06.jpg';
import kaiti07 from '../../assets/样例/开题报告/开题报告_07.jpg';
import kaiti08 from '../../assets/样例/开题报告/开题报告_08.jpg';
import kaiti09 from '../../assets/样例/开题报告/开题报告_09.jpg';

import renwu00 from '../../assets/样例/任务书/任务书_00.jpg';
import renwu01 from '../../assets/样例/任务书/任务书_01.jpg';
import renwu02 from '../../assets/样例/任务书/任务书_02.jpg';
import renwu03 from '../../assets/样例/任务书/任务书_03.jpg';
import renwu04 from '../../assets/样例/任务书/任务书_04.jpg';
import renwu05 from '../../assets/样例/任务书/任务书_05.jpg';
import renwu06 from '../../assets/样例/任务书/任务书_06.jpg';
import renwu07 from '../../assets/样例/任务书/任务书_07.jpg';
import renwu08 from '../../assets/样例/任务书/任务书_08.jpg';

import ppt01 from '../../assets/样例/答辩PPT/PPT_01.jpg';
import ppt02 from '../../assets/样例/答辩PPT/PPT_02.jpg';
import ppt03 from '../../assets/样例/答辩PPT/PPT_03.jpg';
import ppt04 from '../../assets/样例/答辩PPT/PPT_04.jpg';
import ppt05 from '../../assets/样例/答辩PPT/PPT_05.jpg';
import ppt06 from '../../assets/样例/答辩PPT/PPT_06.jpg';
import ppt07 from '../../assets/样例/答辩PPT/PPT_07.jpg';
import ppt08 from '../../assets/样例/答辩PPT/PPT_08.jpg';
import ppt09 from '../../assets/样例/答辩PPT/PPT_09.jpg';
import ppt10 from '../../assets/样例/答辩PPT/PPT_10.jpg';
import ppt11 from '../../assets/样例/答辩PPT/PPT_11.jpg';
import ppt12 from '../../assets/样例/答辩PPT/PPT_12.jpg';
import ppt13 from '../../assets/样例/答辩PPT/PPT_13.jpg';
import ppt14 from '../../assets/样例/答辩PPT/PPT_14.jpg';
import ppt15 from '../../assets/样例/答辩PPT/PPT_15.jpg';
import ppt16 from '../../assets/样例/答辩PPT/PPT_16.jpg';
import ppt17 from '../../assets/样例/答辩PPT/PPT_17.jpg';
import ppt18 from '../../assets/样例/答辩PPT/PPT_18.jpg';
import ppt19 from '../../assets/样例/答辩PPT/PPT_19.jpg';
import ppt20 from '../../assets/样例/答辩PPT/PPT_20.jpg';
import ppt21 from '../../assets/样例/答辩PPT/PPT_21.jpg';
import ppt22 from '../../assets/样例/答辩PPT/PPT_22.jpg';
import ppt23 from '../../assets/样例/答辩PPT/PPT_23.jpg';
import ppt24 from '../../assets/样例/答辩PPT/PPT_24.jpg';
import ppt25 from '../../assets/样例/答辩PPT/PPT_25.jpg';
import ppt26 from '../../assets/样例/答辩PPT/PPT_26.jpg';
import ppt27 from '../../assets/样例/答辩PPT/PPT_27.jpg';
import ppt28 from '../../assets/样例/答辩PPT/PPT_28.jpg';
import ppt29 from '../../assets/样例/答辩PPT/PPT_29.jpg';
import ppt30 from '../../assets/样例/答辩PPT/PPT_30.jpg';
import ppt31 from '../../assets/样例/答辩PPT/PPT_31.jpg';
import ppt32 from '../../assets/样例/答辩PPT/PPT_32.jpg';
import ppt33 from '../../assets/样例/答辩PPT/PPT_33.jpg';
import ppt34 from '../../assets/样例/答辩PPT/PPT_34.jpg';
import ppt35 from '../../assets/样例/答辩PPT/PPT_35.jpg';
import ppt36 from '../../assets/样例/答辩PPT/PPT_36.jpg';
import ppt37 from '../../assets/样例/答辩PPT/PPT_37.jpg';
import ppt38 from '../../assets/样例/答辩PPT/PPT_38.jpg';
import ppt39 from '../../assets/样例/答辩PPT/PPT_39.jpg';
import ppt40 from '../../assets/样例/答辩PPT/PPT_40.jpg';
import ppt41 from '../../assets/样例/答辩PPT/PPT_41.jpg';
import ppt42 from '../../assets/样例/答辩PPT/PPT_42.jpg';
import ppt43 from '../../assets/样例/答辩PPT/PPT_43.jpg';
import ppt44 from '../../assets/样例/答辩PPT/PPT_44.jpg';
import ppt45 from '../../assets/样例/答辩PPT/PPT_45.jpg';
import ppt46 from '../../assets/样例/答辩PPT/PPT_46.jpg';
import ppt47 from '../../assets/样例/答辩PPT/PPT_47.jpg';
import ppt48 from '../../assets/样例/答辩PPT/PPT_48.jpg';
import ppt49 from '../../assets/样例/答辩PPT/PPT_49.jpg';
import ppt50 from '../../assets/样例/答辩PPT/PPT_50.jpg';
import ppt51 from '../../assets/样例/答辩PPT/PPT_51.jpg';
import ppt52 from '../../assets/样例/答辩PPT/PPT_52.jpg';
import ppt53 from '../../assets/样例/答辩PPT/PPT_53.jpg';
import ppt54 from '../../assets/样例/答辩PPT/PPT_54.jpg';

import wenjuan01 from '../../assets/样例/调查问卷/调查问卷_01.jpg';
import wenjuan02 from '../../assets/样例/调查问卷/调查问卷_02.jpg';
import wenjuan03 from '../../assets/样例/调查问卷/调查问卷_03.jpg';
export const lunwenList = [
    lunwen00,
    lunwen01,
    lunwen02,
    lunwen03,
    lunwen04,
    lunwen05,
    lunwen06,
    lunwen07,
    lunwen08,
    lunwen09,
    lunwen10,
    lunwen11,
    lunwen12,
    lunwen13,
    lunwen14,
    lunwen15,
    lunwen16,
    lunwen17,
    lunwen18,
    lunwen19,
    lunwen20,
    lunwen21,
    lunwen22,
    lunwen23,
    lunwen24,
    lunwen25,
    lunwen26,
    lunwen27,
    lunwen28,
    lunwen29,
    lunwen30,
    lunwen31,
    lunwen32,
    lunwen33,
    lunwen34,
    lunwen35,
    lunwen36,
    lunwen37,
    lunwen38,
    lunwen39,
    lunwen40,
    lunwen41,
    lunwen42,
    lunwen43,
    lunwen44,
    lunwen45,
    lunwen46,
    lunwen47,
    lunwen48,
    lunwen49,
    lunwen50,
    lunwen51,
    lunwen52,
    lunwen53,
    lunwen54,
    lunwen55,
    lunwen56,
    lunwen57,
    // lunwen58,
    // lunwen59,
    // lunwen60,
    // lunwen61,
    // lunwen62,
    // lunwen63,
];

export const kaitiList = [
    kaiti00,
    kaiti01,
    kaiti02,
    kaiti03,
    kaiti04,
    kaiti05,
    kaiti06,
    kaiti07,
    kaiti08,
    kaiti09,
];

export const renwuList = [
    renwu00,
    renwu01,
    renwu02,
    renwu03,
    renwu04,
    renwu05,
    renwu06,
    renwu07,
    renwu08,
];

export const pptList = [
    ppt01,
    ppt02,
    ppt03,
    ppt04,
    ppt05,
    ppt06,
    ppt07,
    ppt08,
    ppt09,
    ppt10,
    ppt11,
    ppt12,
    ppt13,
    ppt14,
    ppt15,
    ppt16,
    ppt17,
    ppt18,
    ppt19,
    ppt20,
    ppt21,
    ppt22,
    ppt23,
    ppt24,
    ppt25,
    ppt26,
    ppt27,
    ppt28,
    ppt29,
    ppt30,
    ppt31,
    ppt32,
    ppt33,
    ppt34,
    ppt35,
    ppt36,
    ppt37,
    ppt38,
    ppt39,
    ppt40,
    ppt41,
    ppt42,
    ppt43,
    ppt44,
    ppt45,
    ppt46,
    ppt47,
    ppt48,
    ppt49,
    ppt50,
    ppt51,
    ppt52,
    ppt53,
    ppt54,
];

export const wenjuanList = [wenjuan01, wenjuan02, wenjuan03];
