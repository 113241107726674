import React from 'react';
import { Anchor } from 'antd';
import styles from './index.module.scss';
import { lunwenList, kaitiList, renwuList, wenjuanList, pptList } from './const';
export const Example = () => (
    <div className={styles.example}>
        <div className={styles.anchor}>
            <Anchor
                affix={false}
                direction="horizontal"
                size="large"
                items={[
                    {
                        key: 'part-1',
                        href: '#part-1',
                        title: '正文',
                    },
                    {
                        key: 'part-2',
                        href: '#part-2',
                        title: '开题报告',
                    },
                    {
                        key: 'part-3',
                        href: '#part-3',
                        title: '任务书',
                    },
                    {
                        key: 'part-4',
                        href: '#part-4',
                        title: '调查问卷',
                    },
                    {
                        key: 'part-5',
                        href: '#part-5',
                        title: '答辩汇报PPT',
                    },
                ]}
            />
        </div>

        <div className={styles.content}>
            <div id="part-1" className={styles.anchorContent}>
                <p className={styles.title}>
                    正文包含
                    <span>数据(表)</span>、<span>图</span>、<span>代码段</span>
                </p>
                <div>
                    {lunwenList.map((l, idx) => {
                        return <img className={styles.imgBox} src={l} key={idx} />;
                    })}
                </div>
            </div>
            <div id="part-2" className={styles.anchorContent}>
                <div>
                    {kaitiList.map((l, idx) => {
                        return <img className={styles.imgBox} src={l} key={idx} />;
                    })}
                </div>
            </div>
            <div id="part-3" className={styles.anchorContent}>
                <div>
                    {renwuList.map((l, idx) => {
                        return <img className={styles.imgBox} src={l} key={idx} />;
                    })}
                </div>
            </div>
            <div id="part-4" className={styles.anchorContent}>
                <div>
                    {wenjuanList.map((l, idx) => {
                        return <img className={styles.imgBox} src={l} key={idx} />;
                    })}
                </div>
            </div>
            <div id="part-5" className={styles.anchorContent}>
                <div>
                    {pptList.map((l, idx) => {
                        return <img className={styles.imgBox} src={l} key={idx} />;
                    })}
                </div>
            </div>
        </div>
    </div>
);
