import { message } from 'antd';
import axios from 'axios';
export const baseURL = 'https://lingganfengbao.com/';
// export const baseURL = 'http://localhost:9999/';
// export const baseURL = "http://218.6.171.71:9999/";
axios.interceptors.request.use((config) => {
    return config;
});
axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(
    (response) => {
        return response.data || response;
    },
    (error) => {
        if (!error.config.slient) {
            message.error(error.response && error.response.data && error.response.data.msg);
        }

        // if (error.config.checkAuth && error.response.status === 424) {
        //   loginInstance.open();
        // }
        throw error;
    },
);

export const get = (url, data, config = {}) => {
    const params = data || {};
    const { headers = {} } = config;
    config.headers = {
        ...headers,
    };

    return axios.get(url, {
        ...config,
        params: params,
    });
};

export const post = (url, data = {}, config = {}) => {
    let postData = data || {};
    const { headers = {} } = config;
    config.headers = {
        ...headers,
    };

    return axios.post(url, postData, config);
};

export default axios;
