import React, { useState } from 'react';
import { Card, Typography, Tag, Spin, Empty } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { v4 as uuidv4 } from 'uuid'; // 需要安装 uuid 包
import { post } from '../../axios/axios';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

// 获取创作列表
const fetchCreations = async () => {
    const res = await post('/ai/ai/paper/editor/my', {
        code: uuidv4(),
    });
    return res.data || [];
};

// 状态映射
const STATUS_MAP = {
    生成中: {
        color: 'border-[#E9993E] bg-[#E9993E]/10 text-[#E9993E]',
        text: '生成中',
    },
    已完成: {
        color: 'border-[#9B79E7] bg-[#9B79E7]/10 text-[#9B79E7]',
        text: '已完成',
    },
};

const MyCreation = () => {
    const navigate = useNavigate();
    const { data: response = { data: [] }, loading: listLoading } = useRequest(fetchCreations);
    const cards = response.data || [];

    // 处理编辑
    const handleEdit = (orderNo) => {
        navigate(`/editor?orderNo=${orderNo}`);
    };

    return (
        <div className="mt-6 min-h-screen bg-gray-50 p-6 md:mt-12">
            <div className="mx-auto max-w-7xl">
                <Title level={3} className="mb-6 text-[#9B79E7]">
                    我的创作
                </Title>
                <Spin spinning={listLoading}>
                    {cards.length > 0 ? (
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
                            {cards.map((card) => (
                                <Card
                                    key={card.orderNo}
                                    hoverable
                                    bodyStyle={{ padding: 16 }}
                                    className="h-full"
                                >
                                    <div className="mb-2 flex items-start justify-between">
                                        <Typography.Text
                                            strong
                                            className="mr-2 line-clamp-2 flex-1 text-base"
                                        >
                                            {card.title}
                                        </Typography.Text>
                                        <Tag
                                            className={`whitespace-nowrap rounded-full border ${
                                                STATUS_MAP[card.status]?.color ||
                                                STATUS_MAP['生成中'].color
                                            }`}
                                        >
                                            {card.status}
                                        </Tag>
                                    </div>
                                    <p className="mb-4 line-clamp-4 text-sm text-gray-600">
                                        {card.text}
                                    </p>
                                    <div className="flex items-center justify-between text-xs text-gray-400">
                                        <span>{card.createTime}</span>
                                        <div className="flex gap-2">
                                            <button
                                                className="rounded-lg p-2 transition-colors hover:bg-[#9B79E7]/10 hover:text-[#9B79E7]"
                                                onClick={() => handleEdit(card.orderNo)}
                                            >
                                                <EditOutlined className="text-base" />
                                            </button>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    ) : (
                        <div className="flex min-h-[400px] items-center justify-center rounded-lg bg-white">
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span className="text-gray-400">暂无创作内容，快去创作吧~</span>
                                }
                            />
                        </div>
                    )}
                </Spin>
            </div>
        </div>
    );
};

export default MyCreation;
