import { memo } from 'react';

const CountdownProgress = memo(({ timeLeft, progress }) => {
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
            2,
            '0',
        )}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <div className="mb-3 w-full max-w-md rounded-xl bg-[#F8F9FC] px-3 pb-2 pt-9">
            <div className="relative mb-4">
                <div className="h-2 w-full rounded-full bg-[#E8E9F2]">
                    <div
                        className="absolute left-0 top-0 h-2 rounded-full bg-gradient-to-r from-[#975FFF] to-[#6940F3] transition-all duration-1000"
                        style={{ width: `${progress}%` }}
                    />
                </div>
                <div
                    className="absolute -top-1 translate-x-[-50%] translate-y-[-100%] transition-all duration-1000"
                    style={{ left: `${progress}%` }}
                >
                    <span className="rounded-md bg-[#975FFF] px-2 py-1 text-sm text-white">
                        {Math.round(progress)}%
                    </span>
                </div>
            </div>
            <div className="text-center text-sm text-[#414C6F]">
                预计剩余时间：{formatTime(timeLeft)}
            </div>
        </div>
    );
});

export default memo(CountdownProgress);
