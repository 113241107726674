import { baseURL } from '../../axios/axios';
import styles from './index.module.scss';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Upload, message, Modal, Image, Steps } from 'antd';
import cx from 'classnames';
import { reduceStore } from './store';
import { observer } from 'mobx-react-lite';
import { QueryOrder } from '../Buy/QueryOrder';
import { useNavigate, useSearchParams } from 'react-router-dom';
import upload from './assets/upload.png';
import word from './assets/word.png';
import aigc from './assets/aigc.png';
import search from './assets/search.png';
import success from './assets/success.png';
import { AIGCLIST, LUNWEN_LIST } from './const';
import helpBaogao from '../../assets/helpBaogao.png';
import helpChachong from '../../assets/helpChachong.png';
import helpJiangchong from '../../assets/helpJiangchong.png';
import { isMobile } from '../../common/const';

const { Dragger } = Upload;
export const Step1 = observer(() => {
    const nav = useNavigate();

    const [modal, contextHolder] = Modal.useModal();
    const props = {
        accept: '.pdf,.doc,.docx,.html',
        name: 'file',
        multiple: false,
        maxCount: 1,
        action: baseURL + '/ai/ai/jc/upload',
        data() {
            return {};
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
                reduceStore.onStep1FileChange(info.file);
            }
            if (status === 'done') {
            } else if (status === 'error') {
                message.error(`${info.file.response.msg}`);
            }
        },
        beforeUpload() {
            return new Promise((resolve, reject) => {
                modal.confirm({
                    className: styles.uploadConfirm,
                    title: '确认上传文档',
                    icon: <ExclamationCircleFilled />,
                    okButtonProps: {
                        className: 'bg-[#00CE72]  text-white',
                    },
                    content:
                        '解析此文档可能需要访问您的文档内容，我们将严格保障每一个用户文件的安全性，绝不会泄露、篡改或滥用您的文件，您确定要授权此操作吗？',
                    onOk() {
                        resolve();
                    },
                    onCancel() {
                        resolve(Upload.LIST_IGNORE);
                    },
                });
            });
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    function showHelpDownload(data) {
        Modal.info({
            title: data.title,
            footer: null,
            closable: true,
            maskClosable: true,
            width: 1115,
            content: (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px',
                    }}
                >
                    {data.imgs.map((d, idx) => {
                        return (
                            <div
                                key={idx}
                                style={{
                                    fontWeight: 500,
                                    textAlign: 'center',
                                    marginRight: '15px',
                                }}
                            >
                                <Image src={d.src} height={239} />
                                <div>
                                    【{idx + 1}】{d.step}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ),
        });
    }

    function resetUpload() {
        reduceStore.onStep1FileChange('');
    }

    function onQuery() {
        QueryOrder({
            confirm: (orderNo) => {
                return new Promise((resolve, reject) => {
                    if (!orderNo) {
                        message.info('请输入订单号');
                        reject();
                        return;
                    }
                    reduceStore.onStep3GetOrderInfo({ orderNo }).then(() => {
                        resolve();
                        window.open(`${window.location.href}?o=${orderNo}`);
                    });
                });
            },
        });
    }

    return (
        <div className={'w-full lg:mx-auto lg:w-full'}>
            {contextHolder}
            {/*banner部分*/}
            <div className={'flex flex-col lg:flex-col lg:justify-between lg:gap-4'}>
                {/*上方*/}
                <div
                    className={
                        'mb-4 w-full rounded-[12px] bg-white lg:mb-0 lg:h-full lg:flex-1 lg:bg-white'
                    }
                >
                    <div
                        className={'-translate-x-5 scale-75 lg:mb-6 lg:-translate-x-0 lg:scale-100'}
                    >
                        {/*<Steps*/}
                        {/*    current={reduceStore.curStep}*/}
                        {/*    onChange={reduceStore.onNewStepChange}*/}
                        {/*    className={'site-navigation-steps'}*/}
                        {/*    items={reduceStore.steps}*/}
                        {/*    direction="horizontal"*/}
                        {/*    style={{*/}
                        {/*        flexDirection: isMobile ? 'row' : 'initial',*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </div>
                    {reduceStore.step1.file ? (
                        <div className={styles.hasFile}>
                            <img src={success} className={styles.successImg} />
                            <div className={styles.successTip}>上传成功</div>
                            <div className={styles.successTip}>{reduceStore.step1.file.name}</div>
                            <div className={styles.successBtns}>
                                <Button
                                    onClick={resetUpload}
                                    size="large"
                                    className={styles.leftBtn}
                                >
                                    重新上传
                                </Button>
                                <Button
                                    onClick={() => reduceStore.toStep2(reduceStore.step1.file)}
                                    type="primary"
                                    size="large"
                                >
                                    确认上传
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className={'lg:h-[320px]'}>
                            <Dragger {...props}>
                                <>
                                    <div className={'flex justify-center'}>
                                        <img src={upload} />
                                    </div>
                                    <div className={'mb-2 mt-2 text-base text-[#333] lg:mb-8'}>
                                        点击上传或拖拽文件到此处上传
                                    </div>
                                    <div
                                        className={
                                            'flex flex-col gap-3 px-8 lg:flex-row lg:justify-between'
                                        }
                                    >
                                        <div
                                            className={
                                                'flex h-[65px] w-[235px] items-center rounded-[8px] bg-white p-2 lg:h-[88px] lg:flex-1 lg:p-4'
                                            }
                                        >
                                            <img className={'mr-2 h-8 w-8'} src={word} />
                                            <div>
                                                <div className={'text-start'}>论文原稿</div>
                                                <div className={'text-start text-xs text-[#999]'}>
                                                    .docx
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'flex h-[65px] w-[235px] items-center rounded-[8px] bg-white p-2 lg:h-[88px] lg:flex-1 lg:p-4'
                                            }
                                        >
                                            <img className={'mr-2 h-8 w-8'} src={aigc} />
                                            <div>
                                                <div className={'text-start'}>AIGC检测报告</div>
                                                <div className={'text-start text-xs text-[#999]'}>
                                                    知网、维P
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'flex h-[65px] w-[235px] items-center rounded-[8px] bg-white p-2 lg:h-[88px] lg:flex-1 lg:p-4'
                                            }
                                        >
                                            <img className={'mr-2 h-8 w-8'} src={search} />
                                            <div>
                                                <div className={'text-start'}>论文原稿</div>
                                                <div className={'text-start text-xs text-[#999]'}>
                                                    知W、维P、万F、PaperOK、PaperRight
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </Dragger>
                        </div>
                    )}
                    <div className={'mt-4 text-center text-sm text-[#999]'}>
                        <div className={'mt-12'}>
                            青秧严格遵守保密规定，不会收录、泄露您的任何信息，您提交的信息可随时删除，请放心使用。
                        </div>
                    </div>
                </div>
                {/*下方*/}
                <div className={cx(styles.card, styles.helpBox)}>
                    <div className={`border-b-[1px] pb-3 text-[18px] font-bold`}>帮助中心</div>
                    <div className={'flex gap-4'}>
                        <div className={'flex-1'}>
                            <div
                                className={styles.tip}
                                style={{
                                    paddingTop: '16px',
                                }}
                            >
                                <div className={'flex gap-1'}>
                                    <img className={'h-4 w-4'} src={helpBaogao} alt="" />
                                    <span className={'text-[14px] text-[#333]'}>
                                        AI报告怎么传？
                                    </span>
                                </div>
                            </div>
                            <div className={styles.btnBox}>
                                {AIGCLIST.map((it, idx) => (
                                    <Button
                                        key={idx}
                                        className={styles.btn}
                                        onClick={() => showHelpDownload(it)}
                                    >
                                        {it.btn}
                                    </Button>
                                ))}
                            </div>
                        </div>
                        <div className={'flex-1'}>
                            <div className={styles.tip} style={{ marginTop: '20px' }}>
                                <div className={'flex gap-1'}>
                                    <img className={'h-4 w-4'} src={helpJiangchong} alt="" />
                                    <span className={'text-[14px] text-[#333]'}>
                                        论文查重报告怎么传?
                                    </span>
                                </div>
                            </div>
                            <div className={styles.btnBox}>
                                {LUNWEN_LIST.map((it, idx) => (
                                    <Button
                                        key={idx}
                                        className={styles.btn}
                                        onClick={() => showHelpDownload(it)}
                                    >
                                        {it.btn}
                                    </Button>
                                ))}
                            </div>
                        </div>
                        <div className={'flex-1'}>
                            <div className={styles.tip} style={{ marginTop: '20px' }}>
                                <div className={'flex gap-1'}>
                                    <img className={'h-4 w-4'} src={helpJiangchong} alt="" />
                                    <span className={'text-[14px] text-[#333]'}>查询降重记录</span>
                                </div>
                            </div>
                            <div className={styles.btnBox}>
                                <Button className={styles.btn} onClick={onQuery}>
                                    查询记录
                                </Button>
                            </div>
                        </div>
                        <div className={'flex-1'}>
                            <div className={styles.tip} style={{ marginTop: '20px' }}>
                                <div className={'flex gap-1'}>
                                    <img className={'h-4 w-4'} src={helpJiangchong} alt="" />
                                    <span className={'text-[14px] text-[#333]'}>案例展示</span>
                                </div>
                            </div>
                            <div className={styles.btnBox}>
                                <Button className={styles.btn} onClick={onQuery}>
                                    案例展示
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
