import { Input, Button, Modal, message } from 'antd';
import { useState, useRef } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { globalData } from '../../../../Service/utils';
import { post } from '../../../../axios/axios';

const RedeemModal = NiceModal.create(({ orderNo }) => {
    const modal = NiceModal.useModal();
    const [redeemCode, setRedeemCode] = useState('');
    const [submitRedeemLoading, setSubmitRedeemLoading] = useState(false);
    const payInfoRef = useRef({});

    const handleCancel = () => {
        payInfoRef.current = {};
        globalData.destroy = 1;
        modal.hide();
        modal.reject();
    };

    const submitRedeemCode = async () => {
        try {
            setSubmitRedeemLoading(true);
            const res = await post('/member/payment/redeemCodeNew', {
                orderNo,
                code: redeemCode,
            });

            if (res && res.code === 0) {
                message.info('正在加速生成中，预计需要30分钟');
                modal.hide();
                modal.resolve(res.data);
            } else {
                const msg = res && res.msg;
                message.error(msg || '请检查您输入的兑换码是否正确，如果错误请重新输入。');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitRedeemLoading(false);
        }
    };

    return (
        <Modal
            open={modal.visible}
            width={790}
            footer={null}
            onCancel={handleCancel}
            maskClosable={false}
            title="请输入兑换码"
            zIndex={1889}
        >
            <Input
                style={{ marginTop: '16px' }}
                placeholder="请输入兑换码"
                value={redeemCode}
                onChange={(e) => setRedeemCode(e.target.value)}
            />
            <div
                style={{
                    marginTop: '20px',
                    justifyContent: 'flex-end',
                }}
                className="flex"
            >
                <Button loading={submitRedeemLoading} type="primary" onClick={submitRedeemCode}>
                    确定
                </Button>
            </div>
        </Modal>
    );
});

export default RedeemModal;
