import { useState, useCallback } from 'react';
import useStreamRequest from '../../../../hooks/useStreamRequest';

const useAbstract = ({ baseURL, authHeaders, url, data }) => {
    // 状态管理
    const [abstractsCompleted, setAbstractsCompleted] = useState(false);
    const [abstracts, setAbstracts] = useState('');

    // 处理消息回调
    const handleMessage = useCallback((data) => {
        if (data.chatToken && data.chatToken !== '[DONE]') {
            // chatToken已经在useStreamRequest中处理过转义序列
            setAbstracts((prev) => prev + data.chatToken);
        }
    }, []);

    // 处理错误回调
    const handleError = useCallback((error) => {
        setAbstractsCompleted(false);
        console.error('Stream error:', error);
    }, []);

    // 处理完成回调
    const handleStreamFinish = useCallback(() => {
        setAbstractsCompleted(true);
    }, []);

    // 使用 useStreamRequest
    const {
        streamRequest,
        loading: streamLoading,
        error: streamError,
    } = useStreamRequest({
        url: baseURL + url,
        headers: {
            ...authHeaders,
        },
        onMessage: handleMessage,
        onError: handleError,
        onFinish: handleStreamFinish,
    });

    // 清理函数
    const streamReset = useCallback(() => {
        setAbstracts('');
        setAbstractsCompleted(false);
    }, []);

    return {
        abstracts, // 摘要内容
        abstractsCompleted, // 完成状态
        streamLoading, // 加载状态
        streamError, // 错误信息
        streamRequest, // 请求函数
        streamReset, // 重置函数
    };
};

export default useAbstract;
