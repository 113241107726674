import { Drawer, Button } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { Summary } from '../../Summary';
import { useState } from 'react';

const SummaryDrawer = NiceModal.create(({ onConfirm }) => {
    const modal = NiceModal.useModal();
    const [updateLoading, setUpdateLoading] = useState(false);

    const handleClose = () => {
        modal.hide();
    };

    const handleConfirm = async () => {
        try {
            setUpdateLoading(true);
            await onConfirm?.();
            modal.hide();
        } catch (error) {
            console.error(error);
        } finally {
            setUpdateLoading(false);
        }
    };

    return (
        <Drawer
            title="修改大纲"
            rootClassName="summary-drawer"
            onClose={handleClose}
            open={modal.visible}
            footer={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        lineHeight: 1,
                        height: 'auto',
                    }}
                >
                    <Button onClick={handleClose}>取消</Button>
                    <Button
                        type="primary"
                        style={{ marginLeft: '20px' }}
                        onClick={handleConfirm}
                        loading={updateLoading}
                    >
                        确定
                    </Button>
                </div>
            }
        >
            <Summary from="drawer" />
        </Drawer>
    );
});
export default SummaryDrawer;
