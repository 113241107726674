import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Input, message, Spin } from 'antd';
import React, { useState } from 'react';
import { post } from '../../../../axios/axios';
import { processSection } from './AcademicEditor/AcademicEditorUtil';
import CustomDrawer from './CustomDrawer';
import './tableModal.scss';

// 重写章节
const RewriteModal = NiceModal.create(({ orderNo, content, from, to, chapterId }) => {
    const modal = useModal();
    const [prompt, setPrompt] = useState('');
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [chapterContent, setChapterContent] = useState('');

    const handleOk = async () => {
        if (!text || !chapterContent) {
            message.warning('请先生成内容');
            return;
        }
        const res = await post('/ai/ai/paper/editor/replaceChapter', {
            orderNo,
            chapterId,
            chapterContent,
        });
        modal.resolve(res.data);
        modal.hide();
    };

    const fetchData = async () => {
        if (!orderNo || !chapterId) return;
        if (!prompt) {
            message.warning('请输入提示词');
            return;
        }

        try {
            setLoading(true);
            const res = await post('/ai/ai/paper/editor/rewriteChapter', {
                orderNo,
                chapterId,
                prompt,
            });
            setText(processSection(res.data));
            setChapterContent(res.data);
        } catch (error) {
            message.error('生成失败，请重试');
            console.error('error', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <CustomDrawer
            title="重写章节"
            visible={modal.visible}
            onClose={() => {
                modal.reject();
                modal.hide();
            }}
            okText="替换原文"
            resetText="重新生成"
            onOk={handleOk}
            onReset={fetchData}
            showFooter={!!text}
        >
            <div className="flex flex-col gap-4">
                <Input.TextArea
                    placeholder="请尽量详细描述您想要的内容"
                    value={prompt}
                    onChange={(e) => {
                        setPrompt(e.target.value);
                    }}
                />

                {!text && (
                    <Button type="primary" onClick={fetchData} loading={loading} className="w-full">
                        生成内容
                    </Button>
                )}

                <div className="mt-4">
                    {loading ? (
                        <div className="flex h-32 items-center justify-center">
                            <Spin />
                        </div>
                    ) : text ? (
                        <div
                            className="table-modal content mt-4 rounded border p-4"
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    ) : null}
                </div>
            </div>
        </CustomDrawer>
    );
});

export default RewriteModal;
