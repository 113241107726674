// 在组件内部添加这个辅助函数
const checkHeading3Content = (editor) => {
    // 1. 获取当前选中的位置
    const { from } = editor.state.selection;
    const $from = editor.state.doc.resolve(from);

    // 2. 找到当前的3级标题节点和位置
    let headingNode = null;
    let headingPos = -1;

    for (let d = $from.depth; d >= 0; d--) {
        const node = $from.node(d);
        if (node.type.name === 'heading' && node.attrs.level === 3) {
            headingNode = node;
            headingPos = $from.before(d);
            break;
        }
    }

    // 3. 验证是否找到了3级标题
    if (!headingNode || headingPos === -1) {
        console.log('No heading found');
        return false;
    }

    // 4. 从当前3级标题后开始遍历,直到遇到下一个3级标题或4级标题
    const doc = editor.state.doc;
    const headingEnd = headingPos + headingNode.nodeSize;

    let hasHeading4 = false;
    let foundNextH3 = false; // 添加标记是否找到下一个3级标题的状态

    doc.nodesBetween(headingEnd, doc.content.size, (node, pos) => {
        if (foundNextH3) {
            // 如果已经找到下一个3级标题，直接停止遍历
            return false;
        }

        if (node.type.name === 'heading') {
            if (node.attrs.level === 3) {
                // 遇到下一个3级标题，标记已找到
                foundNextH3 = true;
                return false;
            }
            if (node.attrs.level === 4 && !foundNextH3) {
                // 只有在还没找到下一个3级标题时才设置hasHeading4
                hasHeading4 = true;
                return false;
            }
        }
        return true;
    });

    console.log('Check result:', {
        currentH3: {
            pos: headingPos,
            text: headingNode.textContent,
        },
        hasHeading4,
    });

    return hasHeading4;
};

// 检查各种按钮是否应该显示
export const shouldShowButtons = {
    // 检查是否显示修图按钮
    image: (editor) => editor.isActive('image') && editor.isEditable,

    // 检查是否显示修表按钮
    table: (editor) => editor.isActive('table') && editor.isEditable,

    // 检查是否显示重写本章按钮
    rewrite: (editor) => {
        return (
            ((editor.isActive('heading', { level: 3 }) && !checkHeading3Content(editor)) ||
                editor.isActive('heading', { level: 4 })) &&
            editor.isEditable
        );
    },

    // 检查是否显示文本工具按钮（降重、扩写、缩写）
    textTools: (editor, from, to) => {
        const hasTextSelection = from !== to;
        return (
            !editor.isActive('image') &&
            !editor.isActive('table') &&
            !editor.isActive('heading', { level: 3 }) &&
            !editor.isActive('heading', { level: 4 }) &&
            !editor.isActive('heading', { level: 2 }) &&
            editor.isEditable &&
            hasTextSelection
        );
    },
};

// 检查是否应该显示气泡菜单
export const shouldShowBubbleMenu = ({ editor, from, to }) => {
    // 如果是禁用，直接不显示
    if (!editor.isEditable) {
        return false;
    }

    // 检查是否有任何按钮需要显示
    const shouldShowAnyButton = Object.values(shouldShowButtons).some((check) =>
        check(editor, from, to),
    );

    return shouldShowAnyButton;
};
