import React from 'react';
import { List, Checkbox, Typography, ConfigProvider } from 'antd';

const { Text, Paragraph } = Typography;

const LiteratureItem = ({ item, onSelect, selected }) => (
    <List.Item
        key={item.id}
        style={{
            backgroundColor: '#F9FBFF',
            borderRadius: '16px',
            padding: '16px',
            marginBottom: '8px',
        }}
    >
        <div style={{ width: '100%' }}>
            {/* 第一行：标题和复选框 */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginBottom: '8px',
                }}
            >
                <Text
                    strong
                    style={{ fontSize: '16px', wordBreak: 'break-all', paddingRight: '8px' }}
                >
                    {item.citationInfo}
                </Text>
                <ConfigProvider
                    theme={{
                        token: {
                            borderRadiusSM: 50,
                        },
                    }}
                >
                    <div className={'min-w-16 pt-0.5'}>
                        <Checkbox
                            rootClassName={'rounded-full'}
                            checked={selected}
                            onChange={(e) => onSelect(item, e.target.checked)}
                        >
                            {selected ? (
                                <span className={'text-primary'}>已选</span>
                            ) : (
                                <span className={'text-[#8C93B1]'}>未选 </span>
                            )}
                        </Checkbox>
                    </div>
                </ConfigProvider>
            </div>

            {/* 第二行：内容摘要 */}
            <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: '8px' }}>
                {item.anAbstract}
            </Paragraph>

            {/* 第三行：期刊名称 */}
            {/* <Text
                type="secondary"
                style={{ fontSize: '14px', display: 'flex', justifyContent: 'flex-end' }}
            >
                {item.publish}-{item.authors?.join(', ')}
            </Text> */}
        </div>
    </List.Item>
);

export default LiteratureItem;
