/**
 * Tiptap 编辑器滚动控制类
 */
export default class EditorScroller {
    /**
     * @param {Editor} editor - Tiptap 编辑器实例
     */
    constructor(editor) {
        this.editor = editor;
    }

    /**
     * 滚动到指定位置
     * @param {number} pos - 目标位置
     * @param {Object} options - 滚动选项
     * @returns {boolean} 是否成功滚动
     */
    scrollToPos(pos, options = {}) {
        try {
            // 设置文本选区
            this.editor.commands.setTextSelection(pos);

            // 获取对应的 DOM 节点
            const { node } = this.editor.view.domAtPos(pos);

            // 检查节点是否为 DOM 元素
            if (node instanceof Element) {
                node.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    ...options,
                });
                return true;
            }
            return false;
        } catch (error) {
            console.error('滚动到指定位置失败:', error);
            return false;
        }
    }

    /**
     * 滚动到指定类型的节点
     * @param {string} type - 节点类型
     * @param {Object} options - 滚动选项
     * @returns {boolean} 是否找到并滚动到节点
     */
    scrollToType(type, options = {}) {
        let found = false;

        this.editor.state.doc.descendants((node, pos) => {
            if (!found && node.type.name === type) {
                this.scrollToPos(pos, options);
                found = true;
                return false; // 停止遍历
            }
        });

        return found;
    }

    /**
     * 滚动到包含特定文本的节点
     * @param {string} searchText - 要搜索的文本
     * @param {Object} options - 滚动选项
     * @returns {boolean} 是否找到并滚动到节点
     */
    scrollToText(searchText, options = {}) {
        let found = false;

        this.editor.state.doc.descendants((node, pos) => {
            if (!found && node.isText && node.text?.includes(searchText)) {
                // 获取对应的 DOM 节点
                const { node: domNode } = this.editor.view.domAtPos(pos);

                // 检查节点是否为 DOM 元素
                if (domNode instanceof Element) {
                    // 直接滚动到元素位置，不设置文本选择
                    domNode.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        ...options,
                    });
                    found = true;
                    return false; // 停止遍历
                }
            }
        });

        return found;
    }

    /**
     * 滚动到具有特定属性的节点
     * @param {string} attr - 属性名
     * @param {any} value - 属性值
     * @param {Object} options - 滚动选项
     * @returns {boolean} 是否找到并滚动到节点
     */
    scrollToAttr(attr, value, options = {}) {
        let found = false;

        this.editor.state.doc.descendants((node, pos) => {
            if (!found && node.attrs[attr] === value) {
                this.scrollToPos(pos, options);
                found = true;
                return false;
            }
        });

        return found;
    }

    /**
     * 滚动到带有高亮效果的节点
     * @param {number} pos - 目标位置
     * @param {Object} options - 配置选项
     */
    scrollWithHighlight(pos, options = {}) {
        const { highlightClass = 'highlight', duration = 2000, scrollOptions = {} } = options;

        this.editor.commands.setTextSelection(pos);

        const { node } = this.editor.view.domAtPos(pos);
        if (node instanceof Element) {
            // 添加高亮样式
            node.classList.add(highlightClass);

            // 滚动到视图
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                ...scrollOptions,
            });

            // 移除高亮样式
            setTimeout(() => {
                node.classList.remove(highlightClass);
            }, duration);
        }
    }

    /**
     * 异步滚动到指定选择器的节点
     * @param {string} selector - CSS 选择器
     * @param {Object} options - 滚动选项
     * @returns {Promise<boolean>} 是否成功滚动
     */
    async scrollToSelector(selector, options = {}) {
        try {
            // 等待编辑器就绪
            await this._waitForEditor();

            const node = this.editor.view.dom.querySelector(selector);
            if (node) {
                node.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    ...options,
                });
                return true;
            }
            return false;
        } catch (error) {
            console.error('滚动到选择器失败:', error);
            return false;
        }
    }

    /**
     * 滚动到具有指定id值的第一个元素
     * @param {string} id - 要查找的id值
     * @param {Object} options - 滚动选项
     * @returns {boolean} 是否找到并滚动到元素
     */
    /**
     * 滚动到具有指定id值的第一个元素
     * @param {string} id - 要查找的id值
     * @param {Object} options - 滚动选项
     * @returns {boolean} 是否找到并滚动到元素
     */
    scrollToId(id, options = {}) {
        try {
            // 获取编辑器根DOM元素
            const editorElement = this.editor.view.dom;

            // 直接查找具有id属性的元素
            const targetElement = editorElement.querySelector(`[id="${id}"]`);

            if (targetElement) {
                // 滚动到目标元素
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    ...options,
                });
                return true;
            }

            console.warn(`未找到ID为 ${id} 的元素`);
            return false;
        } catch (error) {
            console.error('滚动到指定ID元素失败:', error);
            return false;
        }
    }

    /**
     * 等待编辑器就绪
     * @returns {Promise<void>}
     */
    _waitForEditor() {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('等待编辑器超时'));
            }, 5000); // 5秒超时

            const check = () => {
                if (this.editor?.isEditable) {
                    clearTimeout(timeout);
                    resolve();
                    return;
                }
                setTimeout(check, 100);
            };

            check();
        });
    }
}

// CSS 样式（需要单独添加）
/*
  .highlight {
    animation: flash 1s ease-out;
  }

  @keyframes flash {
    0% { background-color: transparent; }
    50% { background-color: rgba(255, 255, 0, 0.3); }
    100% { background-color: transparent; }
  }
  */
