import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Modal, Carousel, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './PreviewModal.module.scss';

const TITLE_MAP = {
    titleCoverImageLarge: '大标题封面图',
    titleCoverImage: '标题封面图',
    catalogueCoverImage: '目录封面图',
    chapterCoverImage: '章节封面图',
    contentCoverImage: '内容封面图',
    endCoverImage: '结束封面图',
};

const PreviewModal = ({ visible, onClose, templateData, onOk }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef();

    // 当模态框显示状态改变时重置到第一张图
    useEffect(() => {
        if (visible) {
            setCurrentSlide(0);
            // 确保 Carousel 重置到第一张图
            carouselRef.current?.goTo(0);
        }
    }, [visible]);

    const images = useMemo(() => {
        if (!templateData?.detailImage) return [];

        return Object.entries(templateData.detailImage).map(([key, image]) => ({
            label: TITLE_MAP[key],
            key,
            image,
        }));
    }, [templateData]);

    const handleNavigation = (direction) => {
        carouselRef.current?.[direction]();
    };

    const isFirstSlide = currentSlide === 0;
    const isLastSlide = currentSlide === images.length - 1;
    const currentLabel = images[currentSlide]?.label;

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            footer={null}
            width={800}
            centered
            maskClosable={false}
            title={<div className={styles.modalTitle}>{templateData?.industry}</div>}
        >
            <div className={styles.carouselWrapper}>
                <NavigationButton
                    direction="prev"
                    disabled={isFirstSlide}
                    onClick={() => handleNavigation('prev')}
                />

                <div className={styles.carouselContainer}>
                    <Carousel
                        infinite={false}
                        ref={carouselRef}
                        afterChange={setCurrentSlide}
                        initialSlide={0} // 设置初始显示第一张图
                    >
                        {images.map(({ key, image, label }) => (
                            <SlideContent key={key} image={image} label={label} />
                        ))}
                    </Carousel>
                </div>

                <NavigationButton
                    direction="next"
                    disabled={isLastSlide}
                    onClick={() => handleNavigation('next')}
                />
            </div>

            {images.length > 1 && currentLabel && (
                <div className={styles.slideLabel}>{currentLabel}</div>
            )}

            <div className={styles.actionContainer}>
                <Button
                    size="large"
                    shape="round"
                    type="primary"
                    onClick={() => onOk(templateData)}
                >
                    选择模板
                </Button>
            </div>
        </Modal>
    );
};

const NavigationButton = ({ direction, disabled, onClick }) => (
    <div className={styles[`${direction}Button`]}>
        <Button
            disabled={disabled}
            shape="circle"
            icon={direction === 'prev' ? <LeftOutlined /> : <RightOutlined />}
            onClick={onClick}
        />
    </div>
);

const SlideContent = ({ image, label }) => (
    <div className={styles.slideContent}>
        <img src={image} alt={label} className={styles.slideImage} />
        <div className={styles.slideTitle}>{label}</div>
    </div>
);

export default PreviewModal;
