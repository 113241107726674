import bylw from '../../assets/bylw.png';
import dbppt from '../../assets/dbppt.png';
import kclw from '../../assets/kclw.png';
import ktbg from '../../assets/ktbg.png';
import lwjc from '../../assets/lwjc.png';
import qklw from '../../assets/qklw.png';
import wxzs from '../../assets/wxzs.png';

import iconCodeActive from '../../assets/icon-code-active.svg';
import iconCode from '../../assets/icon-code.svg';
import iconDiagramActive from '../../assets/icon-diagram-active.svg';
import iconDiagram from '../../assets/icon-diagram.svg';
import iconFormulaActive from '../../assets/icon-formula-active.svg';
import iconFormula from '../../assets/icon-formula.svg';
import iconTableActive from '../../assets/icon-table-active.svg';
import iconTable from '../../assets/icon-table.svg';

export const DOC_TYPES = [
    {
        key: 'GRADUATION_THESIS',
        label: '毕业论文',
        icon: bylw,
    },
    {
        key: 'TERM_PAPER',
        label: '期刊论文',
        icon: qklw,
    },
    {
        key: 'PROPOSAL_REPORT',
        label: '开题报告',
        icon: ktbg,
    },
    {
        key: 'LITERATURE_REVIEW',
        label: '文献综述',
        icon: wxzs,
    },
    {
        key: 'DEFENSE_PPT',
        label: '答辩PPT',
        icon: dbppt,
    },
    {
        key: 'COURSE_THESIS',
        label: '课程论文',
        icon: kclw,
    },
    {
        key: 'THESIS_MODIFICATION',
        label: '论文降重',
        icon: lwjc,
    },
];

export const STEPS = [
    {
        title: '基本信息',
        key: 'basicInfo',
        step: 0,
    },
    {
        title: '生成大纲',
        key: 'outline',
        step: 1,
    },
    {
        title: '生成文章',
        key: 'content',
        step: 2,
    },
    {
        title: '论文导出',
        key: 'export',
        step: 3,
    },
];

export const PPT_STEPS = [
    {
        title: '基本信息',
        key: 'basicInfo',
        step: 0,
    },
    {
        title: '生成大纲',
        key: 'outline',
        step: 1,
    },
    {
        title: '选择模板',
        key: 'template',
        step: 2,
    },
    {
        title: 'PPT导出',
        key: 'export',
        step: 3,
    },
];

export const REDUCE_STEPS = [
    {
        title: '上传论文',
        key: 'upload',
        step: 0,
    },
    {
        title: '选择段落',
        key: 'select',
        step: 1,
    },
    {
        title: '降重结果',
        key: 'result',
        step: 2,
    },
];

export const AUTO_LIGHT_TYPES = [
    { value: 'TABLE', label: '表', icon: iconTable, activeIcon: iconTableActive },
    { value: 'DIAGRAM', label: '图', icon: iconDiagram, activeIcon: iconDiagramActive },
    { value: 'FORMULA', label: '公式', icon: iconFormula, activeIcon: iconFormulaActive },
    { value: 'CODE', label: '代码', icon: iconCode, activeIcon: iconCodeActive },
];

export const FEATURES = {
    left: [
        '灵感风暴快速生成全文',
        '灵感风暴提供真实参考文献',
        '灵感风暴提供文献综述、中英文摘要',
        '灵感风暴提供5篇致谢模板',
    ],
    right: [
        '灵感风暴提供数据表、逻辑流程图、公式、相关代码片段等',
        '灵感风暴免费提供千字大纲，二级/三级任意切换',
        '灵感风暴提供开题报告、任务书、调查问卷、答辩汇报PPT等',
        '灵感风暴支持预览',
    ],
};

export const NOTICES = [
    '灵感风暴保护论文隐私安全，采用阿里云安全储存，加密传输，不泄漏任何论文信息。',
    '灵感风暴保留论文7天，生成7天后自动删除，请及时下载保存。',
    '灵感风暴所有生成的论文模板只可用作格式参考，不允许抄袭、代写、直接挪用等行为。',
];

// 文档类型枚举
export const DOCTYPEENUM = {
    /** 专业长文 */
    PAPER: 1,
    /** 文献综述 */
    WENXIAN_ZONGSHU: 2,
    /** 开题报告 */
    KAITI: 3,
    /** PPT */
    PPT: 4,
    /** 文献阅读 */
    WENXIAN_YUEDU: 5,
};

// 文档类型映射
export const DOCTYPEENUM_MAP = {
    [DOCTYPEENUM.PAPER]: '专业长文',
    [DOCTYPEENUM.WENXIAN_ZONGSHU]: '文献综述',
    [DOCTYPEENUM.KAITI]: '开题报告',
    [DOCTYPEENUM.PPT]: 'PPT',
    [DOCTYPEENUM.WENXIAN_YUEDU]: '文献阅读',
};

// 文档类型到UID类型的映射
export const DOCTYPE_UID_TYPE_MAP = {
    [DOCTYPEENUM.PAPER]: 1,
    [DOCTYPEENUM.WENXIAN_ZONGSHU]: 1,
    [DOCTYPEENUM.KAITI]: 1,
    [DOCTYPEENUM.PPT]: 2,
    [DOCTYPEENUM.WENXIAN_YUEDU]: 2,
};
