import styles from './index.module.scss';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NewPPT } from './new';
import { PPTResult } from './result';
import { searchResultStore } from '../SearchResult/store';
import { getQuery } from '../../common/query';
import { createPortal } from 'react-dom';
import { NavRight } from '../../components/NavRight';

 function PPT() {
    const [qs] = useSearchParams();

     
    const [uid, setUid] = useState(qs.get('uid'));
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        if (!qs.get('id')) {
            // searchResultStore.initByOutlineQuery(getQuery());
            setUid(qs.get('uid'));
        }
    }, []);

    return (
        <div className={styles.ppt}>
            {document.getElementById('navTitle') &&
                createPortal(<span>生成PPT</span>, document.getElementById('navTitle'))}
            {document.getElementById('navRight')
                ? createPortal(<NavRight />, document.getElementById('navRight'))
                : null}
            {showResult ? (
                <PPTResult uid={uid} hs={1} />
                
            ) : (
                <NewPPT
                    handleNew={(data = {}) => {
                        console.log('handleNew---data--->',data);
                        window.history.replaceState(
                            null,
                            null,
                            `/search/ppt?uid=${data.uid}&pptTitle=${data.pptTitle}&pptSubTitle=${data.pptSubTitle}&coverImg=${data.coverImg}&templateIndexId=${data.templateIndexId}&hs=1&query=${searchResultStore.pptQuery.query}&docType=${searchResultStore.pptQuery.docType}`,
                        );
                        setUid(data.uid);
                        setShowResult(true);
                    }}
                />
            )}
        </div>
    );
}

export default PPT;