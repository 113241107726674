import styles from './step1.module.scss';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Radio, Upload, Button, Input, message, Checkbox } from 'antd';
import IconUpload from '../../PPT/assets/icon_upload.svg';
import { baseURL } from '../../../axios/axios';
import { DOCTYPEENUM } from '../const';
import { useNavigate } from 'react-router-dom';
import uploadSuccess from '../../../assets/upload_success.png';
import { summaryStore } from '../store';

const { Dragger } = Upload;

const tags = [
    { id: 1, name: '工作汇报' },
    { id: 2, name: '课程研讨' },
    { id: 3, name: '课程汇报' },
    { id: 4, name: '开题报告' },
    { id: 5, name: '转正述职' },
    { id: 6, name: '大学生生涯规划与就业指导' },
    { id: 7, name: '商业计划书' },
    { id: 8, name: '晋升述职' },
    { id: 9, name: '创业计划书' },
    { id: 10, name: '实践报告' },
    { id: 11, name: '文献抄读会' },
    { id: 12, name: '教学设计' },
    { id: 13, name: '开学第一课' },
    { id: 14, name: '我的暑假生活' },
    { id: 15, name: '入学自我介绍' },
    { id: 16, name: '读书分享' },
    { id: 17, name: '班队委竞选' },
    { id: 18, name: '学术答辩' },
    { id: 19, name: '设计提案' },
    { id: 20, name: '点我提需求' },
];

const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const PPTStep1 = (props) => {
    const [tagActive, setTagActive] = useState(1);
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
    };
    const [query, setQuery] = useState('');

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uid, setUid] = useState(null);

    const uploadProps = {
        name: 'file',
        showUploadList: false,
        accept: '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf',
        multiple: false, // 改为 false，限制单文件上传
        action: `${baseURL}ai/file/upload`,

        // 上传前校验
        beforeUpload: (file) => {
            const isLt50M = file.size / 1024 / 1024 < 50;
            if (!isLt50M) {
                message.error('文件大小不能超过 50MB!');
                return Upload.LIST_IGNORE;
            }

            // 如果已有文件，清空文件列表
            if (fileList.length > 0) {
                setFileList([]);
            }

            return true;
        },

        // 上传状态改变的回调
        onChange(info) {
            const { status } = info.file;

            console.log('info.fileList', info.fileList);
            // 始终只保留最后一个文件
            setFileList(info.fileList.slice(-1));

            if (status === 'uploading') {
                setUploading(true);
                return;
            }

            if (status === 'done') {
                setUploading(false);
                message.success(`${info.file.name} 上传成功`);

                // 获取服务器返回的文件ID
                const response = info.file.response;
                console.log('response', response);
                if (response && response.data) {
                    setUid(response.data);
                    console.log('文件ID:', response.data);
                }
            } else if (status === 'error') {
                setUploading(false);
                message.error(`${info.file.name} 上传失败`);
                setFileList([]);
                // 清除文件ID
                setUid(null);
            }
        },

        // 处理拖拽上传
        onDrop(e) {
            e.preventDefault();

            const droppedFiles = e.dataTransfer.files;

            if (droppedFiles.length === 0) {
                message.warning('未检测到文件');
                return;
            }

            const file = droppedFiles[0]; // 只取第一个文件

            // 检查文件大小
            const isLt50M = file.size / 1024 / 1024 < 50;
            if (!isLt50M) {
                message.error('文件大小不能超过 50MB!');
                return;
            }

            // 检查是否正在上传
            if (uploading) {
                message.warning('当前有文件正在上传，请稍后再试');
                return;
            }

            // 如果已有文件，清空文件列表
            if (fileList.length > 0) {
                setFileList([]);
            }

            console.log(
                '拖拽上传文件:',
                file.name,
                '大小:',
                Math.round(file.size / 1024 / 1024),
                'MB',
            );
        },
    };

    const nav = useNavigate();
    const [isCheckConfirm, setIsCheckConfirm] = useState(false);
    return (
        <>
            <div className={styles.ppt_step1}>
                <div className="tags flex flex-wrap items-center justify-center gap-3">
                    {tags.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={`${styles.tag} ${item.id === tagActive ? styles.active : ''}`}
                                onClick={() => setTagActive(item.id)}
                            >
                                {item.name}
                            </div>
                        );
                    })}
                </div>
                <div className="upload_container">
                    <div className="title">竞选职位</div>
                    <div className="radio mt-2">
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>上传文档</Radio>
                            <Radio value={2}>粘贴文本(仅支持中文)</Radio>
                        </Radio.Group>
                    </div>
                    <div>
                        {/* 文件上传部分 */}
                        <div
                            className={`transition-opacity duration-300 ${value === 1 ? 'opacity-100' : 'hidden opacity-0'}`}
                        >
                            <div className="content relative h-full w-full">
                                {/* 上传成功显示的内容 */}
                                <div
                                    className={`absolute inset-0 z-10 flex flex-col items-center justify-center bg-white ${fileList.length > 0 ? 'visible' : 'invisible'}`}
                                >
                                    <div className="flex items-center">
                                        <img className="h-5 w-5" src={uploadSuccess} alt="" />
                                        <div className="text-center">上传成功！快去生成PPT吧～</div>
                                    </div>
                                    <div className="mt-2">文件名称:{fileList[0]?.name}</div>
                                    文件大小:{' '}
                                    {fileList[0]?.size
                                        ? formatFileSize(fileList[0].size)
                                        : '0 Bytes'}
                                </div>

                                {/* 上传组件 */}
                                <Dragger className="h-full w-full" {...uploadProps}>
                                    <img className="mx-auto block" src={IconUpload} alt="" />
                                    <div className="name">点击或拖拽文件到此区域上传</div>
                                    <div className="tip">
                                        仅支持docx、txt文档格式，大小不超过50M,文档仅支持中文
                                    </div>
                                    <Button type="primary" className="btn" loading={uploading}>
                                        点击上传
                                    </Button>
                                </Dragger>
                            </div>
                        </div>

                        {/* 文本输入部分 */}
                        <div
                            className={`transition-opacity duration-300 ${value === 2 ? 'opacity-100' : 'hidden opacity-0'}`}
                        >
                            <Input.TextArea
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                className="mt-2 w-full"
                                autoSize={{ minRows: 10, maxRows: 10 }}
                                placeholder="为确保PPT生成内容符合您的需求，请在这里输入相关信息，我们将基于您输入的内容进行总结和扩充，最多4000字符"
                            />
                        </div>
                    </div>
                </div>
                <div className={'flex flex-col items-center'}>
                    <Button
                        className={styles.geneBtn}
                        size="large"
                        shape="round"
                        type="primary"
                        onClick={() => {
                            if (!isCheckConfirm) {
                                message.warning('请勾选并同意协议');
                                return;
                            }

                            nav(
                                `/search/paper?uid=${uid}&prompt=${tags.find((item) => item.id === tagActive)?.name}&query=${query}&docType=${DOCTYPEENUM.PPT}`,
                            );
                        }}
                    >
                        生成大纲
                    </Button>
                    {/*免责区域*/}
                    <div className={'mt-4 flex justify-center'}>
                        <Checkbox
                            checked={isCheckConfirm}
                            onChange={(e) => {
                                setIsCheckConfirm(e.target.checked);
                            }}
                        ></Checkbox>
                        <div
                            className={'ml-2 flex cursor-pointer items-center text-[#414C6F]'}
                            onClick={() => {
                                setIsCheckConfirm(!isCheckConfirm);
                            }}
                        >
                            我已阅读并同意{' '}
                            <a
                                style={{ color: '#666' }}
                                href={`${window.location.protocol}//${window.location.host}/agreement`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                《服务条款》
                            </a>
                            ：生成的论文范文仅用于参考，不作为毕业、发表使用
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(PPTStep1);
