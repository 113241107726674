import { Button, message, Modal, Pagination, Spin } from 'antd';
import styles from './index.module.scss';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { get, post } from '../../axios/axios';
import selected from './assets/selected.svg';
import classNames from 'classnames';
import PreviewModal from './PreviewModal';

import arrowLeft from '../../assets/arrow-left.png';
import arrowRight from '../../assets/arrow-right.png';
import { searchResultStore } from '../SearchResult/store';
import AIImageModal from './AIImageModal';
import { useSearchParams } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import PPTpayModal from './PPTpayModal';

// 选项数据
const OPTIONS = {
    STYLE_TYPES: ['简约', '卡通', '商务', '创意', '国风', '清新', '扁平', '插画', '节日'],
    COLOR_TYPES: ['蓝色', '绿色', '红色', '紫色', '黑色', '灰色', '黄色', '粉色', '橙色'],
    INDUSTRY_TYPES: [
        '科技互联网',
        '教育培训',
        '政务',
        '学院',
        '电子商务',
        '金融战略',
        '法律',
        '医疗健康',
        '文旅体育',
        '艺术广告',
        '人力资源',
        '游戏娱乐',
    ],
};

const StyleSelector = ({ title, options, value, onChange }) => {
    return (
        <div className="mb-4 flex items-center">
            <div className="mr-6 text-sm text-[#666666]">{title}</div>
            <div className="flex flex-wrap gap-4">
                {options.map((option) => (
                    <button
                        key={option}
                        onClick={() => onChange(option)}
                        className={`rounded px-2 py-1.5 text-sm transition-colors ${value === option ? 'bg-[#F7941D] text-white' : 'text-[#333333]'} `}
                    >
                        {option}
                    </button>
                ))}
            </div>
        </div>
    );
};

export const NewPPT = observer(function (props) {
    const [modal, contextHolder] = Modal.useModal();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [templateData, setTemplateData] = useState({});
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [total, setTotal] = useState(0);
    const [styleType, setStyleType] = useState(null);
    const [colorType, setColorType] = useState(null);
    const [industryType, setIndustryType] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [focusIdx, setFocusIdx] = useState(null);

    const [qs] = useSearchParams();
    const [pptTitle, setPptTitle] = useState(qs.get('pptTitle'));
    const [pptSubTitle, setPptSubTitle] = useState(qs.get('pptSubTitle'));
    useEffect(() => {
        setPptTitle(qs.get('pptTitle'));
        setPptSubTitle(qs.get('pptSubTitle'));
    }, []);

    const [AiModalVisible, setAiModalVisible] = useState(false);

    const handleStyleChange = (value) => {
        setStyleType(value === styleType ? null : value);
        setPageNum(1);
        setFocusIdx(null);
    };

    const handleColorChange = (value) => {
        setColorType(value === colorType ? null : value);
        setPageNum(1);
        setFocusIdx(null);
    };

    const handleIndustryChange = (value) => {
        setIndustryType(value === industryType ? null : value);
        setPageNum(1);
        setFocusIdx(null);
    };

    const getTemplates = async () => {
        try {
            setLoading(true);
            setError(null);
            const res = await get('ai/ai/s/ppt/themeList', {
                style: styleType,
                color: colorType,
                industry: industryType,
                pageNum,
                pageSize: 16,
            });
            setTemplates(res.data.lists);
            setTotal(res.data.total);
        } catch (err) {
            setError(err.message);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTemplates();
    }, [styleType, colorType, industryType, pageNum]);

    const step3Focus = (idx) => {
        setFocusIdx(idx);
    };

    const handlePageChange = (page) => {
        setPageNum(page);
    };

    const [generateLoading, setGenerateLoading] = useState(false);
    const usePptPayModal = useModal(PPTpayModal);

    return (
        <>
            {contextHolder}
            <div className={styles.step3}>
                <div className={styles.title}>
                    选择PPT模板
                    <span className={styles.titleDesc}>（PPT生成后不可更改）</span>
                </div>

                <div className="mb-6">
                    <StyleSelector
                        title="风格类型"
                        options={OPTIONS.STYLE_TYPES}
                        value={styleType}
                        onChange={handleStyleChange}
                    />
                    <StyleSelector
                        title="颜色类型"
                        options={OPTIONS.COLOR_TYPES}
                        value={colorType}
                        onChange={handleColorChange}
                    />
                    <StyleSelector
                        title="行业类型"
                        options={OPTIONS.INDUSTRY_TYPES}
                        value={industryType}
                        onChange={handleIndustryChange}
                    />
                </div>

                <div className={styles.templateBox}>
                    {loading ? (
                        <div className="flex h-40 flex-1 items-center justify-center">
                            <Spin />
                        </div>
                    ) : error ? (
                        <div className="flex h-40 flex-1 items-center justify-center text-red-500">
                            {error}
                        </div>
                    ) : templates.length === 0 ? (
                        <div className="flex h-40 flex-1 items-center justify-center text-gray-500">
                            暂无数据
                        </div>
                    ) : (
                        templates.map((item) => (
                            <div
                                key={`template-${item.templateIndexId}`}
                                className={classNames(styles.template, {
                                    [styles.active]: item.templateIndexId === focusIdx,
                                })}
                                onClick={() => {
                                    setTemplateData(item);
                                    setIsModalVisible(true);
                                }}
                            >
                                <div className={styles.templateImg}>
                                    <img
                                        className={styles.img}
                                        src={item.detailImage.titleCoverImageLarge}
                                        alt={item.title}
                                    />
                                    {focusIdx === item.templateIndexId && (
                                        <div className={styles.select}>
                                            <div className={styles.selectCircle}>
                                                <img src={selected} alt="selected" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <div className="my-6 flex justify-end">
                    <Pagination
                        current={pageNum}
                        total={total}
                        pageSize={16} // 每页显示的数量
                        onChange={handlePageChange}
                        showSizeChanger={false} // 不显示页码选择器
                        itemRender={(page, type, originalElement) => {
                            if (type === 'prev')
                                return (
                                    <div
                                        className={
                                            'flex h-8 w-8 items-center justify-center rounded-md bg-[#F5F7FA]'
                                        }
                                    >
                                        <img className={'h-4 w-4'} src={arrowLeft} alt="" />
                                    </div>
                                );
                            if (type === 'next')
                                return (
                                    <div
                                        className={
                                            'flex h-8 w-8 items-center justify-center rounded-md bg-[#F5F7FA]'
                                        }
                                    >
                                        <img className={'h-4 w-4'} src={arrowRight} alt="" />
                                    </div>
                                );
                            return originalElement;
                        }}
                    />
                </div>

                <div className={styles.paging}>
                    <Button
                        disabled={!focusIdx}
                        className={styles.next}
                        loading={generateLoading}
                        onClick={async () => {
                            if (!focusIdx) {
                                message.error('请选择模板');
                                return;
                            }
                            setAiModalVisible(true);
                        }}
                        type="primary"
                    >
                        生成PPT
                    </Button>
                </div>
            </div>

            <PreviewModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onOk={(_templateData) => {
                    setIsModalVisible(false);
                    step3Focus(_templateData.templateIndexId);
                    setTemplateData(_templateData);
                }}
                templateData={templateData}
            />

            <AIImageModal
                visible={AiModalVisible}
                onClose={() => setAiModalVisible(false)}
                onConfirm={(aiImage) => {
                    modal.confirm({
                        title: '生成PPT',
                        content: '是否确认生成PPT？',
                        onOk: async () => {
                            setGenerateLoading(true);
                            const data = {
                                pptTitle,
                                pptSubTitle,
                                outlines: searchResultStore.outlines,
                                templateId: templateData.templateIndexId,
                                aiImage,
                            };
                            // 查询订单号
                            const result = await post('/ai/ai/s/ppt/w', data).finally(() => {
                                setGenerateLoading(false);
                            });
                            // 查询订单价格
                            const priceListResult = await get('/member/price/list');
                            if (result.success) {
                                usePptPayModal
                                    .show({
                                        majorName: pptTitle,
                                        orderNo: result.data.orderNo,
                                        price: priceListResult.data.defensePptPrice,
                                    })
                                    .then(() => {
                                        props.handleNew({
                                            uid: result.data.orderNo,
                                            coverImg: result?.data?.coverImg,
                                            templateIndexId: templateData.templateIndexId,
                                            pptTitle,
                                            pptSubTitle,
                                        });
                                    });
                            }
                        },
                    });
                }}
            />
        </>
    );
});
