import { Paragraph } from '@tiptap/extension-paragraph'

export const CustomParagraph = Paragraph.extend({
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: element => element.getAttribute('id'),
          renderHTML: attributes => {
            if (!attributes.id) {
              return {}
            }
            return {
              id: attributes.id,
            }
          },
        },
      }
    },
  })