import styles from './index.module.scss';
import logoN from '../../assets/logo-normal.png';
import { Button, Tooltip, Modal, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useState, useRef, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { OrderModal } from '../Order/OrderModal';
import topLeft1 from '../../assets/top-left-1.png';
import topLeft2 from '../../assets/top-left-2.png';
import topLeft3 from '../../assets/top-left-3.png';
import topLeft4 from '../../assets/top-left-4.png';

import { summaryStore } from '../../Article/Documents/store';
import { observer } from 'mobx-react';
import { useScroll } from 'ahooks';

export const defaultOrderNo = 'a242a7d8e4004a37a4070719705b765f';
export const Nav = observer(({ hasBottomBorder = false, bgWhite = false }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOldVersionVisible, setIsOldVersionVisible] = useState(true);
    const nav = useNavigate();

    const scroll = useScroll(document);
    const lastScrollTop = useRef(0);
    const scrollTimer = useRef(null);
    const isScrolling = useRef(false);

    const [editBtnText, setEditBtnText] = useState('编辑');

    useEffect(() => {
        if (scroll) {
            if (scrollTimer.current) {
                clearTimeout(scrollTimer.current);
            }

            scrollTimer.current = setTimeout(() => {
                const currentScrollTop = scroll.top;
                const SHOW_THRESHOLD = 60;
                const BUFFER_ZONE = 40; // 添加缓冲区

                // 添加缓冲区逻辑，避免在阈值附近频繁切换
                if (isOldVersionVisible) {
                    // 当前显示时，只有超过阈值+缓冲区才隐藏
                    if (currentScrollTop > SHOW_THRESHOLD + BUFFER_ZONE) {
                        setIsOldVersionVisible(false);
                    }
                } else {
                    // 当前隐藏时，只有小于阈值-缓冲区才显示
                    if (currentScrollTop < SHOW_THRESHOLD - BUFFER_ZONE) {
                        setIsOldVersionVisible(true);
                    }
                }

                lastScrollTop.current = currentScrollTop;
            }, 10); // 增加防抖时间
        }

        return () => {
            if (scrollTimer.current) {
                clearTimeout(scrollTimer.current);
            }
        };
    }, [scroll?.top, isOldVersionVisible]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const toHome = () => {
        // summaryStore.reset();
        nav('/');
        window.document.scrollingElement?.scrollTo(0, 0);
        window.location.reload();
    };

    const toExample = () => {
        nav('/example');
    };

    const toEditor = () => {
        nav(`/editor?orderNo=${defaultOrderNo}`);
    };

    const isIndex = useLocation().pathname === '/';
    const isExample = useLocation().pathname === '/example';
    const shouldShowBorder = [1, 2].includes(summaryStore.currentStep) || hasBottomBorder;

    return (
        <>
            <div
                className={styles.navWrapper}
                style={{
                    backgroundColor: bgWhite || isExample ? '#fff' : 'transparent',
                    borderBottom: shouldShowBorder ? '1px solid rgb(229, 231, 235)' : undefined,
                }}
            >
                {isIndex && summaryStore.currentStep === 0 ? (
                    <div
                        className={`${styles.oldVersionBanner} ${!isOldVersionVisible ? styles.hidden : ''}`}
                    >
                        <img className={styles.bannerImg1} src={topLeft1} />
                        <div className={styles.bannerImgText}>灵感风暴全新上线！</div>
                        {/* <img className={styles.bannerImg2} src={topLeft2} /> */}
                        <img className={styles.bannerImg3} src={topLeft3} />
                        <img className={`${styles.bannerImg4}`} src={topLeft4} onClick={toEditor} />
                    </div>
                ) : null}
                <div className={styles.nav}>
                    <img
                        // src={summaryStore.useUltra ? logoU : logoN}
                        src={logoN}
                        className={styles.logo}
                        onClick={toHome}
                    />
                    <div className={styles.content}>
                        <div
                            className={styles.item}
                            onClick={() => {
                                setEditBtnText('编辑');
                                showModal();
                            }}
                        >
                            我的订单
                        </div>
                        {/* <div
                            className={styles.item}
                            onClick={() => {
                                nav('/myCreation');
                            }}
                        >
                            我的创作
                        </div> */}
                        <div
                            className={styles.item}
                            onClick={() => {
                                setEditBtnText('改稿');
                                showModal();
                            }}
                        >
                            无限改稿
                        </div>
                        <div className={styles.item} onClick={toExample}>
                            范文样本
                        </div>
                        <div
                            className={`${styles.item} flex items-center text-primary`}
                            onClick={toEditor}
                        >
                            <span className="hidden md:block">无限改稿说明</span>
                            <QuestionCircleOutlined className="ml-1" />
                        </div>
                    </div>
                    <Modal
                        title="我的订单"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        width={600}
                    >
                        <OrderModal
                            editBtnText={editBtnText}
                            handleOk={() => {
                                setIsModalOpen(false);
                            }}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
});
