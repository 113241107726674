import { Button, ConfigProvider, Drawer, Modal } from 'antd';
import React, { useState } from 'react';
import closeIcon from '../images/icon_close.svg';

const CustomDrawer = ({
    children,
    visible,
    title,
    okText,
    resetText,
    onClose,
    onOk,
    onReset,
    onClick,
    showFooter = true,
}) => {
    const [showConfirm, setShowConfirm] = useState(false);

    // 处理关闭事件
    const handleClose = () => {
        setShowConfirm(true);
    };

    // 确认关闭
    const handleConfirmClose = () => {
        setShowConfirm(false);
        onClose();
    };

    // 取消关闭
    const handleCancelClose = () => {
        setShowConfirm(false);
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgMask: 'rgba(0, 0, 0, 0.01)',
                    },
                }}
            >
                <Drawer
                    title={title}
                    placement="right"
                    mask={true}
                    maskClosable={true}
                    open={visible}
                    closeIcon={null}
                    destroyOnClose={true}
                    headerStyle={{ borderBottom: 'none' }}
                    bodyStyle={{ padding: '0 24px' }}
                    onClose={handleClose}
                    onClick={(e) => {
                        if (onClick) {
                            onClick(e);
                        }
                    }}
                    extra={
                        <img
                            className="block h-4 w-4 cursor-pointer"
                            src={closeIcon}
                            onClick={handleClose}
                            alt=""
                        />
                    }
                >
                    <div style={{ width: '552px' }}>
                        {children}
                        {showFooter && (
                            <div className="btns mt-8 flex items-center justify-center">
                                <Button type="primary" onClick={onOk}>
                                    {okText || '一键替换'}
                                </Button>
                                <Button
                                    color="default"
                                    variant="filled"
                                    className="ml-2"
                                    onClick={onReset}
                                    style={{ background: '#F8FBFF' }}
                                >
                                    {resetText || '重新生成'}
                                </Button>
                            </div>
                        )}
                    </div>
                </Drawer>
            </ConfigProvider>
            <Modal
                zIndex={100000}
                title="确认关闭"
                destroyOnClose={true}
                open={showConfirm}
                onOk={handleConfirmClose}
                onCancel={handleCancelClose}
                okText="确认"
                cancelText="取消"
            >
                <p>确定要关闭当前窗口吗？</p>
            </Modal>
        </>
    );
};

export default CustomDrawer;
