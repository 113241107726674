import React from 'react';
import { List } from 'antd';
import iconClose from '../../../assets/icon-close.png';

const SelectedLiteratureItem = ({ item, onClose }) => {
    return (
        <List.Item
            className="w-full flex  justify-between items-center bg-[#F8FBFF]"
            style={{
                padding: '12px',
            }}
            extra={
                <img
                    key="close"
                    className="w-3 h-3 cursor-pointer"
                    src={iconClose}
                    alt="关闭"
                    onClick={onClose}
                />
            }
        >
            {item.citationInfo}
        </List.Item>
    );
};

export default SelectedLiteratureItem;
